import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "@context/AppContext";
import { Button, Icon } from "@common";
import { FaUserFriends } from "react-icons/fa";
import { IoLogoGameControllerB } from "react-icons/io";
import GlitchText from "../../_common/GlitchText/GlitchText";

import { GiStaticWaves } from "react-icons/gi";

const HomeHero = () => {
  const { authenticated, setShowAuth, selectedGame } = useContext(AppContext);

  const [heroVideo, setHeroVideo] = useState("https://leagues-static.s3.eu-west-2.amazonaws.com/img/frontpage-video.mp4");

  useEffect(() => {
    if (selectedGame && selectedGame.frontPageVideo) {
      setHeroVideo(selectedGame.frontPageVideo);
    }
  }, [selectedGame]);
  return (
    <div className="home-hero">
      <div className="home-hero-background"></div>

      <video className="home-hero-video" autoPlay={true} loop={true} muted={true} playsInline={true}>
        <source src={heroVideo} type="video/mp4" />
      </video>

      <span className="faded">WELCOME TO LEAGUES.GG</span>
      <GlitchText className="home-hero__heading">
        <h1>YOUR ESPORTS GAMING PlaTFORM</h1>
      </GlitchText>

      <div className="row">
        <div className="col-4">
          <Icon name="teams-active" pureIcon={<FaUserFriends size={`4em`} />} />
          {/* <Icon name="teams-active" /> */}

          <h2>Team up</h2>
          <p>Find other teams, players and coaches and unite your skills</p>
        </div>
        <div className="col-4">
          <Icon name="scrims" pureIcon={<IoLogoGameControllerB size={`4em`} />} />
          {/* <Icon name="scrims" style={{ height: 50, marginTop: -7 }} /> */}

          <h2>Play esports</h2>
          <p>Play with other gamers in leagues, tournaments and scrims</p>
        </div>
        <div className="col-4">
          {/* <Icon name="play" style={{ height: 50, marginTop: -7 }} /> */}
          <Icon name="play" pureIcon={<GiStaticWaves size={`4em`} />} />

          <h2>Track stats</h2>
          <p>Track your game stats as you climb the ranks</p>
        </div>
      </div>

      {!authenticated && (
        <Button name="cta-button" onClick={() => setShowAuth("register")}>
          Sign up for free
        </Button>
      )}
    </div>
  );
};

export default HomeHero;
