import moment from "moment";
/**
 * Check if the user can reschedule the match or not based on the different conditions
 * @param {Object} reschedule Object with the date interval for reschedule
 * @param {true|false} isLockReschedule Flag to check if the reschedule is allowed or not by admin
 * @param {String} status Status of the match
 * @param {Date | String} matchDateTime datetime string or moment object of match date and time
 * @param {Number} setPlayerLimit grace time in minutes before the match to set players
 * @param {Boolean} isTournament flag to differenciate between league and tournament match
 * @returns true | false
 */
export const canRescheduleMatch = (reschedule, isLockReschedule, status, matchDateTime, setPlayerLimit, isTournament) => {
  const isCurrentDateBetweenReschedulePeriod = moment().isBetween(reschedule?.earliestDate, reschedule?.latestDate, undefined, "[]");
  const setPlayerLimitTime = isSetPlayerDeadlineExceeded(matchDateTime, setPlayerLimit);

  if (
    !reschedule ||
    isLockReschedule ||
    status.toLowerCase() === "completed" ||
    setPlayerLimitTime ||
    reschedule?.rescheduleType === "NO_RESCHEDULE"
  )
    return false;
  if (isTournament || isCurrentDateBetweenReschedulePeriod) {
    return true;
  }
};

/**
 * Check whether the deadline for setting team players is exceeded or not.
 * @param {Date} matchDateTime Date of the match scheduled
 * @param {Number} setPlayerLimit Number of hourse before the match
 * @returns true|false
 */
export const isSetPlayerDeadlineExceeded = (matchDateTime, setPlayerLimit) =>
  moment().utc().isAfter(moment(matchDateTime).subtract(setPlayerLimit, "minutes"));

/**
 * Check if the user has right to request match reschedule
 * @param {Sring} userRole Role of the current logged In user
 * @returns true|false
 */
export const hasRightToRescheduleMatch = (userRole) => {
  return userRole.toLowerCase() !== "player";
};
/**
 * Returns the role of the current logged in user.
 * @param {Object} team1Members Team members and owners of team 1
 * @param {Object} team2Members Team members and owners of team 2
 * @param {String} userId Id of the current logged in user
 * @returns String
 */
export const getUserRole = (team1Members = { members: [], owners: [] }, team2Members = { members: [], owners: [] }, userId) => {
  let allMembers = [];

  if (team1Members?.members) {
    allMembers = [allMembers, ...team1Members.members];
  }

  if (team2Members?.members) {
    allMembers = [allMembers, ...team2Members.members];
  }

  // Player roles in our system are defined as Player:<name> so splitting the name to get player only
  return team1Members?.owners?.find((owner) => owner.user === userId || owner.user?._id === userId) ||
    team2Members?.owners?.find((owner) => owner.user === userId || owner.user?._id === userId)
    ? "owner"
    : allMembers
        ?.find(
          (member) => member?.player?.user._id === userId || member?.coach?.user._id === userId || member?.manager?.user._id === userId
        )
        ?.role?.split(":")[0] || "player";
};

/**
 * Check if user is member of both of the teams.
 * @param {Object} team1Members Object of Team1 members
 * @param {Object} team2Members Object of Team2 members
 * @param {String} userId Id of the logged in user
 * @returns true | false
 */
export const isUserInBothTeams = (team1Members, team2Members, userId) =>
  (team1Members?.members?.some((member) => member?.coach?.user?._id === userId || member?.player?.user._id === userId) ||
    team1Members?.owners?.some((member) => member.user === userId)) &&
  (team2Members?.members?.some((member) => member?.coach?.user?._id === userId || member?.player?.user._id === userId) ||
    team2Members?.owners?.some((member) => member.user === userId));

/**
 * Returns the Id of the Team, the logged in user belongs to.
 * @param {Object} team1 Team1 members
 * @param {Object} team2 Team2 members
 * @param {String} userId Id of the logged in user
 * @returns String
 */
export const getTeamId = (team1 = { members: [], owners: [], _id: "" }, team2 = { members: [], owners: [], _id: "" }, userId) => {
  const team1Member =
    team1?.members?.find((member) => member.player?.user._id === userId || member.coach?.user._id === userId || member.user === userId) ||
    team1?.organisation?.owners.find((owner) => owner.user === userId) ||
    team1.owners?.find((owner) => owner.user === userId);
  const team2Member =
    team2?.members?.find((member) => member.player?.user._id === userId || member.coach?.user._id === userId || member.user === userId) ||
    team2?.organisation?.owners.find((owner) => owner.user === userId) ||
    team2.owners?.find((owner) => owner.user === userId);
  return team1Member ? team1._id : team2Member ? team2._id : null;
};

export const hasReceivedRequest = (receiverTeamId, userTeamId) => receiverTeamId?.toString() === userTeamId;
