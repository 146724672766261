import React from "react";
import Modal from "react-modal";
import { RiCloseLine } from "react-icons/ri";
import ConnectGame from "./ConnectGame/ConnectGame";

const CreatePlayerModal = ({ open, onClose, afterCreate = () => false }) => {
  return (
    <Modal isOpen={open} onRequestClose={onClose} className="modal" contentLabel="Example Modal" shouldCloseOnEsc={false}>
      <div className="modal-close" onClick={onClose}>
        <RiCloseLine />
      </div>
      <div className="modal-header">
        <h2>Create a Player</h2>
        <h3>Connect your game account to participate in tournaments, scrims etc. You can only create one player profile per game.</h3>
      </div>
      <div className="modal-body">
        <ConnectGame onClose={onClose} afterCreate={afterCreate} />
      </div>
    </Modal>
  );
};

export default CreatePlayerModal;
