import React, { useEffect, useState, useContext, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { PageWithTabs, Loading } from "@common";
import { CompareTeams, Games, GamesCSGO, LineUp, MatchReplay, Veto, VetoSteps } from "./MatchPageTabs";
import Axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import Results from "./MatchPageHeaders/Results";
import Upcoming from "./MatchPageHeaders/Upcoming";
import { RiArrowLeftLine /*RiExternalLinkLine*/ } from "react-icons/ri";
import { AppContext } from "../../context/AppContext";
import default_image from "@img/image.jpg";
import { connectSocket } from "./socket";
import {
  canRescheduleMatch,
  getTeamId,
  getUserRole,
  hasReceivedRequest,
  hasRightToRescheduleMatch,
  isSetPlayerDeadlineExceeded,
  isUserInBothTeams,
} from "../../utils/checkReschedule";
import AcceptRequest from "../_modals/reschedule/AcceptRequest";
import SendRequest from "../_modals/reschedule/SendRequest";
import TempTournCodes from "../YourMatches/YourMatchesTabs/OngoingUpcoming/TempTournCodes/TempTournCodes";
import Tippy from "@tippyjs/react";
import "tippy.js/animations/shift-toward.css";
import cover_image from "@img/ScrimFinderBanner.jpg";
import cover_image_csgo from "@img/ScrimFinderBannerCsgo.jpg";
import { applyBrowserTimezone } from "../../utils/time-functions";
import { FaSync } from "react-icons/fa";
import { match } from "core-js/fn/symbol";

const MatchPage = () => {
  let { id: matchId } = useParams();
  const [pageMap, setPageMap] = useState("de_overpass");
  const [loading, setLoading] = useState(true);
  const [matchData, setMatchData] = useState();
  const [isVeto, setIsVeto] = useState(false);
  const [showTournCodes, setShowTournCodes] = useState(false);
  const [t1VetoState, setT1VetoState] = useState("waiting");
  const [t2VetoState, setT2VetoState] = useState("waiting");
  const [playerStat, setPlayerStat] = useState([]);
  const { selectedGame, setSelectedGame, user } = useContext(AppContext);

  const userId = user.state._id;
  const [LeagueOrTournamnet, setLeagueOrTournamnet] = useState(null);
  const [isLeague, setIsLeague] = useState(false);
  const [isScrim, setIsScrim] = useState(false);
  const [maps, setMaps] = useState([]);
  const toastId = React.useRef(null);
  const [rescheduleMatch, setRescheduleMatch] = useState(false);
  const [rescheduleData, setRescheduleData] = useState(null);
  const [rescheduleRequest, setRescheduleRequest] = useState(null);
  const [showRequest, setShowRequest] = useState(true);
  const canUserRescheduleMatch = useMemo(
    () =>
      rescheduleData
        ? canRescheduleMatch(
          rescheduleData?.reschedule,
          rescheduleData?.isLockReschedule,
          rescheduleData?.status,
          rescheduleData?.matchDateTime,
          rescheduleData?.setPlayerLimit,
          !isLeague && !isScrim
        )
        : false,
    [rescheduleData]
  );

  const teamIdOfUser = useMemo(
    () =>
      matchData
        ? getTeamId(
          { members: matchData?.t1?.members || [], owners: matchData?.t1?.organisation?.owners || [], _id: matchData?.t1?._id || null },
          { members: matchData?.t2?.members || [], owners: matchData?.t2?.organisation?.owners || [], _id: matchData?.t2?._id || null },
          userId
        )
        : "",
    [matchData]
  );

  const toastOptions = {
    autoClose: false,
    type: toast.TYPE.ERROR,
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_CENTER,
    closeButton: (
      <span onClick={() => window.location.reload()}>
        <FaSync />
        {/* <i className="fa fa-sync" style={{ width: 25, height: 25 }}></i> */}
      </span>
    ),
    closeOnClick: false,
  };
  // console.log('matchData-',LeagueOrTournamnet.game.shortName)
  const setTeamVetoState = (team, state) => {
    setIsVeto(true);
    if (team && state && !(matchData[`${team}FF`])) {
      team == "t1" ? setT1VetoState(state) : setT2VetoState(state);
    }
  };

  const getRescheduleDataObject = (matchData, latestDate, isTournament) =>
    !matchData
      ? {}
      : {
        matchDateTime: matchData?.datetime,
        reschedule: matchData?.reschedule ||
          matchData?.league?.reschedule || {
          ...matchData?.tournament?.reschedule,
          ...(!!latestDate && { latestDate }),
          ...(!!isTournament && { earliestDate: matchData.datetime }),
        },
        team1: {
          members: matchData?.t1?.members || [],
          owners: matchData?.t1?.organisation?.owners,
        },
        team2: {
          members: matchData?.t2?.members,
          owners: matchData?.t2?.organisation?.owners,
        },
        isLockReschedule: matchData?.isLockReschedule,
        status: matchData?.status,
        gameColor: matchData?.league?.game?.mainColour || matchData?.tournament?.game?.mainColour,
        setPlayerLimit: matchData?.league?.setPlayersDeadline || matchData?.tournament?.setPlayersDeadline,
      };

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/matches/single/${matchId}`)
      .then(({ data }) => {
        let LeagueOrTournamnetData;
        let isLeagueData = false;
        if (data.league) {
          const dateTime = applyBrowserTimezone(data.datetime);
          setLeagueOrTournamnet(data.league);
          LeagueOrTournamnetData = data.league;
          setIsLeague(true);

          // isLeagueData = true;
        } else if (data.tournament) {
          setIsLeague(false);
          setLeagueOrTournamnet(data.tournament);
          console.log("tournamentData", LeagueOrTournamnet);
          LeagueOrTournamnetData = data.tournament;
        } else {
          setIsLeague(false);
          setIsScrim(true);
          setLeagueOrTournamnet(data.scrim);
          LeagueOrTournamnetData = data.scrim;
        }

        if (LeagueOrTournamnetData.game.shortName !== selectedGame.shortName) {
          setSelectedGame(LeagueOrTournamnetData.game);
          return window.location.reload();
        } else {
          if (LeagueOrTournamnetData._id) {
            isLeagueData
              ? Axios.get(`${process.env.REACT_APP_LOL_API}/api/leagues/${LeagueOrTournamnetData._id}/topPlayers`).then(
                ({ data: playerstatdata }) => {
                  setPlayerStat(
                    playerstatdata.playerStats.map((player) => {
                      // if(player.team === teamId){
                      player.kda =
                        player.deaths === 0 ? Infinity : parseFloat((player.kills + player.assists) / player.deaths).toFixed(2);
                      player.csPerMin = parseFloat(player.cs / (player.totalTimePlayed / 60)).toFixed(1);
                      return player;
                      // }
                    })
                  );
                }
              )
              : !isScrim &&
              Axios.get(`${process.env.REACT_APP_LOL_API}/api/tournamnet/${LeagueOrTournamnetData._id}/topPlayers`).then(
                ({ data: playerstatdata }) => {
                  setPlayerStat(
                    playerstatdata.playerStats.map((player) => {
                      // if(player.team === teamId){
                      player.kda =
                        player.deaths === 0 ? Infinity : parseFloat((player.kills + player.assists) / player.deaths).toFixed(2);
                      player.csPerMin = parseFloat(player.cs / (player.totalTimePlayed / 60)).toFixed(1);
                      return player;
                      // }
                    })
                  );
                }
              );
          }

          if (data.csgoData && data.csgoData.mapData && data.csgoData.mapData[0] && data.csgoData.mapData[0].map) {
            setPageMap(data.csgoData.mapData[0].map);
          }
          if (data.csgoData && data.veto) {
            Axios.get(`${process.env.REACT_APP_CSGO_API}/api/matches/${matchId}/veto/maps`)
              .then(({ data }) => {
                if (data && data.csgoMaps) {
                  setMaps(data.csgoMaps);
                }
              })
              .catch((ee) => {
                console.log("map-error--", ee);
                toast.error("There was a problem retriving map data");
              });
          }

          setMatchData({ ...data, datetime: applyBrowserTimezone(data.datetime || data.startDate) });

          setLoading(false);
        }
      })
      .catch((ee) => {
        // console.log("error--", ee);
        toast.error("There was a problem retriving match data");
      });
  }, [matchId]);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/rescheduleRequests/${matchId}`).then(({ data }) => {
      data && setRescheduleRequest(data);
    });
  }, [matchId]);

  useEffect(() => {
    !isLeague && !isScrim
      ? matchData &&
      Axios.post(`${process.env.REACT_APP_CORE_API}/api/tournaments/${matchData?.tournament._id}/matches/nextMatches`, { matchId })
        .then(({ data }) => {
          const { nextMatches } = data;
          const matchTimes = nextMatches.map(({ datetime }) => moment(datetime));
          console.log("RescheduleType", matchData?.reschedule?.rescheduleType);
          const minTime =
            matchData?.tournament?.reschedule?.rescheduleType === "Week"
              ? moment.min([...matchTimes, moment().endOf("week")])
              : moment.min(matchTimes);
          setRescheduleData(
            getRescheduleDataObject(matchData, moment(minTime).subtract(matchData.bestOf, "hours"), !isLeague && !isScrim)
          );
        })
        .catch((err) => toast.error("There was a problem retrieving upcoming matches data"))
      : matchData && setRescheduleData(getRescheduleDataObject(matchData));
  }, [matchData]);

  useEffect(() => {
    if (matchData && LeagueOrTournamnet && moment().diff(moment(matchData.datetime), "hours") > -6) {
      // console.log("connecting to socket");
      connectSocket(
        matchId,
        (_data) => {
          if ((_data.status && (_data.t1Score || _data.t1Score === 0) && (_data.t2Score || _data.t2Score === 0) || _data.t1FF || _data.t2FF))
            setMatchData({ ...matchData, t1Score: _data.t1Score, t2Score: _data.t2Score, status: _data.status, t1FF: _data.t1FF, t2FF: _data.t2FF });
          if (toast.isActive(toastId.current)) toast.dismiss(toastId.current);
        },
        () => {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast(
              <>
                <div>
                  Lost connection to the server. Try{" "}
                  <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => window.location.reload()}>
                    refreshing the page
                  </span>
                  .
                </div>
              </>,
              toastOptions
            );
          }
        },
        "match-update",
        LeagueOrTournamnet.game.shortName === "CSGO" ? process.env.REACT_APP_CSGO_API : process.env.REACT_APP_LOL_API
      );
    }
  }, [matchData]);
  
  const setMatchVeto = (veto) => {
    setMatchData({...matchData, veto: {...veto, userTeam: matchData.veto.userTeam, canPerform: matchData.veto.canPerform }})
  }
  const showGetCodes = (match) => {
    let teamMembers = match?.members || [];
    let isOrgOwner = false;
    let isTeamMember = false;

    if (match?.organisation?.owners?.find(({ user }) => user === userId) || match?.owners.find(({ user }) => user === userId)) {
      isOrgOwner = true;
    }

    if (
      teamMembers.find(
        ({ user, coach, player }) =>
          (user && user._id === userId) ||
          (player && player.user._id === userId) ||
          (coach && typeof coach.user !== "object" && coach.user === userId) ||
          (coach && typeof coach.user == "object" && coach.user._id === userId)
      )
    ) {
      isTeamMember = true;
    }
    if (
      (isOrgOwner || isTeamMember || user.state.admin) &&
      matchData.status !== "complete" &&
      matchData.lolData.tournamentCodes.length &&
      (matchData.league?.game?.shortName === "LoL" || matchData.scrim?.game?.shortName === "LoL")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const tournamentCodesButton =
    LeagueOrTournamnet?.game.shortName === "LOL" && (showGetCodes(matchData.t1) || showGetCodes(matchData.t2)) ? (
      <button className="button primary mr-3 text-right mt-0" onClick={() => setShowTournCodes(true)}>
        Get codes
      </button>
    ) : null;

  if (loading) {
    return (
      <div className="text-center">
        <Loading /> Crunching the data...
      </div>
    );
  } else {
    let metaHeader = null;
    let tabs = {};
    let gameSpecificName = <Loading />;
    let leagueOrTornamentBadge = (
      <Link
        className={`match-page-league-nav-btn ${isScrim && "disabled-link"}`}
        to={`/${isLeague ? "league" : "tournament"}/${LeagueOrTournamnet._id}`}
      >
        <div className="header-badge">
          <p className="badge-title">{!isScrim ? "part of" : null}</p>
          <p className="badge-name">{!isScrim ? LeagueOrTournamnet.name : "Scrim match"}</p>
          <img
            src={!isScrim ? LeagueOrTournamnet.logoImage : selectedGame?.whiteIcon || selectedGame?.blackIcon}
            className="badge-image"
            alt="league or tournament logo"
          />
          <p className="badge-division">
            {!isScrim ? LeagueOrTournamnet.leagueDivisionName || LeagueOrTournamnet.tournamentDivisionName : null}
          </p>
        </div>
      </Link>
    );

    if (LeagueOrTournamnet.game.shortName === "CSGO") {
      gameSpecificName = "Matches";
    } else {
      gameSpecificName = "Games";
    }

    if (moment(matchData.datetime).diff(moment(), "seconds") <= 0 || matchData.status != "upcoming" || (matchData.t1FF || matchData.t2FF)) {
      // If match is in progress/complete
      metaHeader = <Results key={Date.now()} {...matchData} complete={matchData.status === "complete"} isVeto={isVeto} t1VetoState={t1VetoState} t2VetoState={t2VetoState}/>;
      tabs = {
        ...(matchData.status === "complete" && {
          [gameSpecificName]:
            LeagueOrTournamnet.game.shortName === "CSGO" ? (
              <GamesCSGO match={matchData} />
            ) : (
              <Games match={matchData} tournamentCodesButton={tournamentCodesButton} />
            ),
        }),
        Lineup: (
          <LineUp
            gameData={LeagueOrTournamnet.game}
            teams={{ t1: matchData.t1, t2: matchData.t2 }}
            tournamentCodes={matchData.lolData && matchData.lolData.tournamentCodes}
            datetime={matchData.datetime}
            match={matchData}
            setPlayersDeadline={LeagueOrTournamnet.setPlayersDeadline}
            setPlayers={LeagueOrTournamnet.game?.shortName == "CSGO" ? matchData.csgoData?.players : matchData.lolData?.players}
            setMatchData={setMatchData}
            tournamentCodesButton={tournamentCodesButton}
          />
        ),
        "Compare Teams": (
          <CompareTeams
            players={matchData.lolData && matchData.lolData.players}
            playerStats={playerStat}
            teams={{ t1: matchData.t1, t2: matchData.t2 }}
            tournamentCodesButton={tournamentCodesButton}
          />
        ),
        // "Match Replay": <MatchReplay />,
      };
    } else {
      // If match is not complete
      metaHeader = <Upcoming {...matchData} isVeto={isVeto} t1VetoState={t1VetoState} t2VetoState={t2VetoState} />;
      tabs = {
        Lineup: (
          <LineUp
            gameData={LeagueOrTournamnet.game}
            teams={{ t1: matchData.t1, t2: matchData.t2 }}
            tournamentCodes={matchData.lolData && matchData.lolData.tournamentCodes}
            datetime={matchData.datetime}
            match={matchData}
            setPlayersDeadline={LeagueOrTournamnet.setPlayersDeadline}
            setPlayers={LeagueOrTournamnet.game?.shortName == "CSGO" ? matchData.csgoData?.players : matchData.lolData?.players}
            setMatchData={setMatchData}
            tournamentCodesButton={tournamentCodesButton}
          />
        ),
        "Compare Teams": (
          <CompareTeams
            players={matchData.lolData && matchData.lolData.players}
            playerStats={playerStat}
            teams={{ t1: matchData.t1, t2: matchData.t2 }}
            tournamentCodesButton={tournamentCodesButton}
          />
        ),
      };
    }
    if (LeagueOrTournamnet.game.shortName == "CSGO" && matchData.veto) {
      tabs.Veto = (
        <Veto gameData={LeagueOrTournamnet.game} matchData={matchData} maps={maps} setTeamVetoState={setTeamVetoState} maps={maps} updateMatchVeto={setMatchVeto}/>
      );
    }

    const rescheduleSuccess = (data, msg) => {
      setRescheduleRequest(data);
      toast.success(msg);
    };

    const rescheduleFailure = (msg = "") => {
      toast.error(msg);
    };

    return (
      <>
        <PageWithTabs
          base_route={`/match/${matchId}`}
          pages={tabs}
          cover_image={
            isScrim
              ? selectedGame && selectedGame.shortName === "CSGO"
                ? cover_image_csgo
                : cover_image
              : LeagueOrTournamnet.game.shortName === "CSGO"
                ? `https://leagues-static.s3.eu-west-2.amazonaws.com/csgo_imgs/${pageMap}.jpeg`
                : matchData?.league?.coverImage
          }
          custom_header={
            <>
              <div className="row align-items-center">
                <div className="col-9">{metaHeader}</div>
                <div className="col-3 d-flex justify-content-center align-items-center p-0">{leagueOrTornamentBadge}</div>
              </div>
            </>
          }
          reschedule_buttons={
            matchData?.t1 &&
              matchData?.t2 &&
              rescheduleData &&
              canUserRescheduleMatch &&
              hasRightToRescheduleMatch(getUserRole(rescheduleData?.team1, rescheduleData?.team2, userId)) ? (
              <>
                {isUserInBothTeams(rescheduleData.team1, rescheduleData.team2, userId) ? (
                  <>
                    {!rescheduleRequest ? (
                      <Tippy
                        content={
                          rescheduleData?.isLockReschedule
                            ? "Rescheduling has been locked for this match."
                            : rescheduleData && isSetPlayerDeadlineExceeded(rescheduleData.matchDateTime, rescheduleData.setPlayerLimit)
                              ? "Too late to reschedule. Match rescheduling should occur before the set player deadline."
                              : null
                        }
                        animation="shift-toward"
                        disabled={
                          !rescheduleData?.isLockReschedule &&
                          !isSetPlayerDeadlineExceeded(rescheduleData.matchDateTime, rescheduleData.setPlayerLimit)
                        }
                      >
                        <span>
                          <button
                            className="newMatchDate mustAButton navBtn mx-1"
                            style={{
                              backgroundColor: `${canUserRescheduleMatch ? rescheduleData.gameColor : ""}`,
                            }}
                            onClick={() => setRescheduleMatch(true)}
                            disabled={!canUserRescheduleMatch}
                          >
                            Request new match Date
                          </button>
                        </span>
                      </Tippy>
                    ) : (
                      <>
                        <span>
                          <button
                            className="newMatchDate mustAButton navBtn mx-1"
                            style={{
                              backgroundColor: `${canUserRescheduleMatch ? rescheduleData.gameColor : ""}`,
                            }}
                            onClick={() => setRescheduleMatch(true)}
                            disabled={!canUserRescheduleMatch}
                          >
                            Edit Match Date Request
                          </button>
                        </span>
                        <span>
                          <button
                            className="newMatchDate mustAButton navBtn mx-1"
                            style={{
                              backgroundColor: `${canUserRescheduleMatch ? rescheduleData.gameColor : ""}`,
                            }}
                            onClick={() => setShowRequest(true)}
                          >
                            View Match Date Request
                          </button>
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {!rescheduleRequest && (
                      <Tippy
                        content={
                          rescheduleData?.isLockReschedule
                            ? "Rescheduling has been locked for this match."
                            : rescheduleData && isSetPlayerDeadlineExceeded(rescheduleData.matchDateTime, rescheduleData.setPlayerLimit)
                              ? "Too late to reschedule. Match rescheduling should occur before the set player deadline."
                              : null
                        }
                        animation="shift-toward"
                        disabled={
                          !rescheduleData?.isLockReschedule &&
                          !isSetPlayerDeadlineExceeded(rescheduleData.matchDateTime, rescheduleData.setPlayerLimit)
                        }
                      >
                        {!isScrim && (
                          <span>
                            <span>
                              <button
                                className="newMatchDate mustAButton navBtn mx-1"
                                style={{
                                  backgroundColor: `${canUserRescheduleMatch ? rescheduleData.gameColor : ""}`,
                                }}
                                onClick={() => setRescheduleMatch(true)}
                                disabled={!canUserRescheduleMatch}
                              >
                                Request new match Date
                              </button>
                            </span>
                          </span>
                        )}
                      </Tippy>
                    )}

                    {rescheduleRequest && !hasReceivedRequest(rescheduleRequest.recipient, teamIdOfUser) ? (
                      <span>
                        <button
                          className="newMatchDate mustAButton navBtn mx-1"
                          style={{
                            backgroundColor: `${canUserRescheduleMatch ? rescheduleData.gameColor : ""}`,
                          }}
                          onClick={() => setRescheduleMatch(true)}
                          disabled={!canUserRescheduleMatch}
                        >
                          Edit Match Date Request
                        </button>
                      </span>
                    ) : null}

                    {rescheduleRequest && hasReceivedRequest(rescheduleRequest.recipient, teamIdOfUser) ? (
                      <span>
                        <button
                          className="newMatchDate mustAButton navBtn mx-1"
                          style={{
                            backgroundColor: `${canUserRescheduleMatch ? `${rescheduleData.gameColor}` : ""}`,
                          }}
                          onClick={() => setShowRequest(true)}
                        >
                          View Match Date Request
                        </button>
                      </span>
                    ) : null}
                  </>
                )}
                {!!rescheduleRequest &&
                  rescheduleData &&
                  showRequest &&
                  (hasReceivedRequest(rescheduleRequest?.recipient, teamIdOfUser) ||
                    isUserInBothTeams(rescheduleData?.team1, rescheduleData?.team2, userId)) ? (
                  <AcceptRequest
                    onClose={() => setShowRequest(false)}
                    matchDate={rescheduleData?.matchDateTime}
                    rescheduleDate={rescheduleRequest?.rescheduleDate}
                    matchId={matchData?._id}
                    cbSuccess={rescheduleSuccess}
                    cbFailure={rescheduleFailure}
                    isOpen={showRequest}
                  />
                ) : (
                  rescheduleMatch && (
                    <SendRequest
                      onClose={() => setRescheduleMatch(false)}
                      currentDate={rescheduleRequest ? rescheduleRequest.rescheduleDate : rescheduleData?.matchDateTime}
                      gameColor={rescheduleData?.gameColor}
                      reschedule={rescheduleData.reschedule}
                      teamId={teamIdOfUser}
                      matchId={matchData._id}
                      edit={!rescheduleRequest ? false : !hasReceivedRequest(rescheduleRequest.recepient, teamIdOfUser)}
                      cbSuccess={rescheduleSuccess}
                      cbFailure={rescheduleFailure}
                      isTournament={!isLeague && !isScrim}
                    />
                  )
                )}
              </>
            ) : null
          }
        />
        <TempTournCodes
          isOpen={showTournCodes}
          onClose={() => setShowTournCodes(false)}
          team1={matchData?.t1?.name || "[ DELETED ]"}
          team2={matchData?.t2?.name || "[ DELETED ]"}
          tournamentCodes={matchData?.lolData?.tournamentCodes || []}
        />
      </>
    );
  }
};

export default MatchPage;
