import React, { useState, useContext } from "react";
import { AuthModalContext } from "../AuthModal";
import ReactCodeInput from "react-verification-code-input";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AppContext } from "../../../../context/AppContext";

const VerifyEmail = () => {
  const history = useHistory();
  const { setShowAuth } = useContext(AppContext);
  const { registrationData, setRegistrationData, setStep } = useContext(AuthModalContext);
  const [loading, setLoading] = useState(false);
  const [codeIncorrect, setCodeIncorrect] = useState(false);

  const onCodeChange = () => {
    if (codeIncorrect) setCodeIncorrect(false);
  };

  const onCodeComplete = async (code) => {
    setLoading(true);

    try {
      // Check code is correct
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/auth/verifyEmailCode`, { email: registrationData.email, code });

      // Attempt login
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/auth/login`, {
        email: registrationData.email,
        password: registrationData.password,
        rememberMe: registrationData.rememberMe || false,
      });

      // Set session token & hide modal
      localStorage.setItem("sessionToken", data);
      setRegistrationData({});
      setStep(1);
      setShowAuth(false);

      // Redirect to new user welcome page
      history.push("/welcome");
    } catch (e) {
      setCodeIncorrect(true);
      setLoading(false);
    }
  };

  const resendEmail = async () => {
    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/auth/resendEmailCode`, { email: registrationData.email });
      toast.success("Code has been resent to your email");
    } catch (e) {
      toast.error("There was a problem resending your code");
    }
  };

  return (
    <div>
      <div className="modal-header verify-email">
        <h2>Confirm your email address</h2>
        <h2 className="text-14">ENTER YOUR VERIFICATION CODE</h2>
        <div className="text">
          We have send you a 6-digit code to {registrationData.email}. Please verify that this e-mail belongs to you to keep your account
          secure.
        </div>
      </div>
      <div className="modal-body verify-email">
        {codeIncorrect ? (
          <div className="code-incorrect-message">
            <div className="text-12 bold uppercase">Verification failed</div>
            <div className="text-10 faded">Whoops! The code you entered was wrong. Please try again.</div>
          </div>
        ) : null}
        <ReactCodeInput
          autoFocus={true}
          className={`auth-verification-code-input ${codeIncorrect ? "invalid" : ""}`}
          onChange={onCodeChange}
          onComplete={onCodeComplete}
          loading={loading}
          disabled={loading}
          fieldHeight={42}
          fieldWidth={50}
        />
        <button className="auth-resend-code text-12" onClick={resendEmail}>
          Resend code
        </button>

        <div className="faded text-12 pt-3">You can also click on the link in the e-mail, that we send you.</div>
      </div>
    </div>
  );
};

export default VerifyEmail;
