import React, { useContext, useEffect, useState } from "react";
import { Icon } from "@common";
import Dropdown from "../Dropdown/Dropdown";
import NotificationItem from "./NotificationItem/NotificationItem";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../../../../context/AppContext";
import io from "socket.io-client";
import { toast } from "react-toastify";
import NotificationSuccess from "./NotificationSuccess/NotificationSuccess";
import { RiEmotionLaughLine } from "react-icons/ri";
import Axios from "axios";

const socket = io(process.env.REACT_APP_CORE_API);
const sound = new Audio("https://leagues-static.s3.eu-west-2.amazonaws.com/sounds/notification_new.mp3");

// If connection fails
socket.on("disconnect", () => {
  toast.error("Lost connection to the server. Try refreshing the page.", { autoClose: false, toastId: "lost-conn" });
});

const NotificationsDropdown = () => {
  const history = useHistory();
  const { user } = useContext(AppContext);
  const [notifications, setNotifications] = useState(user.state.notifications);
  const [completedNotification, setCompletedNotification] = useState(false);

  const userDispatch = user.dispatch;
  useEffect(() => {
    userDispatch({ type: "update", payload: { notifications } });
  }, [userDispatch, notifications]);

  useEffect(() => {
    socket.emit("userConnected", user.state._id);

    return () => socket.emit("userDisconnected", user.state._id);
  }, [user.state._id]);

  useEffect(() => {
    document.title = `${notifications.length ? `(${notifications.length}) ` : ""}Leagues.gg`;
  }, [notifications]);

  const onMarkAllRead = async () => {
    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/notifications/markAllRead`);
      setNotifications(() => []);
    } catch (e) {
      toast.error("There was a problem");
    }
  };

  const onNewNotification = (newNotification) => {
    setNotifications((notifications) => [{ ...newNotification, new: true }, ...notifications]);
  };

  const onActionComplete = (notification, successMsg = false) => {
    if (successMsg) setCompletedNotification(notification);
    setNotifications((notifications) => notifications.filter(({ _id }) => _id !== notification._id));
  };

  // Listen for new notifications
  useEffect(() => {
    socket.on(`newNotif-${user.state._id}`, (notif) => {
      try {
        const notification = JSON.parse(notif);
        onNewNotification(notification);
        if (notification.user === user.state._id && user.state.notificationSound) sound.play();
      } catch (e) {}
    });
  }, []);

  return (
    <Dropdown
      title="Notifications"
      dots_menu={[
        ["Mark all as read", onMarkAllRead],
        ["Notifications settings", () => history.push(`/account?notification=true`)],
      ]}
      className="notifications-dropdown"
      label={<Icon name="bell" />}
      badge={notifications.length}
      render={() => (
        <>
          {completedNotification && <NotificationSuccess {...completedNotification} />}
          {!notifications.length ? (
            <div className="all-caught-up">
              <RiEmotionLaughLine /> <span> All Caught Up!</span>
            </div>
          ) : (
            notifications.map((notification) => (
              <NotificationItem key={notification._id} {...notification} afterActionComplete={onActionComplete} />
            ))
          )}
        </>
      )}
      footer={
        <Link to="/notifications" className="view-all-link">
          View All
        </Link>
      }
    />
  );
};

export default NotificationsDropdown;
