import React from "react";
import { RiCloseLine } from "react-icons/ri";
import Modal from "react-modal";

const TempTournCodes = ({ isOpen, onClose, team1, teamSelector, team2, tournamentCodes = [] }) => {
  const colorCodes =
    teamSelector === "t1" ? ["Blue", "Red", "Blue", "Red", "Blue", "Red"] : ["Red", "Blue", "Red", "Blue", , "Red", "Blue"];
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="modal auth-modal">
      <div className="modal-close" onClick={onClose}>
        <RiCloseLine />
      </div>
      <div className="modal-header">
        <h2>Tournament Codes</h2>
      </div>
      <div className="modal-body">
        {/* <div className="row smaller-gutter-row team-names">
          <div className="col-6">
            <small className="text-faded">Blue side</small>
            <p className="team-name">
              <strong>{team1}</strong>
            </p>
          </div>
          <div className="col-6">
            <small className="text-faded">Red side</small>
            <p className="team-name">
              <strong>{team2}</strong>
            </p>
          </div>
        </div> */}

        {tournamentCodes.map((code, i) => (
          <div>
            <p className="bold">
              Map {i + 1} ({colorCodes[i]} Side):
            </p>{" "}
            <p>{code}</p>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default TempTournCodes;
