import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "./../../context/AppContext";
import { Loading } from "@common";
import vars from "./../../styles/_variables.scss";
import moment from "moment";
import default_profile_image from "@img/default-profile-pic.png";
import twitter from "@img/twitter-round.svg";
import facebook from "@img/facebook-round.svg";
import twitch from "@img/twitch-round.svg";
import NewsItem from "@components/News/NewsItem/NewsItem";
import { MirrorText, Icon } from "@common";
import Axios from "axios";
import { FiPlay, Fi } from "react-icons/fi";
import { ImCross } from "react-icons/im";
import { FaTwitter } from "react-icons/fa";
import cheerio from "cheerio";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import checkmark from "@img/check-mark.svg";

const ScrollThing = () => {
  return (
    <div className="scroll-thing-major">
      <div className="thing" id="my-element"></div>
    </div>
  );
};

const ScrollThingPlay = () => {
  return (
    <div className="scroll-thing-major play">
      <Icon name="play" pureIcon={<FiPlay size={`1.5em`} />} />
    </div>
  );
};

const ScrollThingCross = () => {
  return (
    <div className="scroll-thing-major cross">
      <Icon name="cross" pureIcon={<ImCross size={`1.2em`} />} />
    </div>
  );
};

const NewsSingle = () => {
  let { slug } = useParams();

  const [related, setRelated] = useState([null, null, null]);
  const [post, setPost] = useState(null);
  const context = useContext(AppContext);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    document.body.appendChild(script);
  });

  useEffect(() => {
    addAView(slug);
    context.ghost.posts
      .read({ slug: slug, include: "tags,authors" })
      .then((post) => {

        const $ = cheerio.load(post.html);
        $("blockquote").append(`<i class='fa fa-quote-left'></i>`)
        $("blockquote").append(`<i class='fa fa-quote-right'></i>`)

        $("blockquote").map((index, data) => {
          data.attribs = {
            ...data.attribs,
            style: "position: relative"
          };
        });

        $(".fa-quote-left").map((index, data) => {
          data.attribs = {
            ...data.attribs,
            style: "position: absolute; left: 0; opacity: 0.3; top: 7px; font-size: 40px; color: #5a5858;"
          };
        });
        $(".fa-quote-right").map((index, data) => {
          data.attribs = {
            ...data.attribs,
            style: "position: absolute; right: 12px; opacity: 0.3; bottom: 5px; font-size: 40px; color: #5a5858;"
          };
        });

        post.html = $.html();

        setPost(post);
      })
      .catch((err) => {
        console.error(err);
      });

    // position: absolute;
    // left: 0;
    // opacity: 0.2;
    // top: 0;
    // font-size: 42px;
    // color: #5a5858;

    context.ghost.posts
      .browse({ limit: 3, include: "tags,authors" })
      .then((posts) => {
        let data = posts.filter((el) => el?.slug !== slug);
        data.meta = posts.meta;
        setRelated(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [slug, context.ghost.posts]);

  var addAView = async (slug) => {
    try {
      // await Axios.post(`${process.env.REACT_APP_CORE_API}/api/auth/register`, {slug});
      console.log("added new view to post.");
    } catch (e) {
      console.log("Error while added new view to post.");
    }
  };

  if (post === null) {
    return <Loading />;
  }

  const timeAgo = moment(post.published_at ?? new Date()).fromNow();
  const publishedDate = moment(post.published_at).utc().format("D. MMMM YYYY");

  let tag = "";
  let tag_color = vars.primary;

  if (typeof post.primary_tag !== "undefined" && post.primary_tag !== null) {
    tag = post.primary_tag.name ?? "";
    tag_color = post.primary_tag.accent_color ?? vars.primary;
  }

  let profile_image = post.authors[0].profile_image ?? default_profile_image;
  let profile_bio = post.authors[0].bio ?? "";
  let profile_name = post.authors[0].name ?? "";
  return (
    <div className="container-fluid news-single">
      <ScrollThing />
      <ScrollThingPlay />
      <ScrollThingCross />
      <MirrorText text={["l34gu3s.gg", "Esp0rt"]} />
      <div className="row">
        <div className="news-featured-image-wrapper">
          <div
            className="news-featured-image"
            style={{
              backgroundImage: `linear-gradient(to top, ${vars.content_bg_color} 20%, rgba(3, 6, 13, 0) 180%), url(${post.feature_image})`,
            }}
          ></div>
        </div>

        <div className="col-12 news-single-content-wrapper">
          <div>
            <span className="ribbon large primary" style={{ backgroundColor: tag_color }}>
              {tag}
            </span>
            <span className="ribbon large default">{timeAgo}</span>
          </div>
          <h1 className="news-heading center">{post.title}</h1>
          <span>{publishedDate}</span>
          <div
            className="news-single-content ghost-content"
            dangerouslySetInnerHTML={{ __html: post.html.replaceAll("<a ", "<a target='_blank' ") }}
          ></div>
          {/* <div className="new-box">
            <div className="new-box-inner">
              <div class="box-header">
                <div class="line__hover">
                  <div class="box-flex">
                    <div className="new-box-image">
                      <img src="/static/media/logo-header.6052ba6d.svg" alt="Leagues logo" />
                    </div>
                    <div className="new-ibox-image-text">
                      <strong class="uppercase logo__text">Leagues.gg</strong>
                    </div>
                  </div>
                </div>
                <a className="new-box-twitter" href="https://twitter.com/Leagues_GG" target="_blank">
                  <FaTwitter />
                </a>
              </div>
              <div
                className="news-single-content ghost-content"
                dangerouslySetInnerHTML={{ __html: post.html.replaceAll("<a ", "<a target='_blank' ") }}
              ></div>
            </div>
          </div> */}
          <div className="news-single-content-after">
            <hr />

            <div className="author">
              <span className="text-12 article-by">Article by:</span>
              {post.authors.length &&
                post.authors.map((data) => (
                  <div className="author-info">
                    <img src={data.profile_image ?? default_profile_image} alt="" />
                    <div className="author-name-bio">
                      <span className="text-12">{data.name}</span>
                      <span className="text-12 text-faded">{data.bio}</span>
                    </div>
                  </div>
                ))}
            </div>

            <div className="social-follow-container">
              <b className="text-14 text-faded moreUpdate">More updates on</b>

              <div className="social-links">
                <a href="https://www.twitch.tv/leaguesggdenmark" target="_blank">
                  <img src={twitch} alt="Twitch" />
                </a>
                <a href="https://www.facebook.com/LeaguesDK" target="_blank">
                  <img src={facebook} alt="Facebook" />
                </a>
                <a href="https://twitter.com/Leagues_GG" target="_blank">
                  <img src={twitter} alt="Twitter" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <h2 className="text-18 uppercase">Latest news</h2>
          <hr />

          <div className="row smaller-gutter-row">
            {related.map((item) => {
              if (item === null) {
                return <NewsItem loading />;
              }

              return (
                <NewsItem
                  key={item.id}
                  title={item.title}
                  excerpt={item.excerpt}
                  image={item.feature_image}
                  published={item.published_at}
                  slug={item.slug}
                  tag={item.primary_tag}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsSingle;
