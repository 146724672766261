import Axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import Modal from "react-modal";
import { AppContext } from '../../../context/AppContext';
import verifyToken from '../../../context/utils/verifyToken';
import ConnectCSGO from "./ConnectCSGO";

export const ConnectToSteamModal = ({ }) => {
    const [open, setOpen] = useState(false);
    const onClose = () => setOpen(false);
    const { authenticated, selectedGame, user } = useContext(AppContext);

    useEffect(() => {
        if (!user.state || user?.state?.steamConnected) return;
        const validTokenData = verifyToken();
        if (!validTokenData) return;
        Axios.get(`${process.env.REACT_APP_CSGO_API}/api/connect/steam/isConnected`).then(({ data }) => {
            if (data.isConnected) user.dispatch({ type: "update", payload: { steamConnected: true } });
            if (data.reconnectRequired && !window.location.href.includes("verify")) setOpen(true);
        }).catch(({error}) => {
            console.log('error', error)
        })
    }, [authenticated, user]);
    if (!authenticated || selectedGame?.shortName !== "CSGO") return null;

    return (
        <Modal shouldCloseOnOverlayClick={false} isOpen={open} onRequestClose={onClose} className="modal" contentLabel="Example Modal">
            {/* <div className="modal-close" onClick={onClose}>
                <RiCloseLine />
            </div> */}
            <div className="modal-header">
                <h2>Connect to steam</h2>
                <h3>We have updated our site user policies. In order to enjoy new features, please connect to steam for your player profile</h3>
            </div>
            <div className="modal-body">
                <ConnectCSGO />
            </div>
        </Modal>
    )
}
