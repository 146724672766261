import React, { useEffect, useState } from "react";
import { SortableList, SortableListItem } from "@common";
import moment from "moment";
import { Link } from "react-router-dom";
import Axios from "axios";
import { getTeamsAvgRank } from "@utils";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
const Applications = ({ matchups, acceptedMatchUp, setPageNameWithCount, game, team1, numberOfMatches, date }) => {
  const history = useHistory();
  const [matchupsData, setMatchupsData] = useState([]);

  useEffect(() => {
    setMatchupsData(matchups);
    setPageNameWithCount(matchups.length);
  }, [matchups]);

  const onDelete = async (matchUpId, scrim, team2) => {
    const postData = {
      t1: team1._id,
      t2: team2._id,
      scoreSystem: "5f6c76bc09fc214fea5dc921",
      bestOf: numberOfMatches,
      datetime: date,
      week: -1,
      round: -1,
    };

    let vetoEnable = game.shortName === "CSGO" ? true : false;
    try {
      const data = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/matchups/${matchUpId}`, { id: matchUpId, scrim });
      const result = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/matches/scrimMatch?isVeto=${vetoEnable}`, {
        scrim,
        ...postData,
      });
      toast.success("Request accepted");
      history.push("chat");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (e) {
      toast.error(`Whoops, there was a problem with accepting request`);
    }
  };

  return (
    <div className="row smaller-gutter-row">
      <div className="col-12">
        <SortableList
          classes="champions-stats-sortable-list"
          config={[
            { col: 2, title: "Submitted", prop_name: "submitted", is_sortable: true, alignment: "left" },
            { col: 3, title: "Team", prop_name: "team", /*is_sortable: true,*/ alignment: "left" },
            { col: 2, title: "Team Rank", prop_name: "teamrank", is_sortable: true, alignment: "left" },
            { col: 2, title: "Nationality", prop_name: "region", is_sortable: true, alignment: "left" },
            {
              col: 3,
              title: "Actions",
              prop_name: "actions",
              /*is_sortable: true,*/ alignment: "left",
              class: "accept-request",
              style: { justifyContent: "space-between" },
            },
          ]}
        >
          {matchupsData.map(({ _id, createdAt, team, scrim }, i) => {
            return (
              <SortableListItem
                style={{ marginRight: "-15px" }}
                key={`item-matchup-${i}`}
                submitted={moment(createdAt).fromNow()}
                team={
                  <React.Fragment>
                    <>
                      <img
                        onClick={() => window.open(`/teams/${team._id}`, "_blank")}
                        style={{ borderRadius: "100%", cursor: "pointer" }}
                        src={team.logo}
                        alt={team.name}
                        className={team.logoCropped || team.logoCropped === undefined ? "image-40 border-radius-100" : "image-40"}
                      />
                      <span style={{ cursor: "pointer" }} onClick={() => window.open(`/teams/${team._id}`, "_blank")}>
                        {team.name}
                      </span>
                    </>
                  </React.Fragment>
                }
                teamrank={getTeamsAvgRank(team.members, game)}
                region={
                  <>
                    <img src={`https://flagcdn.com/w20/${team.nationality.code?.toLowerCase()}.png`}></img>{" "}
                    <span className="ml-3">{team.nationality.name} </span>
                  </>
                }
                actions={
                  <React.Fragment>
                    <Link target={"_blank"} to={`/teams/${team._id}`} className="text-12 primary-link">
                      view team
                    </Link>
                    <button className="ml-2 text-12 primary button ml-4" onClick={() => onDelete(_id, scrim, team)}>
                      Accept Request
                    </button>
                  </React.Fragment>
                }
              />
            );
          })}
        </SortableList>
      </div>
    </div>
  );
};

export default Applications;
