export const TIMEZONES = [
  { name: "(UTC-11:00) Coordinated Universal Time-11", value: "Etc/GMT+11" },
  { name: "(UTC-10:00) Aleutian Standard Time", value: "America/Adak" },
  { name: "(UTC-10:00) Hawaiian Standard Time", value: "Pacific/Honolulu" },
  { name: "(UTC-09:30) Marquesas Standard Time", value: "Pacific/Marquesas" },
  { name: "(UTC-09:00) Alaskan Standard Time", value: "America/Anchorage" },
  { name: "(UTC-09:00) UTC-09", value: "Etc/GMT+9" },
  { name: "(UTC-08:00) Pacific Standard Time (Mexico)", value: "America/Tijuana" },
  { name: "(UTC-08:00) UTC-08", value: "Etc/GMT+8" },
  { name: "(UTC-08:00) Pacific Standard Time", value: "America/Los_Angeles" },
  { name: "(UTC-07:00) US Mountain Standard Time", value: "US/Arizona" },
  { name: "(UTC-07:00) Mountain Standard Time (Mexico)", value: "America/Chihuahua" },
  { name: "(UTC-07:00) Mountain Standard Time", value: "America/Hermosillo" },
  { name: "(UTC-06:00) America Standard Time", value: "America/Chicago" },
  { name: "(UTC-06:00) Central Standard Time", value: "America/Regina" },
  { name: "(UTC-06:00) Easter Island Standard Time", value: "Pacific/Easter" },
  { name: "(UTC-06:00) Central Standard Time (Mexico)", value: "America/Mexico_City" },
  { name: "(UTC-06:00) Canada Central Standard Time", value: "America/Regina" },
  { name: "(UTC-05:00) SA Pacific Standard Time", value: "America/Bogota" },
  { name: "(UTC-05:00) Eastern Standard Time (Mexico)", value: "America/Cancun" },
  { name: "(UTC-05:00) Eastern Standard Time", value: "America/Panama" },
  { name: "(UTC-05:00) Cuba Standard Time", value: "America/Havana" },
  { name: "(UTC-05:00) US Eastern Standard Time", value: "America/New_York" },
  { name: "(UTC-04:00) Paraguay Standard Time", value: "America/Asuncion" },
  { name: "(UTC-04:00) Atlantic Standard Time", value: "America/Glace_Bay" },
  { name: "(UTC-04:00) Venezuela Standard Time", value: "America/Caracas" },
  { name: "(UTC-04:00) Central Brazilian Standard Time", value: "America/Cuiaba" },
  { name: "(UTC-04:00) SA Western Standard Time", value: "America/Cuiaba" },
  { name: "(UTC-04:00) Pacific SA Standard Time", value: "America/Santiago" },
  { name: "(UTC-03:30) Newfoundland Standard Time", value: "America/St_Johns" },
  { name: "(UTC-03:00) Tocantins Standard Time", value: "America/Araguaina" },
  { name: "(UTC-03:00) E. South America Standard Time", value: "America/Araguaina" },
  { name: "(UTC-03:00) SA Eastern Standard Time", value: "America/Cayenne" },
  { name: "(UTC-03:00) Argentina Standard Time", value: "America/Buenos_Aires" },
  { name: "(UTC-03:00) Greenland Standard Time", value: "America/Nuuk" },
  { name: "(UTC-03:00) Montevideo Standard Time", value: "America/Montevideo" },
  { name: "(UTC-03:00) Magallanes Standard Time", value: "America/Punta_Arenas" },
  { name: "(UTC-03:00) Bahia Standard Time", value: "America/Bahia" },
  { name: "(UTC-02:00) Universal Time-02", value: "Etc/GMT+2" },
  { name: "(UTC-01:00) Azores Standard Time", value: "Atlantic/Azores" },
  { name: "(UTC-01:00) Cape Verde Standard Time", value: "Atlantic/Cape_Verde" },
  { name: "(UTC) CoordiUniversal Time UTC", value: "	Etc/UTC" },
  { name: "(UTC+00:00) GMT Standard Time", value: "Etc/GMT" },
  { name: "(UTC+00:00) Greenwich Standard Time", value: "Etc/Greenwich" },
  { name: "(UTC+00:00) Sao Tome Standard Time", value: "Africa/Sao_Tome" },
  { name: "(UTC+01:00) Morocco Standard Time", value: "Africa/Casablanca" },
  { name: "(UTC+01:00) W. Europe Standard Time", value: "Europe/Amsterdam" },
  { name: "(UTC+01:00) Central Europe Standard Time", value: "Europe/Belgrade" },
  { name: "(UTC+01:00) Romance Standard Time", value: "Europe/Brussels" },
  { name: "(UTC+01:00) Central European Standard Time", value: "Europe/Sarajevo" },
  { name: "(UTC+01:00) W. Central Africa Standard Time", value: "Europe/Rome" },
  { name: "(UTC+02:00) Jordan Standard Time", value: "Asia/Amman" },
  { name: "(UTC+02:00) GTB Standard Time", value: "Europe/Athens" },
  { name: "(UTC+02:00) Middle East Standard Time", value: "Asia/Beirut" },
  { name: "(UTC+02:00) Egypt Standard Time", value: "Africa/Cairo" },
  { name: "(UTC+02:00) E. Europe Standard Time", value: "Asia/Nicosia" },
  { name: "(UTC+02:00) Syria Standard Time", value: "Asia/Damascus" },
  { name: "(UTC+02:00) West Bank Standard Time", value: "Asia/Hebron" },
  { name: "(UTC+02:00) South Africa Standard Time", value: "Africa/Maputo" },
  { name: "(UTC+02:00) FLE Standard Time", value: "Europe/Helsinki" },
  { name: "(UTC+02:00) Israel Standard Time", value: "Asia/Jerusalem" },
  { name: "(UTC+02:00) Kaliningrad Standard Time", value: "Europe/Kaliningrad" },
  { name: "(UTC+02:00) Sudan Standard Time", value: "Africa/Khartoum" },
  { name: "(UTC+02:00) Libya Standard Time", value: "Africa/Tripoli" },
  { name: "(UTC+02:00) Namibia Standard Time", value: "Africa/Windhoek" },
  { name: "(UTC+03:00) Arabic Standard Time", value: "Asia/Baghdad" },
  { name: "(UTC+03:00) Turkey Standard Time", value: "Europe/Istanbul" },
  { name: "(UTC+03:00) Arab Standard Time", value: "Asia/Riyadh" },
  { name: "(UTC+03:00) Belarus Standard Time", value: "Europe/Minsk" },
  { name: "(UTC+03:00) Russian Standard Time", value: "Europe/Moscow" },
  { name: "(UTC+03:00) E. Africa Standard Time", value: "Africa/Nairobi" },
  { name: "(UTC+03:30) Iran Standard Time", value: "Asia/Tehran" },
  { name: "(UTC+04:00) Arabian Standard Time", value: "Asia/Dubai" },
  { name: "(UTC+04:00) Astrakhan Standard Time", value: "Europe/Astrakhan" },
  { name: "(UTC+04:00) Azerbaijan Standard Time", value: "Asia/Baku" },
  { name: "(UTC+04:00) Russia Time Zone 3", value: "Europe/Samara" },
  { name: "(UTC+04:00) Mauritius Standard Time", value: "Indian/Mauritius" },
  { name: "(UTC+04:00) Saratov Standard Time", value: "Europe/Saratov" },
  { name: "(UTC+04:00) Georgian Standard Time", value: "Asia/Tbilisi" },
  { name: "(UTC+04:00) Volgograd Standard Time" },
  { name: "(UTC+04:00) Caucasus Standard Time", value: "Asia/Yerevan" },
  { name: "(UTC+04:30) Afghanistan Standard Time", value: "Asia/Kabul" },
  { name: "(UTC+05:00) West Asia Standard Time", value: "Asia/Tashkent" },
  { name: "(UTC+05:00) Ekaterinburg Standard Time", value: "Asia/Yekaterinburg" },
  { name: "(UTC+05:00) Pakistan Standard Time", value: "Asia/Karachi" },
  { name: "(UTC+05:00) Qyzylorda Standard Time", value: "Asia/Qyzylorda" },
  { name: "(UTC+05:30) India Standard Time", value: "Asia/Kolkata" },
  { name: "(UTC+05:30) Sri Lanka Standard Time", value: "Asia/Colombo" },
  { name: "(UTC+05:45) Nepal Standard Time", value: "Asia/Kathmandu" },
  { name: "(UTC+06:00) Bangladesh Standard Time", value: "Asia/Dhaka" },
  { name: "(UTC+06:00) Omsk Standard Time", value: "Asia/Omsk" },
  { name: "(UTC+06:30) Myanmar Standard Time", value: "Asia/Yangon" },
  { name: "(UTC+07:00) SE Asia Standard Time", value: "Asia/Bangkok" },
  { name: "(UTC+07:00) Altai Standard Time", value: "Asia/Hovd" },
  { name: "(UTC+07:00) W. Mongolia Standard Time", value: "Asia/Hovd" },
  { name: "(UTC+07:00) North Asia Standard Time", value: "Asia/Krasnoyarsk" },
  { name: "(UTC+07:00) N. Central Asia Standard Time", value: "Asia/Novosibirsk" },
  { name: "(UTC+07:00) Tomsk Standard Time", value: "Asia/Tomsk" },
  { name: "(UTC+08:00) China Standard Time", value: "Asia/Shanghai" },
  { name: "(UTC+08:00) North Asia East Standard Time", value: "Asia/Irkutsk" },
  { name: "(UTC+08:00) Singapore Standard Time", value: "Asia/Singapore" },
  { name: "(UTC+08:00) W. Australia Standard Time", value: "Australia/Perth" },
  { name: "(UTC+08:00) Taipei Standard Time", value: "Asia/Taipei" },
  { name: "(UTC+08:00) Ulaanbaatar Standard Time", value: "Asia/Ulaanbaatar" },
  { name: "(UTC+08:45) Aus Central W. Standard Time", value: "Australia/Eucla" },
  { name: "(UTC+09:00) Transbaikal Standard Time", value: "Asia/Chita" },
  { name: "(UTC+09:00) Tokyo Standard Time", value: "Asia/Tokyo" },
  { name: "(UTC+09:00) North Korea Standard Time", value: "Asia/Pyongyang" },
  { name: "(UTC+09:00) Korea Standard Time", value: "Asia/Seoul" },
  { name: "(UTC+09:00) Yakutsk Standard Time", value: "Asia/Yakutsk" },
  { name: "(UTC+09:30) Cen. Australia Standard Time", value: "Australia/Adelaide" },
  { name: "(UTC+09:30) AUS Central Standard Time", value: "Australia/Darwin" },
  { name: "(UTC+10:00) E. Australia Standard Time", value: "Australia/Brisbane" },
  { name: "(UTC+10:00) AUS Eastern Standard Time", value: "Australia/Sydney" },
  { name: "(UTC+10:00) West Pacific Standard Time", value: "Pacific/Guam" },
  { name: "(UTC+10:00) Tasmania Standard Time", value: "Australia/Hobart" },
  { name: "(UTC+10:00) Vladivostok Standard Time", value: "Asia/Vladivostok" },
  { name: "(UTC+10:30) Lord Howe Standard Time", value: "Australia/Lord_Howe" },
  { name: "(UTC+11:00) Bougainville Standard Time", value: "Pacific/Bougainville" },
  { name: "(UTC+11:00) Magadan Standard Time", value: "Asia/Magadan" },
  { name: "(UTC+11:00) Norfolk Standard Time", value: "Pacific/Norfolk" },
  { name: "(UTC+11:00) Sakhalin Standard Time", value: "Asia/Sakhalin" },
  { name: "(UTC+11:00) Central Pacific Standard Time", value: "Pacific/Noumea" },
  { name: "(UTC+12:00) New Zealand Standard Time", value: "Pacific/Auckland" },
  { name: "(UTC+12:00) Universal Time+12 	UTC+12", value: "Etc/GMT-12" },
  { name: "(UTC+12:00) Fiji Standard Time", value: "Pacific/Fiji" },
  { name: "(UTC+12:45) Chatham Islands Standard Time", value: "Pacific/Chatham" },
  { name: "(UTC+13:00) Universal Time+13 	UTC+13", value: "Etc/GMT-13" },
  { name: "(UTC+13:00) Tonga Standard Time", value: "Pacific/Tongatapu" },
];
