import React, { useContext } from "react";
import { SimplePageWithTabs } from "@common";
import MyAccount from "./SettingsTabs/MyAccount/MyAccount";
import MySocials from "./SettingsTabs/MySocials/MySocials";
import MyPrivacy from "./SettingsTabs/MyPrivacy/MyPrivacy";
import Sound from "./SettingsTabs/Sound/Sound";
import MyPlayersCoaches from "./SettingsTabs/MyPlayersCoaches/MyPlayersCoaches";
import { useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import ChangePassword from "../ChangePassword/ChangePassword";

const Settings = () => {
  const history = useHistory();
  const { user } = useContext(AppContext);

  const search = useLocation().search;
  const name = new URLSearchParams(search).get("notification");
  const tabs = {
    "My Account": <MyAccount />,
    "Change Password": <ChangePassword />,
    Socials: <MySocials />,
    "My Players & Coaches": <MyPlayersCoaches />,
    Privacy: <MyPrivacy />,
    Notifications: <Sound />,
  };

  return (
    <SimplePageWithTabs
      title="Settings"
      tabs={tabs}
      currentPage={name ? 5 : 0}
      top_right={
        <button className="button bordered" onClick={() => history.push(`/players/${user.state._id}`)}>
          View Profile
        </button>
      }
    />
  );
};

export default Settings;
