import React, { useContext, useEffect } from "react";
import { CheckboxSlider, SearchInput, FinderCard, DynamicSearch } from "@common";
import { CreateScrimModal } from "../../../_modals/CreateScrimModal/CreateScrimModal";
import { useState } from "react";
import Loading from "../../../_common/Loading/Loading";
import Axios from "axios";
import { AppContext } from "../../../../context/AppContext";
import { countries } from "@utils";

const ScrimsFinder = ({ my_active, setPageNameWithCount = null }) => {
  const {
    authenticated,
    user: {
      state: { _id },
    },
    selectedGame,
  } = useContext(AppContext);
  const [nationalities, setNationalities] = useState([]);
  const [createScrim, setCreateScrim] = useState(false);
  const [loading, setLoading] = useState(true);
  const [scrims, setScrims] = useState([]);
  const [teamRegion, setTeamRegion] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [teamSize, setTeamSize] = useState([]);
  const [teamFormat, setTeamFormat] = useState([]);
  const numberOfMatchesOptions = selectedGame.shortName === "LoL" ? [1, 2, 3, 4, 5] : [1, 3, 5];
  const [numberOfMatches, setNumberOfMatches] = useState(["1", "2", "3", "4", "5", "99999"]);
  const [teamRanks, setTeamRanks] = useState({
    minRank: -1,
    maxRank: -1,
  });
  const generateResultArray = (array, value) => {
    return array.includes(value) ? array.filter((val) => val !== value) : [...array, value];
  };

  const generateFilters = () => {
    let filters = "?";
    if (selectedGame._id) filters += "gameId=" + selectedGame._id + "&";
    if (searchString) filters += "search=" + searchString + "&";
    if (authenticated && my_active) filters += "user=" + _id + "&";
    if (teamSize.length) filters += "size=" + teamSize.join(",") + "&";
    if (teamFormat.length) filters += "formats=" + teamFormat.join(",") + "&";
    if (teamRanks.minRank > -1) filters += "minRank=" + teamRanks.minRank + "&";
    if (teamRanks.maxRank > -1) filters += "maxRank=" + teamRanks.maxRank + "&";
    if (teamRegion.length > 0) filters += "teamRegion=" + teamRegion + "&";
    if (numberOfMatches) filters += "numberOfMatches=" + numberOfMatches + "&";
    return filters.length > 1 ? filters : "";
  };

  const generateFiltersForCounting = () => {
    let filters = "?";
    if (selectedGame._id) filters += "gameId=" + selectedGame._id + "&";
    if (authenticated) filters += "user=" + _id + "&";

    return filters.length > 1 ? filters : "";
  };

  const getScrims = () => {
    authenticated &&
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/scrims${generateFiltersForCounting()}`).then(({ data }) => {
        let haveDataWithTeam = data.filter((scrim) => scrim.team !== null);
        setPageNameWithCount !== null && setPageNameWithCount(Number(haveDataWithTeam.length));
      });

    Axios.get(`${process.env.REACT_APP_CORE_API}/api/scrims${generateFilters()}`).then(({ data }) => {
      console.log("dataa", data);
      data.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });
      setScrims(data);
      console.log("SCRIMS : ", data);
      setLoading(false);
    });
  };
  useEffect(() => {
    getScrims();

    setNationalities(
      countries
        .filter(({ demonym }) => demonym)
        .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
        .sort((a, b) => a.demonym.localeCompare(b.demonym))
    );
  }, []);

  useEffect(() => {
    getScrims();
  }, [teamSize, teamFormat, searchString, createScrim, teamRanks, selectedGame, teamRegion, numberOfMatches]);

  const handleRegionFilter = (event) => {
    if (event.target.value === "_DEFAULT_") {
      setTeamRegion(false);
    } else {
      setTeamRegion(event.target.value);
    }
  };

  const handleNumberOfMatches = (event) => {
    const { name } = event.target;
    let formats = generateResultArray(numberOfMatches, name);
    setNumberOfMatches(formats);
  };

  const handleSearchChange = (event) => {
    setSearchString(event.target.value);
  };

  const handleRankChange = (event) => {
    setTeamRanks({ ...teamRanks, [event.target.name]: parseInt(event.target.value) });
  };

  const resetFilters = () => {
    setTeamRegion([]);
    setTeamFormat([]);
    setTeamSize([]);
    setSearchString("");
    setNumberOfMatches(["1", "2", "3", "4", "5", "99999"]);
    setTeamRanks({
      minRank: -1,
      maxRank: -1,
    });
  };
  return (
    <div className="row">
      <div className="col-3 border-right">
        {authenticated && (
          <button className="mb-4 button primary thicc" onClick={() => setCreateScrim(true)}>
            Create scrim
          </button>
        )}
        <CreateScrimModal open={createScrim && authenticated} onClose={() => setCreateScrim(false)} />
        <div className="faded bold uppercase  mb-2">Nationality</div>

        <select
          className="select select-arrow select-text"
          name="teamRegion"
          value={teamRegion || "_DEFAULT_"}
          onChange={handleRegionFilter}
        >
          <option value="_DEFAULT_">All Nationalities</option>

          {nationalities.map(({ alpha2Code, demonym }) => (
            <option key={alpha2Code} value={JSON.stringify({ code: alpha2Code, name: demonym })}>
              {demonym}
            </option>
          ))}
        </select>

        {selectedGame.gameRanks && selectedGame.gameRanks.length && selectedGame.shortName === "LoL" && (
          <>
            <div className="faded bold uppercase mt-4 mb-2">Min rank</div>
            <select className="select select-arrow select-text" value={teamRanks.minRank} name="minRank" onChange={handleRankChange}>
              <option value={-1} disabled>
                Select Rank
              </option>
              {selectedGame.gameRanks &&
                selectedGame.gameRanks?.map(
                  (rank, index) =>
                    rank &&
                    rank.toLowerCase() != "no rank found" &&
                    teamRanks.maxRank <= index && (
                      <option value={index} key={index}>
                        {rank}
                      </option>
                    )
                )}
            </select>
          </>
        )}
        {selectedGame.gameRanks && selectedGame.gameRanks.length && selectedGame.shortName === "LoL" && (
          <>
            <div className="faded bold uppercase mt-4 mb-2">Max rank</div>
            <select className="select select-arrow select-text" value={teamRanks.maxRank} name="maxRank" onChange={handleRankChange}>
              <option value={-1} disabled>
                Select Rank
              </option>
              {selectedGame.gameRanks &&
                selectedGame.gameRanks?.map(
                  (rank, index) =>
                    rank &&
                    rank.toLowerCase() != "no rank found" &&
                    teamRanks.minRank >= index && (
                      <option value={index} key={index}>
                        {rank}
                      </option>
                    )
                )}
            </select>
          </>
        )}

        {numberOfMatchesOptions.length && (
          <>
            <div className="faded bold uppercase mt-4 mb-2">Number of matches</div>

            {numberOfMatchesOptions?.map((format, index) => (
              <CheckboxSlider
                key={`${index}-asdasdas`}
                label={String(format)}
                onChange={handleNumberOfMatches}
                value={numberOfMatches.includes(String(format))}
              />
            ))}
          </>
        )}

        <button className="button default thicc mt-4" onClick={resetFilters}>
          Reset all filters
        </button>
      </div>
      <div className="col-9">
        <SearchInput
          placeholder={"Search teams"}
          onChange={handleSearchChange}
          value={searchString}
          hideButton={true}
          style={{ backgroundColor: "#12141a" }}
          inputStyle={{ paddingLeft: "20px" }}
        />

        {loading && (
          <div className="center mt-4">
            <Loading />
          </div>
        )}

        {!loading && !scrims.length && (
          <div className="center mt-4">
            <p>Currently no scrims available.</p>
          </div>
        )}
        {!loading &&
          scrims.map(
            (item) =>
              item.team && (
                <FinderCard
                  croppedLogo={item.team.logoCropped}
                  key={item._id}
                  isScrimUser={!!my_active && item.createdBy && item.createdBy._id == _id}
                  scrim
                  button_label="Go to scrim"
                  button_link_to={`/scrim/${item._id}`}
                  item={item}
                  showApplications={!!my_active}
                />
              )
          )}
      </div>
    </div>
  );
};

export default ScrimsFinder;
