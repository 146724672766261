import React from 'react';
import { Draggable, state } from 'react-page-maker';
import { RiEditLine,RiCloseLine } from 'react-icons/ri';
import { toast } from "react-toastify";
import EditImageModal from "../../_modals/EditImageModal/EditImageModal";
import './style.scss';
class DraggableImage extends React.Component {
  state = {
    showColorPicker: false,
    background: '',
    description: 'descriptiondescription',
    toggleEdit:false,
    url:'https://www.youtube.com/watch?v=dgwXgi2BVXs',
    showImageModal:false
  };

   onEditImage = async (image) => {
    try {
      this.changePayload('url',image);
      this.toggleShowImageModal();
    } catch (err) {
      toast.error("There was a problem updating image.");
    }
  };

  toggleShowImageModal = () => {
      this.setState({showImageModal:!this.state.showImageModal});
  }
  toggleEditField = (fieldName) => {
    if(this.validURL(this.state.url)){
      this.setState({toggleEdit: fieldName});
    }else{
      toast.success("Please check URL carefully.");
    }
    
  }
  

  handleChangeComplete = (color) => {
    const { id, dropzoneID, parentID }= this.props;
    this.setState({ background: color.hex }, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: { background: color.hex }
      });
    });
  };

  validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  changePayload = (payLoadKey,value) => {
  
      const { id, dropzoneID, parentID,payload }= this.props;
      this.setState({ [payLoadKey]: value }, () => {
        state.updateElement(id, dropzoneID, parentID, {
          payload: { ...payload, [payLoadKey]: value }
        });
      });
  
    
    // this.setState({toggleEdit: ''});
  }

  toggleColorPicker = () => {
    this.setState({
      showColorPicker: !this.state.showColorPicker
    });
  }

  
  render() {
    // console.log('this.props-on ModuleVideo-',this.props.payload)
    const {
      id, showBasicContent, showPreview,
      dropzoneID, parentID, 
      // name, 
      payload
    } = this.props;


    const {showImageModal} =  this.state;
    
    // const heading =  payload && payload.heading || this.state.heading;
    const url = payload.url || this.state.url;
    
    

    if (showBasicContent) {
      // console.log('this.propsthis.props',this.props)
      const {name} = this.props;
      return (
        <>
        <Draggable
          { ...this.props } 
      >{name}</Draggable></>
      )
    }

    if (showPreview) {
      return (
        <>
        <header >
        
        <h2 className="pb_ele_header_heading">IMAGE</h2>
        


        <div className="form-row">
            
              <div
                className="upload-image-btn"
                style={{ backgroundImage: `url(${url})`, width: 550, height: 400 }}
                onClick={() => this.toggleShowImageModal()}
              >
                
              </div>
            </div>
          <br/>
          
        </header>
         </>
      );
    }

    return (
      <Draggable { ...this.props } >
        <div className="actions">
            <span className="action_type" title="Remove element"><RiCloseLine onClick={() => state.removeElement(id, dropzoneID, parentID)} color='white' /></span>
          </div>
        <header >
        
        <h2 className="pb_ele_header_heading">IMAGE</h2>
        


        <div className="form-row">
            
              <div
                className="upload-image-btn"
                style={{ backgroundImage: `url(${url})`, width: 550, height: 400 }}
                onClick={() => this.toggleShowImageModal()}
              >
                <RiEditLine />
              </div>
            </div>

            <EditImageModal
              name="Image"
              width={550}
              height={400}
              crop={false}
              rotate={false}
              borderRadius={0}
              open={showImageModal}
              onClose={() => this.toggleShowImageModal()}
              afterImageUpload={(file) => this.onEditImage(file)}
            />




          <br/>
          
        </header>
      </Draggable>
    )
  }
};

export default DraggableImage;
