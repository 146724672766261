import React, { useState, useEffect, useContext } from "react";
import { MirrorText, Icon, Loading } from "@common";
import { AppContext } from "../../context/AppContext";
import Axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import HomeNonCustomized from "./Home_non_customized";
import HomeCustomized from "./Home_customized";
import HomeCustomizedOrgUpdate from "./Home_customizedOrgUpdate";
import HomeCustomizedProUser from "./Home_customizedProUser";
import {ScrollThing, ScrollThingPlay, ScrollThingCross} from "./HomeSegments/ScrollThing";

const Home = () => {
    const history = useHistory();
    const { shortName } = useParams();
    const { selectedGame, setSelectedGame, authenticated, user } = useContext(AppContext);
    
    const [conditionalHomePage, setConditionalHomePage] = useState(<HomeNonCustomized />)
    // const [conditionalHomePage, setConditionalHomePage] = useState(<Loading height={`30px`} width={`30px`} />)

    useEffect(() => {
        shortName && Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
            var singleGame = data.filter((game) => game.shortName.toLowerCase() === shortName.toLowerCase());
            if(singleGame.length){
                setSelectedGame(singleGame[0]);
                window.location.replace('/');
            }
        });
    },[shortName]);

    useEffect(() => {
        if(selectedGame && selectedGame.frontpage){
            if(authenticated){
                  // console.log("Loggedin user is a Normal (No Pro No Org Update)")
                  setConditionalHomePage(<HomeCustomized userType="LoggedIn" segmentData={selectedGame.frontpage.LoggedIn} />); //If Loggedin user is Normal (No Pro No Org Update)
            }else{
                // console.log("User Not Logged In)")
                setConditionalHomePage(<HomeCustomized userType="NonLoggedIn" segmentData={selectedGame.frontpage.Default} />); // If user is not loggedin
            }
        }
    }, [selectedGame,authenticated]);

  return (
    <div>
      {/* <ScrollThing />
      <ScrollThingPlay />
      <ScrollThingCross /> */}
      <MirrorText text={["l34gu3s.gg", "Esp0rt"]} />
      {conditionalHomePage}
    </div>
  );
};

export default Home;
