import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { RiCloseLine } from "react-icons/ri";
import { useForm } from "react-hook-form";
import EditImageModal from "../../_modals/EditImageModal/EditImageModal";
import Axios from "axios";
import { toast } from "react-toastify";

const RenameInGameNameModal = ({ open, onClose, currentName=null, onUpdateName,type,game,currentavatarImage,
  currentabout,
  currentheaderImage }) => {
   
  const [newName,setNewName] = useState('');
  const [about,setAbout] = useState('');
  const { register, handleSubmit, errors } = useForm();

  const [modals, setModals] = useState({
    logo: false,
    banner: false,
  });
  const [images, setImages] = useState({});

  useEffect(() => {
    setNewName(currentName);
    setAbout(currentabout);
    setImages({ 
      ...images, 
      ['logo']: currentavatarImage,
      ['banner']: currentheaderImage,
     });
  },[currentName,currentavatarImage,currentabout,currentheaderImage]);



  const onSubmit = () => {
    onUpdateName(newName,about,images);
    onClose();
  }


  const update = async (type, image) => {
    console.log('type, image-',type, image);
    // headerImage,avatarImage
    try {
    //   await Axios.post(`${process.env.REACT_APP_CORE_API}/api/organisations/${orgData._id}/image`, { type, image });
      setImages({ ...images, [type]: image });
      setModals({ ...modals, [type]: false });
    } catch (err) {
      toast.error("There was a problem updating your organisation's images");
    }
  };

  return (
    <Modal isOpen={open} onRequestClose={onClose} className="modal" contentLabel="Example Modal" shouldCloseOnEsc={false}>
      <div className="modal-close" onClick={onClose}>
        <RiCloseLine />
      </div>
      <div className="modal-header">
        <h2>Edit {type}</h2>
        {/* <h3>Connect your game account to participate in tournaments, scrims etc. You can only create one player profile per game.</h3> */}
      </div>
      <div className="modal-body">
       
        <div className="row form-row sm-gutters">


          {/* LOGO */}
          <div
            className="image-card round-logo"
            onClick={() => setModals({ ...modals, logo: true })}
            style={{ backgroundImage: `url(${images.logo})` }}
          >
            Add Avatar
          </div>
          <EditImageModal
            name="Logo"
            width={250}
            height={250}
            borderRadius={250}
            open={modals.logo}
            onClose={() => setModals({ ...modals, logo: false })}
            afterImageUpload={(file) => update("logo", file)}
          />

          {/* BANNER */}
          <div
            className="image-card"
            onClick={() => setModals({ ...modals, banner: true })}
            style={{ backgroundImage: `url(${images.banner})` }}
          >
            Add Banner
          </div>

          <EditImageModal
            name="Banner"
            width={800}
            height={200}
            crop={false}
            rotate={false}
            borderRadius={0}
            open={modals.banner}
            onClose={() => setModals({ ...modals, banner: false })}
            afterImageUpload={(file) => update("banner", file)}
          />



          {game=== "CSGO" && type === "player" && 
            <div className="col-12 mb-4">
              <label htmlFor="time">in Game Name</label>
              <input
                type="text"
                id="newName"
                name="newName"
                placeholder="Enter your CSGO in-game name"
                className={`form-input`}
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                disabled={true}
              />
            </div>
          }

            <div className="col-12 mb-4">
              <label htmlFor="description">About {type} (max 200 words)</label>
              <textarea
                // ref={register({ maxLength: { value: 2000, message: "Description too long" } })}
                type="text"
                value={about}
                max={2000}
                rows={4}
                id="description"
                name="description"
                autoComplete="description"
                placeholder="Write something about yourself"
                className={`form-input`}
                onChange={(e) => setAbout(e.target.value)}
              />
            </div>
        </div>

        <button onClick={onSubmit} className="mt-4 button primary thicc" >
          Save
        </button>
      
      </div>
       
    </Modal>
  );
};

export default RenameInGameNameModal;
