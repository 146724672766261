import React, { useState, useEffect, useContext } from "react";
// import leagues_banner from "@img/leagues-banner.jpg";
import { LeagueItem, Item, Advert } from "@common";
import Axios from "axios";
import { AppContext } from "../../../context/AppContext";
import Loading from "../../_common/Loading/Loading";

const LeaguesTab = ({ all = false, groupName = "", groupId }) => {
  const { selectedGame } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (all) {
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/seasons`, { params: { game: selectedGame._id, withLeagues: true } }).then(
        ({ data }) => {
          setSeasons(data);
          setLoading(false);
        }
      );
    } else {
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/gameGroups/${groupId}`, { params: { withLeagues: true } }).then(({ data }) => {
        setSeasons(data.seasons);
        setLoading(false);
      });
    }
  }, [all, groupId, selectedGame._id]);

  return (
    <div className="row smaller-gutter-row">
      {loading ? (
        <div className="col-12 text-center p-5">
          <Loading />
        </div>
      ) : (
        // Array(12)
        //     .fill()
        //     .map((_, i) => <Item key={i} loading column="3" />)
        seasons.map(
          ({ name, year, leagues = [] }) =>
            !!leagues.length && (
              <>
                <div className="col-12 pb-4">
                  <span className="text-22 uppercase bold">
                    {name}&nbsp;
                    <span className="color-primary outline">{year}</span>
                  </span>
                </div>
                {leagues.map(
                  ({ _id, name, leagueDivision, leagueDivisionName, thumbnailImage, country = {}, teams, leagueFinished = false }) => (
                    <LeagueItem
                      key={_id}
                      title={name}
                      second_line={
                        <React.Fragment>
                          <img src={`https://flagcdn.com/w20/${country.code ? country.code?.toLowerCase() : "dk"}.png`} alt="Danish flag" />
                          {country.name ?? "Danish"}
                        </React.Fragment>
                      }
                      third_line={`${teams.length} teams participating`}
                      tag={leagueDivisionName}
                      column="3"
                      image={thumbnailImage}
                      link_to={`/league/${_id}`}
                      leagueFinished={leagueFinished}
                    />
                  )
                )}
              </>
            )
        )
      )}

      <div className="container-fluid" style={{ paddingTop: "36px", paddingBottom: "36px", overflow: "hidden" }}>
        <Advert width={1660} height={168} refName="HOMEPAGE_1660_168_WIDE" style={{ margin: "0 auto" }} />
      </div>
    </div>
  );
};

export default LeaguesTab;
