import React, { useEffect, useState } from "react";
import { Link, Switch, Route, useLocation } from "react-router-dom";
import { string_to_slug } from "@utils";
import { RiCloseLine, RiPencilLine, RiAddLine, RiMenuLine } from "react-icons/ri";
import EditTabNameModal from "../../_modals/EditTabNameModal/EditTabNameModal";
import AddNewTabModal from "../../_modals/AddNewTabModal/AddNewTabModal";
import RepositionTabModal from "../../_modals/RepositionTabModal/RepositionTabModal";
import Axios from "axios";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { toast } from "react-toastify";

const Filter = () => {
  return (
    <React.Fragment>
      <span>FITLER BY:</span>
      <select className="form-input">
        <option>Recent news</option>
      </select>
    </React.Fragment>
  );
};

const SortableItem = SortableElement(({ i, name, hide, setHide }) => (
  <div className={`reorder-item ${hide ? "hidden" : ""}`}>
    {name}
    <span className="input">
      <RiMenuLine />
    </span>
  </div>
));

const SortableList = SortableContainer(({ items, setHide }) => {
  return (
    <div className="reorder-list">
      {items.map((item, i) => !item.hide && <SortableItem key={`item-${item.name}`} index={i} i={i} {...item} setHide={setHide} />)}
    </div>
  );
});

const TabsWithRoutes = (props) => {
  const { pages, addNewPage, showPreview, profileConfig, id, isPageBuilderEnabled, ads, isTeams } = props;
  let pageTitlesdd = Object.keys(pages);
  const [pageTitles, setPageTitles] = useState(pageTitlesdd);
  let defaultPage = pageTitles[0];
  let activePage = defaultPage;
  const [renameTab, setRenameTab] = useState("");
  const [addNewTabModalState, setAddNewTabModalState] = useState(false);
  const [repositionTabModalState, setRepositionTabModalState] = useState(false);
  const { pathname } = useLocation();
  const [tabs, setTabs] = useState(profileConfig && profileConfig.tabs);
  const [logoInBanner, setLogoInBanner] = useState(profileConfig && profileConfig.logoInBanner);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTabs((items) => arrayMove(items, oldIndex, newIndex));
  };

  const onSetHide = (val, index) => {
    tabs[index].hide = val;
    setTabs([...tabs]);
  };

  const onSubmit = async (e) => {
    // e.preventDefault();

    try {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/organisations/${id}/profileConfig`, { tabs, logoInBanner });
      var pageTitlesNew = [];
      tabs.map((tab) => !tab.hide && pageTitlesNew.push(tab.name));
      setPageTitles(pageTitlesNew);
      toast.success("Saved");
    } catch (e) {
      toast.error("There was a problem saving");
    }
  };

  if (typeof props.default !== "undefined" && pageTitles.include(props.default)) {
    defaultPage = props.default;
  }

  const { base_route } = props;
  let pageItems = [];

  pageTitles.forEach((title) => {
    pageItems.push(title);

    if (pathname.includes(string_to_slug(title))) {
      activePage = title;
    }
  });

  return (
    <div className="tabs-container-1">
      <EditTabNameModal
        isModalOpen={renameTab.length > 0}
        renameTab={renameTab}
        editTabName={(newName, oldName) => pages[renameTab]["props"].editTabName(newName, oldName)}
        closeModal={() => setRenameTab("")}
      />

      <AddNewTabModal
        isModalOpen={addNewTabModalState}
        addNewPage={(newName) => addNewPage(newName)}
        closeModal={() => setAddNewTabModalState(false)}
      />

      <RepositionTabModal
        isModalOpen={repositionTabModalState}
        onSubmit={() => onSubmit()}
        closeModal={() => setRepositionTabModalState(false)}
      >
        <SortableList items={tabs} setHide={onSetHide} onSortEnd={onSortEnd} />
      </RepositionTabModal>

      <div className="teams-tab-container">
        <div className={`tabs-nav ${isTeams && "teams-tab"}`}>
          <nav>
            {pageTitles.map((title) =>
              pages[title] && pages[title]["props"].count !== undefined ? (
                pages[title] &&
                pages[title]["props"].count > 0 && (
                  <Link key={title} to={`${base_route}/${string_to_slug(title)}`}>
                    <button className={activePage === title ? "active" : ""} key={title}>
                      <div>
                        {title} {pages[title] && pages[title]["props"].count ? `(${pages[title]["props"].count})` : ""}
                        {pages[title] && !pages[title]["props"].showPreview && pages[title]["props"].canBeModify === true && (
                          <RiPencilLine onClick={() => setRenameTab(title)} />
                        )}
                        {pages[title] && !pages[title]["props"].showPreview && pages[title]["props"].canBeRemove === true && (
                          <RiCloseLine onClick={() => pages[title]["props"].removeTab(title)} />
                        )}
                      </div>
                    </button>
                  </Link>
                )
              ) : (
                <Link key={title} to={`${base_route}/${string_to_slug(title)}`}>
                  <button className={activePage === title ? "active" : ""} key={title}>
                    {title} {pages[title] && pages[title]["props"].count ? `(${pages[title]["props"].count})` : ""}
                    {pages[title] && !pages[title]["props"].showPreview && pages[title]["props"].canBeModify === true && (
                      <RiPencilLine onClick={() => setRenameTab(title)} />
                    )}
                    {pages[title] && !pages[title]["props"].showPreview && pages[title]["props"].canBeRemove === true && (
                      <RiCloseLine onClick={() => pages[title]["props"].removeTab(title)} />
                    )}
                  </button>
                </Link>
              )
            )}
            {!showPreview && isPageBuilderEnabled && (
              <>
                <button onClick={() => setRepositionTabModalState(true)} className="addNewPageBtn" key={"reposition"}>
                  <RiMenuLine /> Reposition Tabs
                </button>
                <button onClick={() => setAddNewTabModalState(true)} className="addNewPageBtn" key={"addNew"}>
                  <RiAddLine /> Add New
                </button>
              </>
            )}
          </nav>
          <div className="filters">{props.show_filter ? props.custom_filter ?? <Filter /> : null}</div>
          {/* Commenting reschedule feature to add in next deployment.*/}
          {/* {pathname === base_route ? <>{props.reschedule_buttons}</> : null} */}
        </div>
      </div>

      <div className="row m-0 tab-content">
        <div className={ads ? "col-9 noPadding" : "col-12 p-0"}>
          <Switch>
            {pageTitles.map((title) => (
              <Route key={title} path={`${base_route}/${string_to_slug(title)}`}>
                {pages[title]}
              </Route>
            ))}

            {/* Redirect to first tab, if it's not supplied in url */}
            <Route path={base_route}>{pages[defaultPage]}</Route>
          </Switch>
        </div>
        {ads ? ads : null}
      </div>
    </div>
  );
};

const Tabs = (props) => {
  const { pages, ads, nextPage, setNextPage, frontpage, isTeams, isOverview } = props;
  const pageTitles = Object.keys(pages);
  let defaultPage = typeof props.default !== "undefined" && pageTitles.include(props.default) ? props.default : pageTitles[nextPage];

  // defaultPage = pageTitles[nextPage];
  // if (typeof props.default !== "undefined" && pageTitles.include(props.default)) {
  //   defaultPage = props.default;
  // }

  const [currentPage, setCurrentPage] = useState(defaultPage);
  useEffect(() => {
    setCurrentPage(defaultPage);
  }, [defaultPage]);

  useEffect(() => {
    setCurrentPage(defaultPage);
  }, [nextPage]);

  if (typeof props.base_route !== "undefined" && props.base_route !== null) {
    return <TabsWithRoutes {...props} />;
  }

  return (
    <div className="tabs-container-1">
      <div className="teams-tab-container" style={isOverview ? { paddingBottom: "0px" } : {}}>
        <div className={`tabs-nav ${isTeams && "teams-tab"} ${props.className}`} style={props.style}>
          <nav>
            {pageTitles.map((title) => (
              <button
                className={currentPage === title ? "active" : ""}
                onClick={() => {
                  setCurrentPage(title);
                  setNextPage && setNextPage(pageTitles.indexOf(title));
                }}
                key={title}
              >
                <div>{title}</div>
              </button>
            ))}
          </nav>

          <div className={`filters ${frontpage && "frontpage"}`}>{props.show_filter ? props.custom_filter ?? <Filter /> : null}</div>
        </div>
      </div>
      <div className="row m-0">
        <div className={ads ? "col-9" : "col-12 p-0"}>
          <div className={props.noPadding ? {} : "tab-content"}>{pages[currentPage]}</div>
        </div>
        {ads && <div className="col-3">{ads}</div>}
      </div>
    </div>
  );
};

export default Tabs;
