import React, { useContext, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import Dropdown from "./Dropdown/Dropdown";
import default_profile_picture from "@img/rallerpenos-image.png";
import logo_header from "@img/logo-header.svg";
import { DynamicSearch, Icon } from "@common";
import NotificationsDropdown from "./NotificationsDropdown/NotificationsDropdown";
import GameSwitcher from "./GameSwitcher/GameSwitcher";
import { useState } from "react";
import Axios from "axios";
import GlitchText from "../../_common/GlitchText/GlitchText";

const toggleSidebar = (sidebarOpen, setSidebarOpen) => {
  setSidebarOpen(!sidebarOpen);
  localStorage.setItem("sidebarOpen", !sidebarOpen);
};

const HeaderButtons = () => {
  const history = useHistory();
  const { authenticated, setAuthenticated, user, setShowAuth } = useContext(AppContext);

  const logout = () => {
    localStorage.removeItem("sessionToken");
    setAuthenticated(false);
    history.push("/");
  };

  if (authenticated && user.state) {
    return (
      <React.Fragment>
        <Link to={`/players/${user.state._id}`} className="header-profile-link">
          <img src={user.state.avatarImage || default_profile_picture} style={{ borderRadius: "15px" }} alt="" />
          <span className="bold">{user.state.nickname || user.state.firstName}</span>
        </Link>

        <NotificationsDropdown />

        <Dropdown
          label={<Icon name="cog" />}
          render={() => (
            <>
              {user.state.admin && (
                <>
                  <a href="https://admin.leagues.gg" target="_blank" rel="noopener noreferrer" className="header-settings-link">
                    <Icon name="cog" /> Leagues Admin
                  </a>
                  <a href="https://pr.leagues.gg/ghost/#/posts" target="_blank" rel="noopener noreferrer" className="header-settings-link">
                    <Icon name="cog" /> Ghost Dashboard
                  </a>
                </>
              )}
              <Link to="/account" className="header-settings-link">
                <Icon name="cog" /> Settings
              </Link>
              <div onClick={logout} className="header-settings-link">
                <Icon name="logout" /> Logout
              </div>
            </>
          )}
        />

        {/* <UpcomingMatch /> */}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <button className="button bordered medium ml-3 mr-3" onClick={() => setShowAuth("login")}>
        Log in
      </button>
      <button className="button primary medium" onClick={() => setShowAuth("register")}>
        Sign up
      </button>
    </React.Fragment>
  );
};

const Header = ({ sidebarOpen, setSidebarOpen }) => {
  const history = useHistory();
  const timeout = useRef();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const { selectedGame, setSelectedGame } = useContext(AppContext);

  const onSearch = async (query) => {
    clearTimeout(timeout.current);
    setResults([]);
    if (!query.length) return setLoading(false);
    setLoading(true);
    let selectedGameId = null;
    if (selectedGame.shortName !== "No Game") {
      selectedGameId = selectedGame._id;
    }

    timeout.current = setTimeout(async () => {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/globalSearch`, {
        query,
        include: "users, players, organisations, teams, coaches",
        selectedGameId,
      });

      // Map data to required output, and remove already selected players
      const searchResults = data.map(({ _id, img, name, user, type, logoCropped }) => ({
        _id,
        type,
        img,
        logoCropped,
        title: name,
        subtitle: user ? `${user.firstName} ${user.lastName}` : false,
      }));

      setResults(searchResults);
      setLoading(false);
    }, 250);
  };

  const onClickSearchResult = (result) => {
    const { _id, type } = result;

    switch (type) {
      case "User":
        history.push(`/players/${_id}`);
        break;
      case "Player":
        history.push(`/game-profile/${_id}`);
        break;
      case "Organisation":
        history.push(`/organisation/${_id}`);
        break;
      case "Team":
        history.push(`/teams/${_id}`);
        break;
      case "Coach":
        history.push(`/coaches/${_id}`);
        break;
      default:
    }
  };

  return (
    <React.Fragment>
      <header className="global-header">
        <div className="header-logo">
          <button className="menu-icon" onClick={() => toggleSidebar(sidebarOpen, setSidebarOpen)}>
            {sidebarOpen ? <Icon name="menu-close" /> : <Icon name="menu-open" />}
          </button>
          <div style={{ cursor: "pointer" }}>
            <Link to="/" className="logo-text">
              <GlitchText animateOnHover>
                <div className="d-flex justify-content-center align-items-center">
                  <img src={logo_header} alt="Leagues logo" />
                  <strong className="uppercase logo__text">Leagues.gg</strong>
                </div>
              </GlitchText>
            </Link>
          </div>
          <span className="header-game-switcher-bleft">&nbsp;</span>
          <GameSwitcher />
        </div>

        <div className="header-search">
          <DynamicSearch
            placeholder="Search for users, players, teams and more..."
            onSearch={onSearch}
            onSelectResult={onClickSearchResult}
            loadingResults={loading}
            results={results}
            mixedResults={true}
            newTab
          />
        </div>

        <div className="header-actions">
          <HeaderButtons />
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
