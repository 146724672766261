import React, { useState, useEffect, useContext } from "react";
import { useParams, Link, useHistory, Redirect } from "react-router-dom";
import { SimplePageWithTabs } from "@common";
import { AppContext } from "@context/AppContext";
import EditOrganisation from "./EditOrganisation/EditOrganisation";
import Owners from "./Owners/Owners";
import Axios from "axios";
import ProfileSettings from "./ProfileSettings/ProfileSettings";
import Socials from "./Socials/socials";

const OrganisationsManage = () => {
  const { id } = useParams();
  const history = useHistory();
  const { user } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [organisation, setOrganisation] = useState({});

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/organisations/${id}`)
      .then(({ data }) => {
        setOrganisation(data);
        setLoading(false);
      })
      .catch(() => {
        history.push("/error");
      });
  }, [id, history]);

  const setOwners = (owners) => {
    setOrganisation({ ...organisation, owners });
  };

  const setProfileConfig = (profileConfig) => {
    setOrganisation({ ...organisation, profileConfig });
  };

  const isOwner = !loading && (organisation.owners.find(({ user: id }) => id && user.state._id === id._id) || user.state.admin);

  let tabs = {
    Organisation: <EditOrganisation organisation={organisation} setOrganisation={setOrganisation} />,
    Socials: <Socials organisation={organisation} setOrganisation={setOrganisation} />,
    "Profile Settings": <ProfileSettings profileConfig={organisation.profileConfig} setProfileConfig={setProfileConfig} />,
    "Owners & Managers": <Owners owners={organisation.owners} setOwners={setOwners} />,
  };

  return !loading && !isOwner ? (
    <Redirect to="/" />
  ) : (
    !loading && (
      <SimplePageWithTabs
        title="Manage Organisation"
        tabs={tabs}
        top_right={
          <Link to={`/organisation/${id}`} className="primary-link">
            Back to organisation
          </Link>
        }
      />
    )
  );
};

export default OrganisationsManage;
