import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { AppContext } from "@context/AppContext";
import Axios from "axios";
import logo_header from "@img/logo-header.svg";
import Loading from "@common/Loading/Loading";
// import Loading from "../../_common/Loading/Loading";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ConnectVL = ({ afterConnect }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { riotId } = useParams();
  const { user } = useContext(AppContext);
  const [inGameName, setInGameName] = useState(null);
  // const [game, setGame] = useState(null);
  const [primaryRole, setPrimaryRole] = useState(null);
  const [secondaryRole, setSecondaryRole] = useState(null);
  const [roles, setRoles] = useState([]);
  const onCreate = (data, type) => {
    user.dispatch({ type: "update", payload: { [type]: [...user.state[type], data] } });
  };

  let query = useQuery();
  let riotUsername = query.get("riotUsername");
  let playerId = query.get("id");
  let tagLine = query.get("tagLine");
  useEffect(() => {
    let _error = query.get("error");
    if (_error) {
      history.push("/");
      toast.error(_error);
    }
  }, []);
  const getGames = () => {
    Axios.get(`${process.env.REACT_APP_VL_API}/api/game`).then(({ data }) => {
      
      console.log('games data', data)
      setRoles(data[0]?.configuration?.playerTypes?.filter(({ name }) => !name.includes("SUB")) || []);
      // setGame(data);
    })
    .catch((err)=>{
      console.log('err', err)
    })
  };

  const onSubmit = async (e) => {
    try {
      setLoading(true)
      // console.log({ riotUsername, riotId, inGameName, primaryRole, secondaryRole })
      Axios.post(
        `${process.env.REACT_APP_VL_API}/api/player`,
        
        { riotUsername, riotId, inGameName, primaryRole, secondaryRole ,playerId,tagLine}
      ).then(({data})=>{
        toast.success("Successfully created Valorant player!");
        setLoading(false);
        
        console.log(data.player)
        onCreate(data.player, "players");
  
        history.push(`/game-profile/${data.player._id}`);
      }).catch((err)=>{
        console.log("eeee=", err);
        const msg = err?.response && err?.response.data ? err?.response.data.msg : "There was a problem creating player";
        toast.error(msg);
        setLoading(false);
        if (msg !== "In Game Name already registered") {
          history.push(`/`);
        }
      })
     
    } catch (e) {
      console.log("eeee=", e);
      const msg = e?.response && e?.response?.data ? e?.response?.data?.msg : "There was a problem creating player";
      toast.error(msg);
      setLoading(false);
      if (msg !== "In Game Name already registered") {
        history.push(`/`);
      }
    }
  };

  useEffect(() => {
    getGames();
    setInGameName(riotUsername);
  }, [riotUsername, riotId]);

  return (
    <div className="global-pnf">
      <div className="container-fluid">
        <div className="area-logo">
          <Link to="#" className="logo-text">
            <img src={logo_header} alt="Leagues logo" />
          </Link>
        </div>

        <div>
          <div className="form-row">
            <label>Set your in game name and press next.</label>
            <input className="form-input" value={inGameName} onChange={(e) => setInGameName(e.target.value)} disabled={true} />
          </div>
          <div className="form-row">
            <label htmlFor="primaryRole">Preferred Primary Role</label>
            <select
              id="primaryRole"
              className="form-input"
              value={primaryRole || "_DEFAULT"}
              onChange={(e) => setPrimaryRole(e.target.value)}
            >
              <option value="_DEFAULT" disabled>
                Choose preferred primary role
              </option>
              {roles.map(({ name }) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-row">
            <label htmlFor="secondaryRole">Preferred Secondary Role</label>
            <select
              id="secondaryRole"
              className="form-input"
              value={secondaryRole || "_DEFAULT"}
              onChange={(e) => setSecondaryRole(e.target.value)}
            >
              <option value="_DEFAULT" disabled>
                Choose preferred secondary role
              </option>
              {roles.map(({ name }) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>

          <button
            onClick={() => onSubmit()}
            className="button primary thicc mt-4"
            disabled={loading || secondaryRole === null || primaryRole === null || inGameName === null || inGameName === ""}
          >
            {loading ? (
              <>
                <Loading width={30} height={30} /> Please wait...
              </>
            ) : (
              "Next"
            )}
          </button>
        </div>

        <div className="area-logo">
          <strong className="uppercase">Do not refresh or close this window.</strong>
        </div>
      </div>
    </div>
  );
};

export default ConnectVL;
