import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { RiCloseLine } from "react-icons/ri";
import Modal from "react-modal";
import Axios from "axios";
import { toast } from "react-toastify";
import "./_edit-create-category-modal.scss";

const EditCreateCategoryModal = ({
  category = null,
  teams = [],
  open,
  onClose = () => {},
  afterCreateOrUpdate = () => {},
  organisation = "",
}) => {
  const [selectedTeams, setSelectedTeams] = useState(category?.teams.filter((team) => teams.find((_team) => _team._id === team)) || []);
  const [categoryName, setCategoryName] = useState(category?.name || null);

  const resetModalData = () => {
    setSelectedTeams([]);
    setCategoryName(null);
  };

  const handleSubmit = async () => {
    if (!categoryName?.length) {
      return toast.error("Category name cannot be empty");
    }
    if (category) {
      try {
        const { data } = await Axios.put(
          `${process.env.REACT_APP_CORE_API}/api/organisations/${organisation}/update-category/${category._id}`,
          { name: categoryName, teams: selectedTeams }
        );
        afterCreateOrUpdate(data);
        toast.success("Category Successfully updated");
        resetModalData();
      } catch (error) {
        toast.error(error.res?.data?.msg || "Failed to updated category");
      }
    } else {
      try {
        const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/organisations/${organisation}/create-category`, {
          name: categoryName,
          teams: selectedTeams,
        });
        afterCreateOrUpdate(data);
        toast.success("Category successfully created");
        resetModalData();
      } catch (error) {
        toast.error(error.res?.data?.msg || "Failed to updated category");
      }
    }
  };
  return (
    <Modal
      style={{ content: { width: 520 } }}
      isOpen={open}
      onRequestClose={onClose}
      className="modal editCategory"
      shouldCloseOnEsc={false}
    >
      <div
        className="modal-close"
        onClick={() => {
          resetModalData();
          onClose();
        }}
      >
        <RiCloseLine />
      </div>
      <div className="modal-header">
        {category ? <h2>Edit {category.name}</h2> : <h2 className="uppercase mb-4">Create Team Category</h2>}
        <Form.Label style={{ marginLeft: "-39px" }}>Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          placeholder="Example: Academy Teams"
          className={"form-input"}
          maxLength={40}
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          required={true}
        />
      </div>
      <div className="modal-body">
        <div>
          <p className="uppercase bold mt-1">
            choose teams{" "}
            <span className="badge badge-secondary uppercase">
              {selectedTeams.length} {selectedTeams.length === 1 ? "Team" : "Teams"}
            </span>
          </p>
          <p className="desc">Choose the teams you wish to display in the team category</p>
        </div>
        <div className="teams__container">
          {teams.map((team) => (
            <div
              className={`row team__item ${selectedTeams.find((_team) => _team === team._id) ? "selected" : ""}`}
              onClick={() =>
                setSelectedTeams((prevState) =>
                  prevState.find((_team) => _team === team._id) ? prevState.filter((_team) => _team !== team._id) : [...prevState, team._id]
                )
              }
            >
              <div className="col-4">
                <img src={team.banner} />
              </div>
              <div className="col-8 team-content">
                <h3>{team.name}</h3>
                <div className="team__item_details">
                  <div className="d-flex team__item_details-game">
                    <img src={team.game.whiteIcon} />
                    <span className="team__item_details-game-name">
                      {team.game.name} - {team.members.length + team.organisation.owners.length}{" "}
                      {team.members.length + team.organisation.owners.length === 1 ? "Member" : "Members"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-4 modal-footer">
          <p className="foot-note text-center">
            Please note: You can only display your team in one category. If you pick a team that is already in another team category, it
            will get moved to this category.
          </p>
          <button className="mt-4 button primary thicc" onClick={handleSubmit}>
            {category?.name ? "Edit Team Category" : "Create Team Category"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditCreateCategoryModal;
