import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import Axios from "axios";
import moment from "moment";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { AuthModalContext } from "../../AuthModal";
import Loading from "../../../../_common/Loading/Loading";
import { countries } from "@utils";

const Register = () => {
  const [loading, setLoading] = useState(false);
  const { registrationData, setRegistrationData, setStep } = useContext(AuthModalContext);
  const { register, handleSubmit, watch, errors, setError } = useForm();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [nationalities, setNationalities] = useState([]);

  useEffect(() => {
    // Get list of nationalities from external API (europe only)
    //https://restcountries.eu/rest/v2/all
    //Axios.get("https://restcountries.eu/rest/v2/region/europe?fields=demonym;alpha2Code", { headers: false }).then(({ data }) => {
    // Axios.get("https://restcountries.eu/rest/v2/all?fields=demonym;alpha2Code", { headers: false }).then(({ data }) => {
    //   setNationalities(
    //     data
    //       .filter(({ demonym }) => demonym)
    //       .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
    //       .sort((a, b) => a.demonym.localeCompare(b.demonym))
    //   );
    // });
    setNationalities(
      countries
        .filter(({ demonym }) => demonym)
        .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
        .sort((a, b) => a.demonym.localeCompare(b.demonym))
    );
  }, []);

  const togglePswdVisibility = () => setPasswordVisible(!passwordVisible);

  const onSubmit = async (data) => {
    setLoading(true);

    // Check if email exists via API
    const { data: emailExists } = await Axios.get(`${process.env.REACT_APP_CORE_API}/api/users/emailExists/${data.email}`);
    if (emailExists) {
      setError("email", "emailExists", "Email already exists");
      return setLoading(false);
    }

    // Check if user is 10
    const birthday = moment(`${data.birthdayDay} ${data.birthdayMonth} ${data.birthdayYear}`);

    if (moment().diff(birthday, "years") < 10) {
      setError("birthday", "tooYoung", "You need to be at least 10 to sign up for Leagues");
      return setLoading(false);
    }

    setRegistrationData({ ...data, birthday: birthday.toISOString() });
    setLoading(false);
    return setStep(1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row form-row sm-gutters">
        <div className="col-6">
          <label htmlFor="firstName">First Name</label>
          <input onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
            ref={register({ required: "Required", maxLength: { value: 200, message: "Name too long" } })}
            type="text"
            id="firstName"
            name="firstName"
            placeholder="John"
            autoComplete="first-name"
            className={`form-input ${errors.firstName ? "invalid" : ""}`}
            defaultValue={registrationData.firstName}
          />
          {errors.firstName && <div className="form-input-error">{errors.firstName.message}</div>}
        </div>

        <div className="col-6">
          <label htmlFor="lastName">Last Name</label>
          <input onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
            ref={register({ required: "Required", maxLength: { value: 200, message: "Name too long" } })}
            type="text"
            placeholder="Doe"
            id="lastName"
            name="lastName"
            autoComplete="last-name"
            className={`form-input ${errors.lastName ? "invalid" : ""}`}
            defaultValue={registrationData.lastName}
          />
          {errors.lastName && <div className="form-input-error">{errors.lastName.message}</div>}
        </div>
      </div>

      <div className="form-row">
        <label htmlFor="password">Password</label>
        <input
          ref={register({
            required: "Required",
            minLength: { value: 6, message: "Password must be at least 6 characters" },
            maxLength: { value: 30, message: "Password can't be more than 30 characters" },
          })}
          type={passwordVisible ? "text" : "password"}
          id="password"
          name="password"
          placeholder="Password"
          maxLength={30}
          autoComplete="new-password"
          className={`form-input ${errors.password ? "invalid" : ""}`}
          defaultValue={registrationData.password}
        />
        <div onClick={togglePswdVisibility} className="toggle-pswd-visibility">
          {passwordVisible ? <RiEyeOffLine size="1.2rem" color="#666" /> : <RiEyeLine size="1.2rem" color="#666" />}
        </div>
        {errors.password && <div className="form-input-error">{errors.password.message}</div>}
      </div>

      <div className="form-row">
        <label htmlFor="confirmPassword">Confirm Password</label>
        <input
          ref={register({ required: "Required", validate: (value) => value === watch("password") || "Passwords must match" })}
          type={passwordVisible ? "text" : "password"}
          id="confirmPassword"
          name="confirmPassword"
          placeholder="Confirm password"
          maxLength={30}
          autoComplete="confirm-new-password"
          className={`form-input ${errors.confirmPassword ? "invalid" : ""}`}
          defaultValue={registrationData.confirmPassword}
        />
        <div onClick={togglePswdVisibility} className="toggle-pswd-visibility">
          {passwordVisible ? <RiEyeOffLine size="1.2rem" color="#666" /> : <RiEyeLine size="1.2rem" color="#666" />}
        </div>
        {errors.confirmPassword && <div className="form-input-error">{errors.confirmPassword.message}</div>}
      </div>

      <div className="form-row">
        <label htmlFor="birthdayDay">Birthday</label>
        <div className="row form-row sm-gutters">
          <div className="col-3">
            <input
              type="number"
              ref={register({ required: "Required", min: 1, max: 31 })}
              id="birthdayDay"
              name="birthdayDay"
              className={`form-input ${errors.birthdayDay ? "invalid" : ""}`}
              defaultValue={registrationData.birthdayDay || ""}
              placeholder="Day"
            />
            {errors.birthdayDay && <div className="form-input-error">{errors.birthdayDay.message}</div>}
          </div>

          <div className="col-6">
            <select
              ref={register({ required: "Required" })}
              id="birthdayMonth"
              name="birthdayMonth"
              className={`form-input ${errors.birthdayMonth ? "invalid" : ""}`}
              defaultValue={registrationData.birthdayMonth || ""}
            >
              <option value="" disabled>
                Month
              </option>
              {[
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ].map((month) => (
                <option value={month}>{month}</option>
              ))}
            </select>
            {errors.birthdayMonth && <div className="form-input-error">{errors.birthdayMonth.message}</div>}
          </div>
          <div className="col-3">
            <input
              type="number"
              ref={register({ required: "Required", min: 1900, max: moment().format("Y") })}
              id="birthdayYear"
              name="birthdayYear"
              className={`form-input ${errors.birthdayYear ? "invalid" : ""}`}
              defaultValue={registrationData.birthdayYear || ""}
              placeholder="Year"
            />
            {errors.birthdayYear && <div className="form-input-error">{errors.birthdayYear.message}</div>}
          </div>
        </div>
      </div>

      <div className="row form-row sm-gutters">
        <div className="col-6">
          <label htmlFor="gender">Gender</label>
          <select
            ref={register({ required: "Required" })}
            id="gender"
            name="gender"
            className={`form-input ${errors.gender ? "invalid" : ""}`}
            defaultValue={registrationData.gender || ""}
          >
            <option value="" disabled>
              Choose gender
            </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
            <option value="Prefer not to say">Prefer not to say</option>
          </select>
          {errors.gender && <div className="form-input-error">{errors.gender.message}</div>}
        </div>

        <div className="col-6">
          <label htmlFor="nationality">Nationality</label>
          <select
            ref={register({ required: "Required" })}
            id="nationality"
            name="nationality"
            className={`form-input ${errors.nationality ? "invalid" : ""}`}
            defaultValue={registrationData.nationality || ""}
          >
            <option value="" disabled>
              Choose nationality
            </option>
            {nationalities.map(({ alpha2Code, demonym }) => (
              <option key={alpha2Code} value={JSON.stringify({ code: alpha2Code, name: demonym })}>
                {demonym}
              </option>
            ))}
          </select>
          {errors.nationality && <div className="form-input-error">{errors.nationality.message}</div>}
        </div>
      </div>

      <div className="form-row">
        <label htmlFor="email">Email</label>
        <input
          ref={register({ required: "Required" })}
          type="email"
          id="email"
          placeholder="Name@mail.com"
          name="email"
          autoComplete="email"
          className={`form-input ${errors.email ? "invalid" : ""}`}
          defaultValue={registrationData.email}
        />
        {errors.email && <div className="form-input-error">{errors.email.message}</div>}
      </div>

      <button type="submit" className="button primary thicc" disabled={loading}>
        {loading ? (
          <>
            <Loading width={30} height={30} /> Verifying
          </>
        ) : (
          "Continue"
        )}
      </button>
    </form>
  );
};

export default Register;
