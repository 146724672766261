import React, { useEffect, useState } from "react";
import { SimpleTabs, SortableList, SortableListItem, Loading } from "@common";
import matches_big_image from "@img/new-match.png";
import default_team_member from "@img/default-team-member.png";
import default_team_position from "@img/default-team-position.png";
import { cosh } from "core-js/fn/number";
import TopPlayers from "./TopPlayers";

const Panel = ({ playerStats, team = null, page }) => {
  return team === null ? (
    "[DELETED]"
  ) : (
    <div className="panel secondary">
      <header>
        <div>
          <span className="text-16">{team?.name}</span>
        </div>
        <a href={`/teams/${team._id}`} className="text-12 primary-link">
          Go to Team
        </a>
      </header>

      <TopPlayers
        playerStats={playerStats}
        teamId={team._id}
        page={page}
        players={team.members.filter(({ role }) => role.includes("Player:"))}
      />
      {/* {[...Array(8).keys()].map((i) => (
        <div className="secondary-items" key={i}>
          <div className="row smaller-gutter-row">
            <div className="col-5">
              <img className="team-member-image" src={default_team_member} alt="" />
              <span className="text-12 bold">g1ngeren</span>
            </div>
            <div className="col-5">
              <img className="team-position-image" src={default_team_position} alt="" />

              <span className="text-12 bold uppsercase">Top</span>
            </div>
            <div className="col-2 right">
              <span className="text-12 bold">8.6</span>
            </div>
          </div>
        </div>
      ))} */}
    </div>
  );
};

const CompareTeams = ({ players, teams, playerStats = [], tournamentCodesButton }) => {
  // console.log('playerStats-in com p=',playerStats)
  const [page, setPage] = useState(0);
  const pages = ["KDA", "CS pr min", "Kills", "Assists", "Vision score", "Killparticipation"];
  const [teamPlayers, setTeamPlayers] = useState([]);

  useEffect(() => {
    var teamOf = [];
    Object.keys(players).map((team) => {
      let playersTeam = players[team];
      var flags = [],
        output = [],
        l = playersTeam.length,
        i;
      for (i = 0; i < l; i++) {
        // console.log(playersTeam[i].role);
        if (["TOP", "JUNGLE", "MID", "BOT", "SUPPORT"].includes(playersTeam[i].role)) {
          if (flags[playersTeam[i].role]) continue;
          var position = {};
          switch (playersTeam[i].role) {
            case "TOP":
              position = team === "t1" ? { top: "25%", left: "7%" } : { top: "13%", left: "22%" };

              break;
            case "JUNGLE":
              position = team === "t1" ? { top: "39%", left: "28%" } : { top: "55%", left: "59%" };

              break;
            case "MID":
              position = team === "t1" ? { top: "54%", left: "36%" } : { top: "39%", left: "52%" };

              break;
            case "BOT":
              position = team === "t1" ? { top: "79%", left: "57%" } : { top: "68%", left: "74%" };

              break;
            case "SUPPORT":
              position = team === "t1" ? { top: "79%", left: "47%" } : { top: "53%", left: "74%" };

              break;
          }
          playersTeam[i]["position"] = position;
          flags[playersTeam[i].role] = true;
          output.push(playersTeam[i]);
          teamOf[team] = output;
          setTeamPlayers(...teamPlayers, teamOf);
        }
      }
    });
  }, [players]);

  return (
    <div className="compare-teams">
      <div className="row d-flex justify-content-end">
        <div className="col-3">{tournamentCodesButton}</div>
      </div>
      <div className="row smaller-gutter-row under-contruction">
        <div className="col-12" style={{ position: "relative" }}>
          <img src={matches_big_image} alt="Matches" style={{ width: "100%", height: "auto" }} />
        </div>

        {teamPlayers &&
          teamPlayers.t1 &&
          teamPlayers.t1.map((player) => {
            let top = player.position.top;
            let left = player.position.left;
            return (
              <div style={{ position: "absolute", top, left, textAlign: "center", lineHeight: "26px" }}>
                <img className="image-55 border-radius-100" src={player?.player?.avatarImage || default_team_member} alt="player" />
                <br />
                <span className="text-18">{player?.player?.name || "[DELETED]"}</span>{" "}
                <img
                  className="flag"
                  src={player?.player?.user?.nationality?.code ? `https://flagcdn.com/w20/${player.player?.user?.nationality?.code?.toLowerCase()}.png` : `https://flagcdn.com/w20/dk.png`}
                  alt={player?.player?.user?.nationality?.name}
                />
                <br />
                <span className="faded text-10 uppercase bold">{player.role}</span>
              </div>
            );
          })}

        {teamPlayers &&
          teamPlayers.t2 &&
          teamPlayers.t2.map((player) => {
            let top = player.position.top;
            let left = player.position.left;
            return (
              <div style={{ position: "absolute", top, left, textAlign: "center", lineHeight: "26px" }}>
                <img className="image-55 border-radius-100" src={player?.player?.avatarImage || default_team_member} alt="player" />
                <br />
                <span className="text-18">{player?.player?.name || "[DELETED]"}</span>{" "}
                <img
                  className="flag"
                  src={player?.player?.user?.nationality?.code ? `https://flagcdn.com/w20/${player.player?.user?.nationality?.code?.toLowerCase()}.png` : `https://flagcdn.com/w20/dk.png`}
                  alt={player?.player?.user?.nationality?.name}
                />
                <br />
                <span className="faded text-10 uppercase bold">{player.role}</span>
              </div>
            );
          })}
      </div>

      <div className="row smaller-gutter-row">
        <div className="col-12 pt-4">
          <SimpleTabs pages={pages} page={page} setPage={setPage} />
        </div>

        <div className="col-6">
          <Panel playerStats={playerStats} team={teams?.t1} page={page} />
          {/* <TopPlayers playerStats={playerStats} teamId={teams?.t1?._id} /> */}
        </div>

        <div className="col-6">
          <Panel playerStats={playerStats} team={teams?.t2} page={page} />
          {/* <TopPlayers playerStats={playerStats} teamId={teams?.t2?._id} /> */}
        </div>
      </div>
      {/* Compare players is coming soon! Here you will be able to compare all kinds of stats for both teams. */}
    </div>
  );
};

export default CompareTeams;
