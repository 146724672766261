import React, { useEffect, useState, useContext } from "react";
import Axios from "axios";
import ConnectLOL from "./LOL/ConnectLOL";
import ConnectCSGO from "./CSGO/ConnectCSGO";
import ConnectVL from "./VL/ConnectVL";
import NonIntegrationGame from "./non-integration/nonIntegration";
import { AppContext } from "@context/AppContext";

const ConnectGame = ({ onClose, afterCreate }) => {
  const { user, selectedGame } = useContext(AppContext);
  const [games, setGames] = useState([]);
  const [game, setGame] = useState("");

  const fetchGamesPlayerLimit = async (userId) => {
    if(!userId) {
      console.log('no user', userId)
      return
    }
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games/playerLimit/${userId}`)
      .then(({ data }) => {
        setGames(data)
        console.log('playerLimitData', data)
      })
      .catch(({ error }) => {
        console.log('error playerLimit', error)
      })
  }

  const fetchGames = async () => {
    await Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`)
      .then(({ data }) => {
        console.log('games fetched: ', data)
        setGames(data)
      })
  }

  useEffect(() => {
    //fetchGamesPlayerLimit(user.state._id)
    fetchGames()
  }, []);

  const afterConnect = (player) => {
    afterCreate(player);
    onClose();
  };

  const getGameData = () => {
    if (game) {
      return games.find(({ shortName }) => game === shortName);
    }

    return false;
  };

  return (
    <div>
      <div className="form-row">
        <label htmlFor="game">Add game account</label>
        <select 
          id="game" 
          className={`form-input ${game === "CSGO"?"csgo-sel":game === "VL"?"vl-sel":game === "LoL"?"lol-sel":''}`}
          value={game || "_DEFAULT"} 
          onChange={(e) => {
            console.log('e target', e.target.value)
            setGame(e.target.value)
          }}
          >
          <option value="_DEFAULT" disabled>
            Choose game
          </option>
          {games.map(({ _id, name, shortName, limitReached }) => (
            <option key={_id} value={shortName} disabled={limitReached}>
              {name} {limitReached && "(Account Limit Full)"}
            </option>
          ))}
        </select>
      </div>

      {games.map(({ _id, name, shortName }) => {
        if (game === shortName) {
          switch (shortName) {   
            case "CSGO":
              return <ConnectCSGO key={_id} gameData={getGameData()} afterConnect={afterConnect}  selectedGame={shortName}/>;
              break;
            case "LoL":
              return <ConnectLOL key={_id} gameData={getGameData()} afterConnect={afterConnect} selectedGame={shortName} />;
              break;
              case "VL":
                return <ConnectVL key={_id} gameData={getGameData()} afterConnect={afterConnect} selectedGame={shortName} />;
                break;
            default:
              return <NonIntegrationGame afterConnect={afterConnect} game={_id} user={user.state._id} />;
          }
        }
        // if (game === name)
        //   return <ConnectCSGO key={_id} gameData={getGameData()} afterConnect={afterConnect} />;
        // return false;
      })}
    </div>
  );
};

export default ConnectGame;
