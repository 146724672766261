import React,  { useState, useContext } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { AppContext } from "../../../../context/AppContext";

const MySocials = () => {
  const { user } = useContext(AppContext);
  const { register, handleSubmit, errors } = useForm();
  const [cerror, setCerror] = useState({});

  const onSubmit = async (data) => {
    try {
      const { data: newData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/auth`, data);
      user.dispatch({ type: "update", payload: newData.updatedUser });
      toast.success("Social links updated");
    } catch (e) {
      const msg = e.response.data ? e.response.data.msg : "Fatal error";
      toast.error(msg);
    }
  };

  // Social fields are dynamically rendered from this array! The value must match the regex in order to be valid
  const socialFields = [
    { name: "Discord", placeholder: "Name #0000", regex: /.*\s?#\s?([0-9]{4})$/, invalidMsg: "Discord name is not valid" },
    { name: "Twitch", placeholder: "Twitch.tv/channel", regex: /(?:(?:http|https):\/\/)?(?:www.)?(?:twitch.tv)\/([A-Za-z0-9-_]+)/ },
    { name: "Facebook", placeholder: "Facebook.com/name", regex: /(?:(?:http|https):\/\/)?(?:www.)?(?:facebook.com)\/([A-Za-z0-9-_]+)/ },
    { name: "Twitter", placeholder: "Twitter.com/handle", regex: /(?:(?:http|https):\/\/)?(?:www.)?(?:twitter.com)\/([A-Za-z0-9-_]+)/ },
    {
      name: "Instagram",
      placeholder: "Instagram.com/handle",
      regex: /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am)\/([A-Za-z0-9-_]+)/,
    },
    {
      name: "YouTube",
      placeholder: "Youtube.com/channel",
      regex: /(?:(?:http|https):\/\/)?(?:www.)?(?:youtube.com|youtu.be)\/([A-Za-z0-9-_]+)/,
    },
  ];

  const handleChange = (value,fieldName,regex) => {
    let validate = false
    if(value !== ""){
      if(value.match(regex)){
        validate = true;
        let cuserrors = cerror;
        delete cuserrors[fieldName]
        setCerror({...cuserrors})
      }else{
        validate = false;
        let cuserrors = cerror;
        setCerror({...cuserrors,[fieldName]:`Invalid ${fieldName} URL ${value}`})
      }
    }else{
      let cuserrors = cerror;
      delete cuserrors[fieldName]
      setCerror({...cuserrors})
    }

  }
  
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} id="acc-settings-form" className="account-settings-form">
        <div className="row">
          {/* Social links */}
          {socialFields.map(({ name, placeholder, regex, invalidMsg }) => (
            <div className="col-6">
              <div className="form-row">
                <label htmlFor={name.toLowerCase()}>{name}</label>
                {name === 'Discord' ? <><input
                  ref={register({
                    validate: (value) => (!value.match(regex) && value !== "" ? invalidMsg || `Invalid ${name} URL` : true),
                  })}
                  type="text"
                  id={name.toLowerCase()}
                  name={name.toLowerCase()}
                  autoComplete="off"
                  className={`form-input ${errors[name.toLowerCase()] ? "invalid" : ""}`}
                  defaultValue={user.state[name.toLowerCase()]}
                  placeholder={placeholder}
                  
                />
                {errors[name.toLowerCase()] && <div className="form-input-error">{errors[name.toLowerCase()].message}</div>}
                </> : <><input 
                type="text"
                id={name.toLowerCase()}
                name={name.toLowerCase()}
                // autoComplete="off"
                className={`form-input ${cerror[name.toLowerCase()] ? "invalid" : ""}`}
                defaultValue={user.state[name.toLowerCase()]}
                placeholder={placeholder}
                onBlur={(e) => handleChange(e.target.value,name.toLowerCase(), regex)}
              
              />
              {Object.keys(cerror).length > 0 && cerror[name.toLowerCase()] && <div className="form-input-error">{cerror[name.toLowerCase()]}</div>}
               </>}
              
                
              </div>
            </div>
          ))}
        </div>

        <div className="form-row">
          <button className="button primary" disabled={Object.keys(cerror).length > 0 ? true : false} type="submit">
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default MySocials;
