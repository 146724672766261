import React, { useState, useEffect, useContext } from "react";
import Modal from "react-modal";
import { RiCloseLine } from "react-icons/ri";
import { useForm } from "react-hook-form";
import Loading from "../../_common/Loading/Loading";
import { toast } from "react-toastify";
import Axios from "axios";
import moment from "moment";
import { AppContext } from "../../../context/AppContext";
import { TIMEZONES } from "../../../utils/timezones";

export const CreateScrimModal = (props) => {
  const { selectedGame, setSelectedGame, authenticated } = useContext(AppContext);
  const [games, setGames] = useState([]);
  const [teams, setTeams] = useState([]);
  const [game, setGame] = useState(selectedGame);

  const [loading, setLoading] = useState(false);
  const numberOfMatchesOptions = game.shortName === "LoL" ? [1, 2, 3, 4, 5] : [1, 3, 5];
  const checkInPeriodOptions = ["15 Minutes", "30 Minutes", "1 Hour", "2 Hours", "3 Hours", "4 Hours", "5 Hours", "6 Hours"];
  const mapOptions =
    game.shortName === "LoL"
      ? [
          { name: "Summoner's Rift", value: "SUMMONERS_RIFT" },
          { name: "Howling Abyss", value: "HOWLING_ABYSS" },
        ]
      : [
          { map: "Veto", numOfMatches: [1, 3, 5] },
          { map: "Inferno", numOfMatches: [1] },
          { map: "Mirage", numOfMatches: [1] },
          { map: "Nuke", numOfMatches: [1] },
          { map: "Overpass", numOfMatches: [1] },
          { map: "Dust 2", numOfMatches: [1] },
          { map: "Vertigo", numOfMatches: [1] },
          { map: "Ancient", numOfMatches: [1] },
        ];
  const pickModeOptions = [
    { name: "Tournament draft", value: "TOURNAMENT_DRAFT" },
    { name: "Draft pick", value: "DRAFT_PICK" },
    { name: "Blind pick", value: "BLIND_PICK" },
    { name: "All Random", value: "ALL_RANDOM" },
  ];
  const [selectedMatches, setSelectedMatches] = useState();
  const [selectedMap, setSelectedMap] = useState();
  const [teamRanks, setTeamRanks] = useState({
    minRank: -1,
    maxRank: -1,
  });
  const { open, onClose } = props;
  const { register, handleSubmit, setError, errors, watch } = useForm();

  const gameChangeWatcher = watch("game", game._id);

  const handleRankChange = (event) => {
    setTeamRanks({ ...teamRanks, [event.target.name]: parseInt(event.target.value) });
  };

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
      setGames(data);
    });
  }, []);

  useEffect(() => {
    authenticated &&
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/teams/my/${gameChangeWatcher}`).then(({ data }) => {
        setTeams(data);
      });
    gameChangeWatcher !== game._id && setGame(games.filter((gameData) => gameData._id === gameChangeWatcher)[0]);
  }, [gameChangeWatcher]);

  const onSubmit = async (dataAct) => {
    setLoading(true);

    let data = { ...dataAct, ...teamRanks };
    const { date, time } = data;

    data.date = moment(date + " " + time).toISOString();
    let checkInArr = data.checkInPeriod.split(" ");
    let checkInTime = -1;
    if (checkInArr[0] && checkInArr[1])
      checkInTime = checkInArr[1].toLowerCase().includes("minute")
        ? checkInArr[0] * 60
        : checkInArr[1].toLowerCase().includes("hour")
        ? checkInArr[0] * 60 * 60
        : checkInTime;
    let scrimDate = new Date(new Date(data.date).getTime() - checkInTime * 1000).toISOString();
    if (checkInTime > 0 && moment().diff(moment(scrimDate), "seconds") > 0) {
      setError("time", "invalid", "Time and check-in should not be earlier than current time");
      setError("checkInPeriod", "invalid");
    } else {
      delete data.time;
      try {
        const { data: scrim } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/scrims`, data);
        if (scrim) {
          toast.success("Successfully created scrim!");
          onClose();
        }
      } catch (e) {
        const msg = e.response && e.response.data ? e.response.data.msg : "There was a problem while creating scrim";
        toast.error(msg);
      }
    }
    setLoading(false);
  };

  const maxDate = () => {
    var dtToday = new Date();
    var month = dtToday.getMonth() + 2;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();
    return year + "-" + month + "-" + day;
  };
  return (
    <Modal isOpen={open} onRequestClose={onClose} className="modal" shouldCloseOnEsc={false}>
      <div className="modal-close" onClick={onClose}>
        <RiCloseLine />
      </div>
      <div className="modal-scrollable">
        <div className="modal-header">
          <h2>Create Scrim</h2>
          <h3>Improve your skills by finding teams to scrim against.</h3>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <label htmlFor="game">Choose Game</label>
              <select
                ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
                id="game"
                name="game"
                className={`form-input ${errors.game ? "invalid" : ""}`}
                defaultValue={game._id || "_DEFAULT_"}
              >
                <option value="_DEFAULT_" disabled>
                  Choose game
                </option>
                {games.map(
                  ({ _id, name }) =>
                    name != "No Game" && (
                      <option key={_id} value={_id}>
                        {name}
                      </option>
                    )
                )}
              </select>
              {errors.game && <div className="form-input-error">{errors.game.message}</div>}
            </div>

            <div className="rowX form-row Xsm-gutters">
              {/* <div className="col-6"> */}
              <label htmlFor="name">Choose Team</label>
              <select
                ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
                id="team"
                name="team"
                className={`form-input ${errors.team ? "invalid" : ""}`}
                defaultValue="_DEFAULT_"
              >
                <option value="_DEFAULT_" disabled>
                  Choose team
                </option>
                {teams.map(
                  (team) =>
                    team?.game?.name == game?.name && (
                      <option key={team._id} value={team._id}>
                        {team.name}
                      </option>
                    )
                )}
              </select>
              {errors.team && <div className="form-input-error">{errors.team.message}</div>}
              {/* </div> */}
              {/* <div className="col-6">
                <label htmlFor="initials">Min Rank</label>
                <select
                  ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
                  id="minRank"
                  name="minRank"
                  className={`form-input ${errors.rank ? "invalid" : ""}`}
                  defaultValue="_DEFAULT_"
                >
                  <option value="_DEFAULT_" disabled>
                    Choose min rank
                  </option>
                  {selectedGame.gameRanks?.map((rank, index) => (
                    index > 0 && <option key={index} value={index}>
                      {rank}
                    </option>
                  ))}
                </select>
                {errors.rank && <div className="form-input-error">{errors.rank.message}</div>}
              </div> */}
            </div>

            {game.shortName === "LoL" && (
              <div className="row form-row sm-gutters">
                <div className="col-6">
                  <label htmlFor="initials">Min Rank</label>
                  {/* <select
                  ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
                  id="minRank"
                  name="minRank"
                  className={`form-input ${errors.rank ? "invalid" : ""}`}
                  defaultValue="_DEFAULT_"
                >
                  <option value="_DEFAULT_" disabled>
                    Choose min rank
                  </option>
                  {selectedGame.gameRanks?.map((rank, index) => (
                    index > 0 && <option key={index} value={index}>
                      {rank}
                    </option>
                  ))}
                </select>
                {errors.minRank && <div className="form-input-error">{errors.minRank.message}</div>} */}
                  <select value={teamRanks.minRank} className="form-input" name="minRank" onChange={handleRankChange}>
                    <option value={-1} disabled>
                      Select Rank
                    </option>
                    {game.gameRanks?.map(
                      (rank, index) =>
                        rank &&
                        rank.toLowerCase() != "no rank found" &&
                        index >= teamRanks.maxRank && (
                          <option value={index} key={index}>
                            {rank}
                          </option>
                        )
                    )}
                  </select>
                </div>

                <div className="col-6">
                  <label htmlFor="initials">Max Rank</label>
                  {/* <select
                  ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
                  id="maxRank"
                  name="maxRank"
                  className={`form-input ${errors.rank ? "invalid" : ""}`}
                  defaultValue="_DEFAULT_"
                >
                  <option value="_DEFAULT_" disabled>
                    Choose max rank
                  </option>
                  {selectedGame.gameRanks?.map((rank, index) => (
                    index > 0 && <option key={index} value={index}>
                      {rank}
                    </option>
                  ))}
                </select>
                {errors.maxRank && <div className="form-input-error">{errors.maxRank.message}</div>} */}
                  <select className="form-input" value={teamRanks.maxRank} name="maxRank" onChange={handleRankChange}>
                    <option value={-1} disabled>
                      Select Rank
                    </option>
                    {game.gameRanks?.map(
                      (rank, index) =>
                        rank &&
                        rank.toLowerCase() != "no rank found" &&
                        teamRanks.minRank >= index && (
                          <option value={index} key={index}>
                            {rank}
                          </option>
                        )
                    )}
                  </select>
                </div>
              </div>
            )}

            {/* <div className="row form-row sm-gutters">
              <div className="col-6">
                <label htmlFor="name">Team Size</label>
                <select
                  ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
                  id="size"
                  name="size"
                  className={`form-input ${errors.size ? "invalid" : ""}`}
                  defaultValue="_DEFAULT_"
                >
                  <option value="_DEFAULT_" disabled>
                    Choose team size
                  </option>
                  {selectedGame.teamSizes?.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
                {errors.size && <div className="form-input-error">{errors.size.message}</div>}
              </div>
              <div className="col-6">
                <label htmlFor="initials">Scrim Format</label>
                <select
                  ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
                  id="scrimFormat"
                  name="scrimFormat"
                  className={`form-input ${errors.scrimFormat ? "invalid" : ""}`}
                  defaultValue="_DEFAULT_"
                >
                  <option value="_DEFAULT_" disabled>
                    Choose Scrim Format
                  </option>
                  {selectedGame.gameFormats?.map((format) => (
                    <option key={format} value={format}>
                      {format}
                    </option>
                  ))}
                </select>
                {errors.scrimFormat && <div className="form-input-error">{errors.scrimFormat.message}</div>}
              </div>
            </div> */}

            <div className="row form-row sm-gutters">
              <div className="col-6">
                <label htmlFor="time">Suggested Time and date</label>
                <input
                  ref={register({
                    required: "Required",
                  })}
                  type="time"
                  id="time"
                  name="time"
                  placeholder="00:00"
                  min={moment()}
                  className={`form-input time ${errors.time ? "invalid" : ""}`}
                />
                {errors.time && <div className="form-input-error">{errors.time.message}</div>}
              </div>
              <div className="col-6">
                <input
                  ref={register({
                    required: "Required",
                  })}
                  type="date"
                  // placeholder=""
                  // value=""
                  // max="2021-03-31"
                  max={maxDate()}
                  min={moment().format("YYYY-MM-DD")}
                  id="date"
                  name="date"
                  className={`form-input date ${errors.website ? "invalid" : ""}`}
                />
                {errors.date && <div className="form-input-error">{errors.date.message}</div>}
              </div>
            </div>

            <div className="row form-row sm-gutters">
              <div className="col-6">
                <label htmlFor="name">Number of matches</label>
                <select
                  ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
                  id="numberOfMatches"
                  name="numberOfMatches"
                  className={`form-input ${errors.numberOfMatches ? "invalid" : ""}`}
                  defaultValue="_DEFAULT_"
                  onChange={(v) => setSelectedMatches(parseInt(v.target.value))}
                >
                  <option value="_DEFAULT_" disabled>
                    Choose Number of matches
                  </option>
                  {numberOfMatchesOptions?.map((options) => (
                    <option key={`${options}-options`} value={options}>
                      {options}
                    </option>
                  ))}
                </select>
                {errors.numberOfMatches && <div className="form-input-error">{errors.numberOfMatches.message}</div>}
              </div>

              <div className="col-6">
                <label htmlFor="initials">Check-in period</label>
                <select
                  ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
                  id="checkInPeriod"
                  name="checkInPeriod"
                  className={`form-input ${errors.checkInPeriod ? "invalid" : ""}`}
                  defaultValue="_DEFAULT_"
                >
                  <option value="_DEFAULT_" disabled>
                    Choose Check-in period
                  </option>
                  {checkInPeriodOptions?.map((options) => (
                    <option key={`${options}-check-option`} value={options}>
                      {options}
                    </option>
                  ))}
                </select>
                {errors.checkInPeriod && <div className="form-input-error">{errors.checkInPeriod.message}</div>}
              </div>
            </div>

            <div className="row form-row sm-gutters">
              <div className={game.shortName === "LoL" ? "col-6" : "col-12"}>
                <label htmlFor="name">Map type</label>
                <select
                  ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
                  id="map"
                  name="map"
                  className={`form-input ${errors.map ? "invalid" : ""}`}
                  defaultValue={"Veto"}
                  onChange={(v) => setSelectedMap(v.target.value)}
                  value={game.shortName === "CSGO" && (selectedMatches === 3 || selectedMatches === 5) ? "Veto" : selectedMap || "Veto"}
                  disabled={game.shortName === "CSGO" && (selectedMatches === 3 || selectedMatches === 5)}
                >
                  {game.shortName === "LoL" ? (
                    mapOptions?.map(({ name, value }) => (
                      <option key={`${value}-mapoptions`} value={value}>
                        {name}
                      </option>
                    ))
                  ) : (
                    <>
                      {/* {!(selectedMatches === 3 || selectedMatches === 5) ? (
                        <option value="_DEFAULT_" disabled>
                          Veto
                        </option>
                      ) : null} */}
                      {mapOptions?.map(({ map, numOfMatches }) => (
                        <option key={`${map}-mapoptions`} value={map} disabled={selectedMatches === 3 || selectedMatches === 5}>
                          {map}
                        </option>
                      ))}
                    </>
                  )}
                </select>
                {errors.map && <div className="form-input-error">{errors.map.message}</div>}
              </div>

              {game.shortName === "LoL" && (
                <div className="col-6">
                  <label htmlFor="initials">Pick Mode</label>
                  <select
                    ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
                    id="pickMode"
                    name="pickMode"
                    className={`form-input ${errors.pickMode ? "invalid" : ""}`}
                    defaultValue="_DEFAULT_"
                  >
                    {pickModeOptions?.map(({ name, value }) => (
                      <option key={`${name}-pickMode-option`} value={value}>
                        {name}
                      </option>
                    ))}
                  </select>
                  {errors.pickMode && <div className="form-input-error">{errors.pickMode.message}</div>}
                </div>
              )}
            </div>
            <div className="row form-row sm-gutters">
              <div className="col-12">
                <label htmlFor="initials">Time Zone</label>
                <select
                  ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
                  id="timeZone"
                  name="timeZone"
                  className={`form-input ${errors.timeZone ? "invalid" : ""}`}
                  defaultValue={window.localStorage.getItem("timezone") || moment.tz.guess()}
                >
                  {/* <option value="_DEFAULT_" disabled>
                    Select Time Zone
                  </option> */}
                  {TIMEZONES?.map(({ name, value }) => (
                    <option key={`${value}-check-option`} value={value}>
                      {name}
                    </option>
                  ))}
                </select>
                {errors.timeZone && <div className="form-input-error">{errors.timeZone.message}</div>}
              </div>
            </div>
            {/* <div className="form-row">
              <label htmlFor="description">Description</label>
              <textarea
                ref={register({ maxLength: { value: 2000, message: "Description too long" } })}
                type="text"
                max={2000}
                rows={4}
                id="description"
                name="description"
                autoComplete="description"
                placeholder="Description"
                className={`form-input ${errors.description ? "invalid" : ""}`}
              />
              {errors.description && <div className="form-input-error">{errors.description.message}</div>}
            </div> */}
            {/* {error && <div className="text-12 color-danger">{error}</div>} */}
            <button type="submit" className="button primary thicc" disabled={loading}>
              {loading ? (
                <>
                  <Loading width={30} height={30} /> Creating Team
                </>
              ) : (
                "Create Scrim"
              )}
            </button>
          </form>
        </div>
      </div>
    </Modal>
  );
};
