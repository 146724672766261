import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
const OauthCallback = () => {
  const location = useLocation();
  const history = useHistory();
  const getQueryParams = (query) => {
    return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
          let [key, value] = param.split('=');
          params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
          return params;
        }, {}
        )
      : {};
  };

  async function authorize() {
    const params = getQueryParams(location.search);
    const game = params.state;

    const API = game === 'LoL' ? process.env.REACT_APP_LOL_API :
      game === 'csgo' ? process.env.REACT_APP_CSGO_API :
        game === 'val' ? process.env.REACT_APP_VL_API : null;

    if (!API) {
      console.error('No API endpoint set for game:', game);
      return Promise.reject('No API endpoint set'); // Handle the case where API is not set
    }

    try {
      const response = await fetch(`${API}/api/auth/oauth2-callback?code=${params.code}&iss=${params.iss}&state=${params.state}`)
      const { pathname, searchParams, error, payload } = await response.json()
      if(error) {
        console.error('SERVER ERROR:', error);
      }
      if(payload) {
        console.log('SERVER PAYLOAD:', payload);
      }
      const search = new URLSearchParams(searchParams).toString()
      console.log('search', search)
      history.push(`${pathname}?${search}`)
    }
    catch (error) {
      console.error('Error fetching token:', error);
    }

  }


  useEffect(() => {
    const init = async () => {
       await authorize();
    }
    init()
    /* const effectGames = fetchGames().then(data => data)
    console.log('effectGames', effectGames)
    const token = authorize()
    console.log('token', token) */
  }, [])


  return (
    <div>
      <h1>Callback PAGE</h1>
    </div>
  )
};


export default OauthCallback;