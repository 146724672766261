import React, { useContext } from "react";
import { PlayerFinder, TeamFinder, CoachFinder, ComparePlayers } from "./FinderTabs";
import { PageWithTabs } from "@common";
import cover_image from "@img/finder-cover.png";
import cover_image_csgo from "@img/ScrimFinderBannerCsgo.jpg";
import esport_series_logo from "@img/esport-series-logo.png";
import { AppContext } from "../../context/AppContext";

const Finder = () => {
  const { selectedGame } = useContext(AppContext);

  const pages = {
    "Player Finder": <PlayerFinder />,
    "Team Finder": <TeamFinder />,
    "Coach Finder": <CoachFinder />,
    "Compare Players": <ComparePlayers />,
  };

  return (
    <PageWithTabs
      showAdsInTabs
      title={`${selectedGame.name} Finder`}
      above_title="Finder"
      below_title={<React.Fragment>Find players, teams and coaches to join or recruit</React.Fragment>}
      pages={pages}
      croppedLogo={false}
      cover_image={
        selectedGame && selectedGame.scrimFinderBanner
          ? selectedGame.scrimFinderBanner
          : selectedGame && selectedGame.shortName === "CSGO"
          ? cover_image_csgo
          : cover_image
      }
      base_route="/finder"
      image={selectedGame.whiteIcon || esport_series_logo}
      // type="under-contruction-all-page"
    />
  );
};

export default Finder;
