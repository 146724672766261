import React from "react";
import { SimplePageWithTabs } from "@common";

const ConditionsOfUse = () => {
	return (
		<SimplePageWithTabs title="Conditions of use">
			<div className="row smaller-gutter-row">
				<div className="col-12"></div>
			</div>
		</SimplePageWithTabs>
	);
};

export default ConditionsOfUse;
