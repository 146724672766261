import React, { useState, useEffect, useContext } from "react";
import { useParams, Link, useHistory, Redirect } from "react-router-dom";
import { SimplePageWithTabs } from "@common";
import { AppContext } from "@context/AppContext";
import EditTeam from "./EditTeam/EditTeam";
import Axios from "axios";
import AllMembers from "../TeamsSingle/TeamsSingleTabs/Members/includes/AllMembers";
import Socials from "./Socials/socials";

const TeamsManage = () => {
  const { id } = useParams();
  const history = useHistory();
  const { user } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [team, setTeam] = useState(false);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/teams/${id}`)
      .then(({ data }) => {
        setTeam(data);
        setLoading(false);
      })
      .catch(() => {
        history.push("/error");
      });
  }, [id, history]);

  // const setProfileConfig = (profileConfig) => {
  //   setTeam({ ...team, profileConfig });
  // };

  const isOwner =
    !loading &&
    team &&
    (team.members.find(({ user: userId, role }) => user.state._id === userId?._id && ["Owner", "Manager"].includes(role)) ||
      team.organisation?.owners?.find(({ user: userId }) => user.state._id === userId._id) ||
      user.state.admin);

  let tabs = {
    Team: <EditTeam team={team} setTeam={setTeam} />,
    Socials: <Socials team={team} setTeam={setTeam} />,
    "Add Team Members": <AllMembers team={team} isOwner={isOwner} isManager={isOwner} />,
    // "Profile Settings": <ProfileSettings profileConfig={team.profileConfig} setProfileConfig={setProfileConfig} />,
  };

  return !loading && !isOwner ? (
    <Redirect to="/roma" />
  ) : (
    !loading && (
      <SimplePageWithTabs
        title="Manage Team"
        tabs={tabs}
        top_right={
          <Link to={`/teams/${id}`} className="primary-link">
            Back to team
          </Link>
        }
      />
    )
  );
};

export default TeamsManage;
