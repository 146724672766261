import React, { useContext, useState, useEffect } from "react";
import { Loading } from "@common";
import Dropdown from "./../Dropdown/Dropdown";
import { AppContext } from "../../../../context/AppContext";
import Axios from "axios";
import { Link } from "react-router-dom";
import Collapsible from "react-collapsible";

const GameSwitcherLink = ({ icon, name, onClick }) => {
  return (
    <div onClick={onClick} className="game-switcher-link text-12 bold pt-2 pb-2">
      <div className="icon" style={{ backgroundImage: `url(${icon})` }} />
      {name}
    </div>
  );
};

const GameSwitcher = ({ game, setGame }) => {
  const { selectedGame, setSelectedGame } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [games, setGames] = useState([]);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
      setGames(() => {
        return data.filter((game) => game.name !== "No Game");
      });
      // setGames(data);
      setLoading(false);
    });
  }, []);

  const onSwitch = (game) => {
    setSelectedGame(game);
    window.location.reload();
  };

  return (
    <Dropdown
      games={games && games.length}
      isGameSwitcher={true}
      image={selectedGame && selectedGame.whiteIcon}
      label={selectedGame && selectedGame.shortName}
      showCaret
      dynamic_width
      className="header-game-switcher"
      render={(open, setOpen) =>
        loading ? (
          <Loading width={20} height={20} className="loaderForGamesSelection" />
        ) : (
          <>
            {/* <Collapsible transitionTime={100} classParentString="collapsible-wrapper"> */}
            <div className="game-switcher-link text-12 bold pt-2 pb-2">
              <div className="icon" />
              <Link to={"/browse-games"} className={"primary-link"}>
                Browse Games
              </Link>
            </div>
            {games.map((game, i) => (
              <GameSwitcherLink
                key={i}
                icon={game.whiteIcon}
                name={game.name}
                onClick={() => {
                  onSwitch(game);
                  setOpen(false);
                }}
              />
            ))}
            {/* </Collapsible> */}
          </>
        )
      }
    />
  );
};

export default GameSwitcher;
