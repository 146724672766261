import React from "react";
import moment from "moment";
import reactStringReplace from "react-string-replace";
import youtube from "@img/youtube-round.svg";
import facebook from "@img/facebook-round.svg";
import twitter from "@img/twitter-round.svg";
import twitch from "@img/twitch-round.svg";
import instagram from "@img/instagram-round.svg";

const About = ({ user }) => {
  const items = [
    { icon: `https://flagcdn.com/w20/${user.nationality.code?.toLowerCase()}.png`, value: user.nationality.name, label: "Nationality" },
    user.gender && { value: user.gender, label: "Gender" },
    user.birthday && { value: moment().diff(user.birthday, "years"), label: "Age" },
    user.discord && { value: user.discord, label: "Discord" },
    user.phone && user.phoneExt && { value: `${user.phoneExt} ${user.phone}`, label: "Phone" },
    user.email && { value: user.email, label: "Email" },
  ].filter((i) => i);

  const linkify = (str) => {
    return reactStringReplace(str, /(https?:\/\/\S+)/g, (match, i) => (
      <a key={match + i} href={match} className="link" target="_blank" rel="noopener noreferrer">
        {match}
      </a>
    ));
  };

  return (
    <div>
      <div className="row smaller-gutter-row player-overview-row">
        {items.map(({ icon, value, label }) => (
          <div className="col-3 pt-3 pb-4">
            <div className="text-14 bold uppercase">
              {icon && <img src={icon} className="flag" alt={value} />}
              {value}
            </div>
            <div className="text-14 faded">{label}</div>
          </div>
        ))}
      </div>

      <hr />

      <div className="row player-overview-row">
        <div className="col-lg-3 row-title">
          <span className="text-16 faded bold uppercase">About</span>
        </div>
        <div className="col-lg-9 row-content">
          {user.about ? (
            <p className="text-14" style={{ whiteSpace: "pre-line" }}>
              {linkify(user.about)}
            </p>
          ) : (
            <div className="not-found">
              <p>This user hasn’t written anything about themselves.</p>
            </div>
          )}

          <div className="social-links">
            {[
              { name: "twitch", icon: twitch },
              { name: "facebook", icon: facebook },
              { name: "twitter", icon: twitter },
              { name: "instagram", icon: instagram },
              { name: "youtube", icon: youtube },
            ].map(
              ({ name, icon }) =>
                user[name] && (
                  <a href={!user[name].startsWith("http") ? `https://${user[name]}` : user[name]} target="_blank" rel="noopener noreferrer">
                    <img src={icon} alt={name} />
                  </a>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
