import React, { useEffect, useState } from "react";
import { ChampionsList, TournamentListItem, PositionDisplay } from "@common";
import Axios from "axios";
import { RiGamepadLine } from "react-icons/ri";
import RankData from "../includes/RankData";
import RankDataLeagueStats from "../includes/RankDataLeagueStats";
import { Loading } from "@common";

const TournamentStats = ({ type = "tournament", player }) => {
  const [stats, setStats] = useState({});
  const [matches, setMatches] = useState([]);
  const [championsData, setChampionsData] = useState([]);
  const [spellsData, setSpellsData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [championsLoading, setChampionsLoading] = useState(true);
  const [spellsLoading, setSpellsLoading] = useState(true);
  const [leagueData, setLeagueData] = useState({})

  useEffect(() => {
    setDataLoading(true);

    Axios.get(`${process.env.REACT_APP_LOL_API}/api/players/${player._id}/aggregratedStats`, { params: { type } }).then(({ data }) => {
      setStats(data.statistics);
      setMatches(data.matches);
      setLeagueData(data.statistics.leagueEntries.find(entry => entry.id === data?.matches[0]?.league?._id));
      setDataLoading(false);
    });
  }, [type, player._id]);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_LOL_API}/api/riot/champions`).then(({ data }) => {
      setChampionsData(data);
      setChampionsLoading(false);
    });

    Axios.get(`${process.env.REACT_APP_LOL_API}/api/riot/spells`).then(({ data }) => {
      setSpellsData(data);
      setSpellsLoading(false);
    });
  }, []);

  if (dataLoading || championsLoading || spellsLoading) {
    return <div className="col-12 text-center">
      <Loading />
    </div>
  }

  const championIdToImg = (championId) => {
    const championData = championsData[championId];
    const championVers = championData.version;
    const championImage = championData.image.full;

    return `https://ddragon.leagueoflegends.com/cdn/${championVers}/img/champion/${championImage}`;
  };

  const spellIdToImg = (spellId) => {
    const spellData = spellsData.find(({ key }) => key === spellId.toString());

    if (!spellData) return false;

    const spellVers = spellData.version;
    const spellImage = spellData.image.full;

    return `https://ddragon.leagueoflegends.com/cdn/${spellVers}/img/spell/${spellImage}`;
  };

  const championIdToName = (championId) => {
    const championData = championsData[championId];
    return championData.name;
  };

  const champions = stats.mostPlayedChampions.map(({ championId, wins, losses, kills, deaths, assists, games }) => ({
    image: championIdToImg(championId),
    name: championIdToName(championId),
    kda: deaths !== 0 ? parseFloat((kills + assists) / deaths).toFixed(2) : "Perfect",
    wr: Math.round((wins / (wins + losses)) * 100),
    games,
  }));

  const championHighestWins = stats.mostPlayedChampions[0];
  const championLowestWins = stats.mostPlayedChampions[stats.mostPlayedChampions.length - 1];

  return (
    <div className="temperary">
      <div className="row smaller-gutter-row pt-3">
        <div className="col-3">
          <PositionDisplay mostPlayed={stats.mostPlayedPositions[0] && stats.mostPlayedPositions[0].name} />

          {type === "ranked" &&
            <RankData leagueEntries={stats.leagueEntries && stats.leagueEntries.sort((a, b) => b.division - a.division)} />
          }

          {type === "tournament" &&
            <RankDataLeagueStats leagueData={leagueData} />
          }

          {!!champions.length && (
            <div className="panel">
              <header className="panel-header">MOST PLAYED CHAMPIONS</header>

              <div className="panel-body">
                <ChampionsList champions={champions} />
              </div>

              {/* <footer className="panel-footer">view more champions</footer> */}
            </div>
          )}
        </div>

        <div className="col-9">
          {!matches.length ? (
            <div className="no-matches">
              <RiGamepadLine />
              <span>No Matches</span>
            </div>
          ) : (
            <div>
              {type === "ranked" && (
                <div className="row smaller-gutter-row mr-0">
                  <div className="col-12">
                    <button className="button default mr-3">All LEAGUES.GG TOURNAMENTS</button>
                    <button className="button default mr-3">All seasons</button>
                    <button className="button transparent">Reset</button>
                  </div>
                </div>
              )}

              <div className="row smaller-gutter-row">
                <div className="col-12">
                  <div className="tournaments-stats-overview">
                    <div className="row smaller-gutter-row">
                      <div className="col-3 tournaments-stats-overview-flex-column">
                        <div
                          className="mini-pie-chart"
                          style={{
                            background: `conic-gradient(#3eabf0 0, #3eabf0 ${Math.round(
                              (stats.wins / (stats.wins + stats.losses)) * 100
                            )}%, #0b598a 0, #0b598a 100%)`,
                          }}
                        />
                        <div className="flex-column">
                          <div className="text-12 bold">{Math.round((stats.wins / (stats.wins + stats.losses)) * 100)}% WR</div>
                          <div className="text-10 bold faded">
                            Last {stats.wins + stats.losses} game{stats.wins + stats.losses === 1 ? "" : "s"}
                          </div>
                        </div>
                      </div>
                      <div className="col-3 tournaments-stats-overview-flex-column center">
                        <div className="flex-column">
                          <div className="text-12 bold color-primary center">
                            {stats.deaths !== 0 ? parseFloat((stats.kills + stats.assists) / stats.deaths).toFixed(2) : "Perfect"} KDA
                          </div>
                          <div className="text-10 bold center">
                            {stats.kills} / {stats.deaths} / {stats.assists}
                          </div>
                        </div>
                      </div>

                      {championHighestWins && (
                        <div className="col-3 tournaments-stats-overview-flex-column">
                          <img src={championIdToImg(championHighestWins.championId)} alt="" className="profile-images" />
                          <div className="flex-column">
                            <div className="text-12 bold">
                              {Math.round((championHighestWins.wins / (championHighestWins.wins + championHighestWins.losses)) * 100)}% WR
                            </div>
                            <div className="text-10 bold faded">
                              {championHighestWins.wins}W / {championHighestWins.losses}L -{" "}
                              {championHighestWins.deaths !== 0
                                ? parseFloat(
                                  (championHighestWins.kills + championHighestWins.assists) / championHighestWins.deaths
                                ).toFixed(2)
                                : "Perfect"}{" "}
                              KDA
                            </div>
                          </div>
                        </div>
                      )}

                      {championLowestWins && (
                        <div className="col-3 tournaments-stats-overview-flex-column">
                          <img src={championIdToImg(championLowestWins.championId)} alt="" className="profile-images" />
                          <div className="flex-column">
                            <div className="text-12 bold">
                              {Math.round((championLowestWins.wins / (championLowestWins.wins + championLowestWins.losses)) * 100)}% WR
                            </div>
                            <div className="text-10 bold faded">
                              {championLowestWins.wins}W / {championLowestWins.losses}L -{" "}
                              {championLowestWins.deaths !== 0
                                ? parseFloat((championLowestWins.kills + championLowestWins.assists) / championLowestWins.deaths).toFixed(2)
                                : "Perfect"}{" "}
                              KDA
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {matches.map((match) => {
                // console.log("mapData-match-", match);
                if (type === "tournament") {
                  const { league, lolData } = match;
                  const { mapData = [] } = lolData;

                  return mapData.map((mapData) => (
                    <TournamentListItem
                      type={league?.name}
                      mapData={mapData}
                      championIdToImg={championIdToImg}
                      spellIdToImg={spellIdToImg}
                      match={match}
                    />
                  ));
                } else if (type === "ranked") {
                  return (
                    <TournamentListItem
                      type="Ranked Solo"
                      mapData={match}
                      championIdToImg={championIdToImg}
                      spellIdToImg={spellIdToImg}
                      match={match}
                    />
                  );
                }

                return null;
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TournamentStats;
