import React, { useState, useEffect } from "react";
import { MirrorText, SimplePageWithTabs } from "@common";
import TeamsItem from "@components/TeamsRouter/TeamsItem/TeamsItem";
import Axios from "axios";
import { CreateTeamModal } from "../../_modals/CreateTeamModal/CreateTeamModal";
import { set } from "core-js/library/fn/dict";
import { getGameBackground } from "../../../utils/additional-helpers";

const TeamsTab = ({ filter, loading, teams, createTeam, setTeams, setCreateTeam }) => (
  <div>
    <div className="row smaller-gutter-row">
      <div className="col-9">
        <div className="row smaller-gutter-row">
          {!loading ? (
            teams.length ? (
              filter !== "all" ? (
                teams
                  .filter(({ game }) => game.shortName === filter)
                  .map((team) => <TeamsItem key={team._id}  croppedLogo={team.logoCropped} classes="col-4" {...team} link_to={`/teams/${team._id}`} />)
              ) : (
                teams.map((team) => <TeamsItem key={team._id}  croppedLogo={team.logoCropped} classes="col-4" {...team} link_to={`/teams/${team._id}`} />)
              )
            ) : (
              <div className="league-no-matches">
                <p>You’re currently not part of any team</p>
              </div>
            )
          ) : (
            [...Array(12).keys()].map((item) => <TeamsItem key={item} loading classes="col-4" />)
          )}
        </div>

        <CreateTeamModal open={createTeam} onClose={() => setCreateTeam(false)} afterCreate={(newTeam) => setTeams([...teams, newTeam])} />

        <button className="button wide-primary" onClick={() => setCreateTeam(true)} style={{ backgroundColor: getGameBackground() }}>
          Create New Team
        </button>
      </div>
      <div className="col-3">
        <div className="info-placeholder">
          <span>advertisement</span>
        </div>
      </div>
    </div>
  </div>
);

const Teams = () => {
  const [teams, setTeams] = useState([]);
  const [createTeam, setCreateTeam] = useState(false);

  const [loading, setLoading] = useState(true);
  const [allTeams, setAllTeams] = useState([]);
  useEffect(() => {
    if (teams.length === 0) {
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/teams/myTeams`).then(({ data }) => {
        setTeams(data);
        setAllTeams((data && data.length) ? data.filter(team => !!team.game) : []);
        setLoading(false);
      });
    }
  }, []);

  /**
   * dynamic tabs to only show the tabs of the games having one or more teams.
   */
  const calculatedTabs = [...new Set(allTeams.map((team) => team?.game?._id))]
    .map((_gameId) => allTeams.find(({ game }) => game._id === _gameId))
    .map(({ game: { name, shortName } }) => ({
      [`${name} (${allTeams.filter((_team) => _team.game.name === name).length})`]: (
        <TeamsTab
          filter={shortName}
          loading={loading}
          teams={allTeams}
          createTeam={createTeam}
          setTeams={setAllTeams}
          setCreateTeam={setCreateTeam}
        />
      ),
    }));

  const tabs = {
    [`All (${allTeams.length})`]: (
      <TeamsTab
        filter={`all`}
        loading={loading}
        teams={allTeams}
        createTeam={createTeam}
        setTeams={setAllTeams}
        setCreateTeam={setCreateTeam}
      />
    ),
    ...calculatedTabs.reduce((acc, tab) => ({ ...acc, ...tab }), {}),
  };

  return (
    <SimplePageWithTabs
      isTeams
      title="Teams"
      tabs={tabs}
      type="teams"
      top_right={
        <React.Fragment>
          <MirrorText text={["CH4LL3NG3R D14M0ND", "PL4T1N1UM"]} is_static />
          <CreateTeamModal
            open={createTeam}
            onClose={() => setCreateTeam(false)}
            afterCreate={(newTeam) => setTeams([...teams, newTeam])}
          />
          <button className="button primary ml-4" onClick={() => setCreateTeam(true)}>
            Create new team
          </button>
        </React.Fragment>
      }
    />
  );
};

export default Teams;
