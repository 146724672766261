import React, { useContext } from "react";
import Login from "./tabs/Login";
import Register from "./tabs/Register";
import { AppContext } from "../../../../context/AppContext";
import logo_header from "@img/logo-header.svg";

const LoginRegister = () => {
  const { showAuth: tab, setShowAuth } = useContext(AppContext);

  // Add tabs and the component the tab should render here
  // (title is the display title, name is a reference for it)
  const tabs = [
    { name: "login", title: "Log in", component: <Login /> },
    { name: "register", title: "Sign up", component: <Register /> },
  ];

  return (
    <div className="login-register-modal">
      <div className="auth-modal-header">
        <img src={logo_header} alt="Leagues.gg" />
        <h2 className="bold uppercase">Log in to Leagues.gg</h2>
      </div>
      <div className="auth-modal-tabs">
        {tabs.map(({ name, title }) => (
          <div key={name} className={`tab ${tab === name && "active"}`} onClick={() => setShowAuth(name)}>
            {title}
          </div>
        ))}
      </div>
      <div className="modal-body">{tabs.map(({ name, component }) => tab === name && <div key={name}>{component}</div>)}</div>
    </div>
  );
};

export default LoginRegister;
