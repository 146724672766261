import React, { useContext, useEffect, useState } from "react";
import cheerio from "cheerio";
import { AppContext } from "../../../../../context/AppContext";
import Scrollspy from "react-scrollspy";
import { Loading } from "@common";

const Rules = ({ slug = false }) => {
  const { ghost } = useContext(AppContext);
  const [rules, setRules] = useState(false);
  const [contents, setContents] = useState([]);
  const [ids, setIds] = useState([]);
  const [currentHeadingId, setCurrentHeadingId] = useState();
  const [loading, setLoading] = useState(slug ? true : false);

  useEffect(() => {
    if (slug) {
      ghost.pages
        .read({ slug })
        .then((post) => {
          setLoading(true);
          const h1 = [];
          const h2 = [];
          const h3 = [];
          const h4 = [];
          const h1Ids = [];
          const h2Ids = [];
          const h3Ids = [];
          const h4Ids = [];

          const $ = cheerio.load(post.html);

          $("h4").map((index, data) => {
            h4Ids.push(data.attribs.id);
            data.attribs = {
              ...data.attribs,
              style: "padding-left: 8rem",
            };
            h4.push({ chapter: data.childNodes[0].data, divId: data.attribs.id });
          });

          $("h3").map((index, data) => {
            h3Ids.push(data.attribs.id);
            data.attribs = {
              ...data.attribs,
              style: "padding-left: 6rem",
            };
            h3.push({
              chapter: data.childNodes[0].data,
              divId: data.attribs.id,
              subHeadings: h4.filter((x) => `${x.chapter}`.startsWith(data.childNodes[0].data.substr(0, 4))),
            });
          });

          $("h2").map((index, data) => {
            h2Ids.push(data.attribs.id);
            data.attribs = {
              ...data.attribs,
              style: "padding-left: 3rem",
            };
            h2.push({
              chapter: data.childNodes[0].data,
              divId: data.attribs.id,
              subHeadings: h3.filter((x) => `${x.chapter}`.startsWith(data.childNodes[0].data.substr(0, 3))),
            });
          });

          $("h1").map((index, data) => {
            h1Ids.push(data.attribs.id);
            h1.push({
              chapter: data.childNodes[0].data,
              divId: data.attribs.id,
              subHeadings: h2.filter((x) => `${x.chapter}`.startsWith(data.childNodes[0].data.substr(0, 2))),
            });
          });

          let ptag = $("p");
          let ultag = $("ul");
          let oltag = $("ol");

          alignTags(ptag);
          alignTags(ultag);
          alignTags(oltag);

          setRules($.html());
          setContents(h1);
          setIds([...h1Ids, ...h2Ids, ...h3Ids, ...h4Ids]);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
      }
  }, [slug, ghost.pages]);

  return loading ? (
    <div className="col-12 text-center">
      <Loading />
    </div>
  ) : !rules ? "No rules have been added to this league yet. Check back later!" : (
    <div>
      <div className="row smaller-gutter-row mt-4 rules-row">
        <div className="col-4">
          <div className="table-of-contents ">
            <div className="column table">
              <h1 className="pt-0">Table of contents</h1>
              {/* <ul style={{ listStyle: "none" }}> */}
              {ids.length ? (
                <Scrollspy
                  items={ids}
                  // currentClassName="is-current"
                  onUpdate={(e) => {
                    if (e) setCurrentHeadingId(e.id);
                  }}
                >
                  {contents.map(({ chapter, divId, subHeadings }, i) => (
                    <li className={currentHeadingId === divId ? "is-current-li-main text-14 uppercase bold mt-4" : "" + "text-14 uppercase bold mt-4"} key={i}>
                      <a
                        href={`#${divId}`}
                        className={currentHeadingId === divId ? "is-current" : "" + "faded table-title "}
                        style={{ marginLeft: `${currentHeadingId === divId ? "-19.5px" : ""}`, textDecoration: "none" }}
                        key={i}
                        dangerouslySetInnerHTML={{ __html: chapter }}
                      />
                      {subHeadings.length ? (
                        subHeadings.map(({ chapter, divId, subHeadings }, i) => (
                          <li
                            className={currentHeadingId === divId ? "is-current-li text-14 uppercase bold mt-2 ml-1" : "" + "text-14 uppercase bold mt-2 ml-4"}
                            key={i}>
                            <a
                              href={`#${divId}`}
                              className={currentHeadingId === divId ? "is-current" : "" + "faded table-title "}
                              style={{ textDecoration: "none" }}
                              key={i}
                              dangerouslySetInnerHTML={{ __html: chapter }}
                            />
                            {subHeadings.length ? (
                              subHeadings.map(({ chapter, divId, subHeadings }, i) => (
                                <li className="text-14 uppercase bold mt-2 ml-4" key={i}>
                                  <a
                                    href={`#${divId}`}
                                    className={currentHeadingId === divId ? "is-current" : "" + "faded table-title "}
                                    style={{ textDecoration: "none" }}
                                    key={i}
                                    dangerouslySetInnerHTML={{ __html: chapter }}
                                  />
                                  {subHeadings.length ? (
                                    subHeadings.map(({ chapter, divId, subHeadings }, i) => (
                                      <li className="text-14 uppercase bold mt-2 ml-4" key={i}>
                                        <a
                                          href={`#${divId}`}
                                          className={currentHeadingId === divId ? "is-current" : "" + "faded table-title "}
                                          style={{ textDecoration: "none" }}
                                          key={i}
                                          dangerouslySetInnerHTML={{ __html: chapter }}
                                        />
                                      </li>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </li>
                              ))
                            ) : (
                              <></>
                            )}
                          </li>
                        ))
                      ) : (
                        <></>
                      )}
                    </li>
                  ))}
                </Scrollspy>
              ) : null}
              {/* </ul> */}
            </div>
          </div>
        </div>
        <div className="col-8">
          <div className="leagues-gg-rules-body" dangerouslySetInnerHTML={{ __html: rules }}></div>
        </div>
      </div>
    </div>
  );
};

const alignTags = (tags) => {
  if (!tags) return;
  
  tags.map((index, tag) => {
    let _tag = tag;
    while (true && tag) {
      if (["h1", "h2", "h3", "h4", "h5"].includes(tag?.prev?.name)) {
        switch (tag.prev.name) {
          case "h2":
            _tag.attribs = {
              ..._tag.attribs,
              style: "padding-left: 3rem;",
            };
            break;
          case "h3":
            _tag.attribs = {
              ..._tag.attribs,
              style: "padding-left: 6rem;",
            };
            break;
          case "h4":
            _tag.attribs = {
              ..._tag.attribs,
              style: "padding-left: 8rem;",
            };
            break;
          default:
            break;
        }
        break;
      } else {
        tag = tag.prev;
      }
    }
  })
};

export default Rules;
