import React, { useState } from "react";
import { CheckboxSlider } from "@common";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { RiMenuLine } from "react-icons/ri";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const SortableItem = SortableElement(({ i, name, hide, setHide }) => (
  <div className={`reorder-item ${hide ? "hidden" : ""}`}>
    {name}
    <span className="input">
      <input type="checkbox" id={`hide-${i}`} checked={hide} onChange={(e) => setHide(e.target.checked, i)} />
      <label htmlFor={`hide-${i}`}>Hide</label>
      <RiMenuLine />
    </span>
  </div>
));

const SortableList = SortableContainer(({ items, setHide }) => {
  return (
    <div className="reorder-list">
      {items.map((item, i) => (
        <SortableItem key={`item-${item.name}`} index={i} i={i} {...item} setHide={setHide} />
      ))}
    </div>
  );
});

const ProfileSettings = ({ profileConfig, setProfileConfig }) => {
  const { id } = useParams();
  const [tabs, setTabs] = useState(profileConfig.tabs);
  const [logoInBanner, setLogoInBanner] = useState(profileConfig.logoInBanner);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTabs((items) => arrayMove(items, oldIndex, newIndex));
  };

  const onSetHide = (val, index) => {
    tabs[index].hide = val;
    setTabs([...tabs]);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/organisations/${id}/profileConfig`, { tabs, logoInBanner });
      setProfileConfig(data.profileConfig);
      toast.success("Saved");
    } catch (e) {
      toast.error("There was a problem saving");
    }
  };

  return (
    <form onSubmit={onSubmit} className="account-settings-form">
      <div className="row">
        <div className="col-6">
          <div className="form-row">
            <label>Tabs</label>

            <SortableList items={tabs} setHide={onSetHide} onSortEnd={onSortEnd} />
          </div>
        </div>

        <div className="col-6">
          <div className="form-row">
            <label>Options</label>
            <CheckboxSlider value={logoInBanner} onChange={(e) => setLogoInBanner(e.target.checked)} label="Show logo in banner" />
          </div>
        </div>
      </div>

      <button className="button primary">Save</button>
    </form>
  );
};

export default ProfileSettings;
