import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { Loading } from "@common";
import { AppContext } from "@components/../context/AppContext";
import { Overview, TournamentStats, ChampionStats, Teams } from "./GameProfileTabs";
import { PageWithTabs } from "@common";
import { toast } from "react-toastify";
import { FaExternalLinkAlt } from "react-icons/fa";

const GameProfile = () => {
  const { id } = useParams();
  const context = useContext(AppContext);
  const { selectedGame, setSelectedGame } = context;
  const [loading, setLoading] = useState(true);
  const [player, setPlayer] = useState();
  const [updatingData, setUpdatingData] = useState(false);
  const [stats, setStats] = useState({});
  const [matches, setMatches] = useState([]);
  const [totalWin, setTotalWin] = useState(Number(0));
  const [totalLoss, setTotalLoss] = useState(Number(0));

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/players/${id}`)
      .then(({ data }) => {
        if (data.game.shortName !== selectedGame.shortName) {
          setSelectedGame(data.game);
          return window.location.reload();
        } else {
          setPlayer(data);
          setLoading(false);
        }
      })
      .catch((e) => {
        let error = "There was a problem fetching player data";
        if (e.response && e.response.data) error = e.response.data.msg;
        toast.error(error);
      });
  }, [id, context.user.state]);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_LOL_API}/api/players/${id}/aggregratedStats`, { params: { type: "tournament" } }).then(
      ({ data }) => {
        // setStats(data.statistics);
        // setMatches(data.matches);
        let winnn = 0;
        let losss = 0;

        data.statistics.leagueEntries.length &&
          data.statistics.leagueEntries.map((entry, i) => {
            winnn = totalWin + entry.wins;
            losss = totalLoss + entry.losses;
          });

        setTotalWin(winnn);
        setTotalLoss(losss);
      }
    );
  }, [id]);

  const updatePlayerData = async () => {
    setUpdatingData(true);

    try {
      await Axios.post(`${process.env.REACT_APP_LOL_API}/api/players/${id}/update`);

      return window.location.reload();
    } catch (e) {
      let error = "There was a problem updating player data";
      if (e.response && e.response.data) error = e.response.data.msg;

      toast.error(error);
    }

    setUpdatingData(false);
  };

  if (loading) {
    return <div className="col-12 text-center">
      <Loading />
    </div>
  }

  let showPlayerStats = selectedGame && selectedGame.accessibility ? selectedGame.accessibility["Player Stats"] : true;
  let pages =
    player && player.game && player.game.name === "CS:GO"
      ? {
        Overview: <Overview player={player} setPlayer={setPlayer} totalWin={totalWin} totalLoss={totalLoss} />,
        ...(showPlayerStats && {
          "Leagues.gg stats": <TournamentStats type="tournament" player={player} />,
          "Map stats": <ChampionStats player={player} />,
        }),
        Teams: <Teams gameProfileId={id} teams={player.assocTeams} />,
      }
      : player && player?.game?.name.toLowerCase() === "league of legends"
        ? {
          Overview: <Overview player={player} setPlayer={setPlayer} totalWin={totalWin} totalLoss={totalLoss} />,
          ...(showPlayerStats && {
            "Leagues.gg stats": <TournamentStats type="tournament" player={player} />,
            "Ranked stats": <TournamentStats type="ranked" player={player} />,
            "Champion stats": <ChampionStats player={player} />,
          }),
          Teams: <Teams gameProfileId={id} teams={player.assocTeams} />,
        }
        : {
          Overview: (
            <Overview player={player} gameName={player.game.name} setPlayer={setPlayer} totalWin={totalWin} totalLoss={totalLoss} />
          ),
          Teams: <Teams gameProfileId={id} teams={player.assocTeams} />,
        };
  return (
    <>
      {/* <CreateCoachModal
        open={showCreateCoach}
        onClose={() => setShowCreateCoach(false)}
        afterCreate={(coach) => onCreate(coach, "coaches")}
      />
      <CreatePlayerModal
        open={showCreatePlayer}
        onClose={() => setShowCreatePlayer(false)}
        afterCreate={(player) => onCreate(player, "players")}
      /> */}
      <PageWithTabs
        pages={pages}
        tab_link={
          <a href={"/players/" + player.user._id}>
            User profile <FaExternalLinkAlt />
          </a>
        }
        base_route={`/game-profile/${id}`}
        image={player.avatarImage}
        // cover_image={cover_image}
        append_to_header={
          <div className="update-btn-container">
            {/* <div>
              <button className="button bordered" onClick={() => setShowCreateCoach(true)}>
                Create Coach
              </button>
            </div>
            <div>
              <button className="button bordered" onClick={() => setShowCreatePlayer(true)}>
                Create Player
              </button>
            </div> */}
            <div>
              <button className="button primary" disabled={updatingData} onClick={updatePlayerData}>
                {updatingData ? <Loading width={17} height={17} /> : "Update Data"}
              </button>
              <p className="last-updated-text">Last updated {moment(player?.lolData?.lastUpdated).fromNow()}</p>
            </div>
          </div>
        }
        title={player.name}
        above_title={player.user ? player.user.firstName + " " + player.user.lastName : "[Deleted]"}
        below_title={
          <React.Fragment>
            Signed up {moment(player.createdAt).fromNow()}
            <span className="pill">Player</span>
            <span className="pill">
              <img src={`https://flagcdn.com/w20/${player.user.nationality.code.toLowerCase()}.png`} alt={player.user.nationality.name} />
              {player.user.nationality.name}
            </span>
          </React.Fragment>
        }
      // type="under-contruction"
      tagLine={player?.tagLine}
      />
    </>
  );
};

export default GameProfile;
