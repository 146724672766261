import React, { useState, useEffect, useContext } from "react";
import Modal from "react-modal";
import { RiCloseLine } from "react-icons/ri";
import { useForm } from "react-hook-form";
import Loading from "../../_common/Loading/Loading";
import { toast } from "react-toastify";
import Axios from "axios";
import moment from "moment";
import { AppContext } from "../../../context/AppContext";
import { TIMEZONES } from "../../../utils/timezones";

export const EditScrimModal = (props) => {
  const { open, onClose } = props;

  const [scrim, setScrim] = useState(props.scrim);
  const { selectedGame, setSelectedGame, authenticated } = useContext(AppContext);

  const [games, setGames] = useState([]);
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMatches, setSelectedMatches] = useState(scrim.numberOfMatches);
  const [selectedMap, setSelectedMap] = useState(scrim.map);
  const numberOfMatchesOptions = selectedGame.shortName === "LoL" ? [1, 2, 3, 4, 5] : [1, 3, 5];
  const checkInPeriodOptions = ["15 Minutes", "30 Minutes", "1 Hour", "2 Hours", "3 Hours", "4 Hours", "5 Hours", "6 Hours"];
  const mapOptions =
    selectedGame.shortName === "LoL"
      ? ["Summoner's Rift", "Howling Abyss"]
      : [
          { map: "Veto", numOfMatches: [1, 3, 5] },
          { map: "Inferno", numOfMatches: [1] },
          { map: "Mirage", numOfMatches: [1] },
          { map: "Nuke", numOfMatches: [1] },
          { map: "Overpass", numOfMatches: [1] },
          { map: "Dust 2", numOfMatches: [1] },
          { map: "Vertigo", numOfMatches: [1] },
          { map: "Ancient", numOfMatches: [1] },
        ];
  // const pickModeOptions = ["Tournament draft", "Draft pick"];
  const pickModeOptions = [
    { name: "Tournament draft", value: "TOURNAMENT_DRAFT" },
    { name: "Draft pick", value: "DRAFT_PICK" },
    { name: "Blind pick", value: "BLIND_PICK" },
    { name: "All Random", value: "ALL_RANDOM" },
  ];

  const [teamRanks, setTeamRanks] = useState({
    minRank: (scrim && scrim.minRank) || -1,
    maxRank: (scrim && scrim.maxRank) || -1,
  });

  const { register, handleSubmit, setError, errors, watch } = useForm();

  useEffect(() => {
    scrim.minRank &&
      scrim.maxRank &&
      setTeamRanks({
        minRank: scrim.minRank,
        maxRank: scrim.maxRank,
      });
  }, [scrim]);

  const gameChangeWatcher = watch("game", selectedGame._id);

  const handleRankChange = (event) => {
    setTeamRanks({ ...teamRanks, [event.target.name]: parseInt(event.target.value) });
  };

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
      setGames(data);
    });
  }, []);

  useEffect(() => {
    authenticated &&
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/teams/my/${gameChangeWatcher}`).then(({ data }) => {
        setTeams(data);
      });
    gameChangeWatcher !== selectedGame._id && setSelectedGame(games.filter((game) => game._id === gameChangeWatcher)[0]);
  }, [gameChangeWatcher]);

  const onSubmit = async (dataAct) => {
    setLoading(true);

    let data = { ...scrim, ...dataAct, ...teamRanks };
    data.game = selectedGame._id;

    const { date, time } = data;

    data.date = moment(date + " " + time).toISOString();
    let checkInArr = data.checkInPeriod.split(" ");
    let checkInTime = -1;
    if (checkInArr[0] && checkInArr[1])
      checkInTime = checkInArr[1].toLowerCase().includes("minute")
        ? checkInArr[0] * 60
        : checkInArr[1].toLowerCase().includes("hour")
        ? checkInArr[0] * 60 * 60
        : checkInTime;
    let scrimDate = new Date(new Date(data.date).getTime() - checkInTime * 1000).toISOString();
    if (checkInTime > 0 && moment().diff(moment(scrimDate), "seconds") > 0) {
      setError("time", "invalid", "Time and check-in should not be earlier than current time");
      setError("checkInPeriod", "invalid");
    } else {
      delete data.time;
      const { minRank, maxRank, date: dateNew, numberOfMatches, checkInPeriod, pickMode, map, timeZone } = data;
      try {
        const { data: scrim } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/scrims/${data._id}`, {
          minRank,
          maxRank,
          date: dateNew,
          numberOfMatches,
          checkInPeriod,
          pickMode,
          map,
          timeZone,
        });
        if (scrim) {
          setScrim(scrim.updatedScrim);
          toast.success("Successfully updated scrim!");
          onClose();
        }
      } catch (e) {
        const msg = e.response && e.response.data ? e.response.data.msg : "There was a problem while updating scrim";
        toast.error(msg);
      }
    }
    setLoading(false);
  };

  return (
    <Modal isOpen={open} onRequestClose={onClose} className="modal" shouldCloseOnEsc={false}>
      <div className="modal-close" onClick={onClose}>
        <RiCloseLine />
      </div>
      <div>
        <div className="modal-header">
          <h2>Edit Scrim</h2>
          <h3>Improve your skills by finding teams to scrim against.</h3>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            {selectedGame.shortName === "LoL" && (
              <div className="row form-row sm-gutters">
                <div className="col-6">
                  <label htmlFor="initials">Min Rank</label>

                  <select className="select" value={teamRanks.minRank} name="minRank" onChange={handleRankChange}>
                    <option value={-1} disabled>
                      Select Rank
                    </option>
                    {selectedGame.gameRanks?.map(
                      (rank, index) =>
                        rank &&
                        rank.toLowerCase() != "no rank found" &&
                        teamRanks.maxRank <= index && (
                          <option value={index} key={index}>
                            {rank}
                          </option>
                        )
                    )}
                  </select>
                </div>

                <div className="col-6">
                  <label htmlFor="initials">Max Rank</label>

                  <select className="select" value={teamRanks.maxRank} name="maxRank" onChange={handleRankChange}>
                    <option value={selectedGame?.gameRanks?.length || 99999} disabled>
                      Select Rank
                    </option>
                    {selectedGame.gameRanks?.map(
                      (rank, index) =>
                        rank &&
                        rank.toLowerCase() != "no rank found" &&
                        teamRanks.minRank >= index && (
                          <option value={index} key={index}>
                            {rank}
                          </option>
                        )
                    )}
                  </select>
                </div>
              </div>
            )}

            <div className="row form-row sm-gutters">
              <div className="col-6">
                <label htmlFor="time">Suggested Time and date</label>
                <input
                  ref={register({
                    required: "Required",
                  })}
                  type="time"
                  id="time"
                  name="time"
                  placeholder="00:00"
                  min={moment()}
                  defaultValue={moment(scrim.date).format("HH:MM")}
                  className={`form-input time ${errors.time ? "invalid" : ""}`}
                />
                {errors.time && <div className="form-input-error">{errors.time.message}</div>}
              </div>
              <div className="col-6">
                <input
                  ref={register({
                    required: "Required",
                  })}
                  type="date"
                  placeholder=""
                  defaultValue={moment(scrim.date).format("YYYY-MM-DD")}
                  max={() => {
                    var dtToday = new Date();
                    var month = dtToday.getMonth() + 1;
                    var day = dtToday.getDate();
                    var year = dtToday.getFullYear();
                    if (month < 10) month = "0" + month.toString();
                    if (day < 10) day = "0" + day.toString();
                    return year + "-" + month + "-" + day;
                  }}
                  min={moment().format("YYYY-MM-DD")}
                  id="date"
                  name="date"
                  className={`form-input date ${errors.website ? "invalid" : ""}`}
                />
                {errors.date && <div className="form-input-error">{errors.date.message}</div>}
              </div>
            </div>

            <div className="row form-row sm-gutters">
              <div className="col-6">
                <label htmlFor="name">Number of matches</label>
                <select
                  defaultValue={scrim.numberOfMatches}
                  ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
                  id="numberOfMatches"
                  name="numberOfMatches"
                  className={`form-input ${errors.numberOfMatches ? "invalid" : ""}`}
                  // defaultValue="_DEFAULT_"
                  onChange={(v) => setSelectedMatches(parseInt(v.target.value))}
                >
                  <option value="_DEFAULT_" disabled>
                    Choose Number of matches
                  </option>
                  {numberOfMatchesOptions?.map((options) => (
                    <option key={`${options}-options`} value={options}>
                      {options}
                    </option>
                  ))}
                </select>
                {errors.numberOfMatches && <div className="form-input-error">{errors.numberOfMatches.message}</div>}
              </div>

              <div className="col-6">
                <label htmlFor="initials">Check-in period</label>
                <select
                  defaultValue={scrim.checkInPeriod}
                  ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
                  id="checkInPeriod"
                  name="checkInPeriod"
                  className={`form-input ${errors.checkInPeriod ? "invalid" : ""}`}
                  // defaultValue="_DEFAULT_"
                >
                  <option value="_DEFAULT_" disabled>
                    Choose Check-in period
                  </option>
                  {checkInPeriodOptions?.map((options) => (
                    <option key={`${options}-check-option`} value={options}>
                      {options}
                    </option>
                  ))}
                </select>
                {errors.checkInPeriod && <div className="form-input-error">{errors.checkInPeriod.message}</div>}
              </div>
            </div>

            <div className="row form-row sm-gutters">
              <div className={selectedGame.shortName === "LoL" ? "col-6" : "col-12"}>
                <label htmlFor="name">Map type</label>
                <select
                  ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
                  id="map"
                  name="map"
                  className={`form-input ${errors.map ? "invalid" : ""}`}
                  defaultValue={scrim.map}
                  onChange={(v) => setSelectedMap(v.target.value)}
                  value={selectedGame.shortName === "CSGO" && (selectedMatches === 3 || selectedMatches === 5) ? "Veto" : selectedMap}
                  disabled={selectedGame.shortName === "CSGO" && (selectedMatches === 3 || selectedMatches === 5)}
                >
                  {selectedGame.shortName === "LoL" ? (
                    mapOptions?.map((options) => (
                      <option key={`${options}-mapoptions`} value={options}>
                        {options}
                      </option>
                    ))
                  ) : (
                    <>
                      {!(selectedMatches === 3 || selectedMatches === 5) ? (
                        <option value="_DEFAULT_" disabled>
                          Choose Map
                        </option>
                      ) : null}
                      {mapOptions?.map(
                        ({ map, numOfMatches }) =>
                          numOfMatches.includes(selectedMatches) && (
                            <option key={`${map}-mapoptions`} value={map} disabled={selectedMatches === 3 || selectedMatches === 5}>
                              {map}
                            </option>
                          )
                      )}
                    </>
                  )}
                </select>
                {errors.map && <div className="form-input-error">{errors.map.message}</div>}
              </div>

              {selectedGame.shortName === "LoL" && (
                <div className="col-6">
                  <label htmlFor="initials">Pick Mode</label>
                  <select
                    ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
                    id="pickMode"
                    name="pickMode"
                    className={`form-input ${errors.pickMode ? "invalid" : ""}`}
                    defaultValue={scrim.pickMode}
                  >
                    {pickModeOptions?.map(({ name, value }) => (
                      <option key={`${name}-pickMode-option`} value={value}>
                        {name}
                      </option>
                    ))}
                  </select>
                  {errors.pickMode && <div className="form-input-error">{errors.pickMode.message}</div>}
                </div>
              )}
            </div>
            <div className="row form-row sm-gutters">
              <div className="col-12">
                <label htmlFor="initials">Time Zone</label>
                <select
                  ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
                  id="timeZone"
                  name="timeZone"
                  className={`form-input ${errors.timeZone ? "invalid" : ""}`}
                  defaultValue={scrim.timeZone || window.localStorage.getItem("timezone") || moment.tz.guess()}
                >
                  {TIMEZONES?.map(({ name, value }) => (
                    <option key={`${value}-check-option`} value={value}>
                      {name}
                    </option>
                  ))}
                </select>
                {errors.timeZone && <div className="form-input-error">{errors.timeZone.message}</div>}
              </div>
            </div>
            <button type="submit" className="button primary thicc" disabled={loading}>
              {loading ? (
                <>
                  <Loading width={30} height={30} /> Update...
                </>
              ) : (
                "Edit Scrim"
              )}
            </button>
          </form>
        </div>
      </div>
    </Modal>
  );
};
