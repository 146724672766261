import React, { useState, createContext, useEffect } from "react";
import Modal from "react-modal";
import CreateTeam from "./CreateTeam/CreateTeam";
import InvitePlayers from "./InvitePlayers/InvitePlayers";
import AddTeamImages from "./AddTeamImages/AddTeamImages";
import AddTeamSocials from "./AddTeamSocials/AddTeamSocials";
import { RiCloseLine } from "react-icons/ri";

export const CreateTeamModalContext = createContext();

export const CreateTeamModal = ({ open, onClose, organisation, organisationId, game, nationality, afterCreate }) => {
  const [step, setStep] = useState(0);
  const [teamData, setTeamData] = useState({});

  useEffect(() => {
    // reset modal when it changes visibility
    setStep(0);
    setTeamData({});
  }, [open]);

  const steps = [<CreateTeam />, <InvitePlayers />, <AddTeamImages />, <AddTeamSocials />];

  return (
    <Modal isOpen={open} onRequestClose={onClose} className="modal" shouldCloseOnEsc={false}>
      <div className="modal-close" onClick={onClose}>
        <RiCloseLine />
      </div>
      {step > 0 ? (
        <div className="modal-step-progress">
          <span className="text-12 uppercase bold color-primary">step {step + 1}/4</span>
        </div>
      ) : null}
      <CreateTeamModalContext.Provider
        value={{ setStep, onClose, organisation, organisationId, game, nationality, teamData, setTeamData, afterCreate }}
      >
        {steps.map((component, i) => i === step && <div key={i}>{component}</div>)}
      </CreateTeamModalContext.Provider>
    </Modal>
  );
};
