import React, { useContext, useState } from "react";
import { CheckboxSlider } from "@common";
import { AppContext } from "../../../../context/AppContext";
import { toast } from "react-toastify";
import Axios from "axios";
import { Link } from "react-router-dom";

const MyPrivacy = () => {
  const { user } = useContext(AppContext);
  const [emailPublic, setEmailPublic] = useState(user.state.emailPublic);
  const [phonePublic, setPhonePublic] = useState(user.state.phonePublic);
  const [discordPublic, setDiscordPublic] = useState(user.state.discordPublic);

  const onSubmit = async (e) => {
    e.preventDefault();

    const data = { emailPublic, phonePublic, discordPublic };

    try {
      const { data: newData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/auth`, data);
      user.dispatch({ type: "update", payload: newData.updatedUser });
      toast.success("Privacy settings updated");
    } catch (e) {
      const msg = e.response.data ? e.response.data.msg : "Fatal error";
      toast.error(msg);
    }
  };

  return (
    <form onSubmit={onSubmit} className="account-settings-form">
      <div className="row">
        <div className="col-12">
          <div className="form-row">
            <CheckboxSlider value={emailPublic} onChange={(e) => setEmailPublic(e.target.checked)} label="Show email on profile" />
            <CheckboxSlider value={phonePublic} onChange={(e) => setPhonePublic(e.target.checked)} label="Show phone number on profile" />
            <CheckboxSlider value={discordPublic} onChange={(e) => setDiscordPublic(e.target.checked)} label="Show Discord ID on profile" />
          </div>
          <p className="text-14 text-faded">
            We take all possible precautions to keep your data safe. Take a look at our{" "}
            <Link to="/p/privacy-policy" className="link">
              privacy policy
            </Link>{" "}
            for information on how we collect and store data.
          </p>
        </div>
      </div>

      <div className="form-row">
        <button className="button primary" type="submit">
          Save Changes
        </button>
      </div>
    </form>
  );
};

export default MyPrivacy;
