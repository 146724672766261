import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { RichEditor } from "../../../_common/RichEditor/RichEditor";
import Axios from "axios";
import { toast } from "react-toastify";
import { Checkbox } from "@common";

import { RiEditLine } from "react-icons/ri";
import EditImageModal from "../../../_modals/EditImageModal/EditImageModal";
import DeleteTeamModal from "./DeleteTeamModal/DeleteTeamModal";
import { countries } from "@utils";

import { AppContext } from "../../../../context/AppContext";

const EditTeam = ({ team, setTeam }) => {
  const { selectedGame } = useContext(AppContext);
  const { handleSubmit, register, setValue, errors } = useForm();
  const [about, setAbout] = useState(team.about || "");
  const [logoCropped, setLogoCropped] = useState(team.logoCropped === undefined ? true : team.logoCropped);
  const [nationalities, setNationalities] = useState(countries
    .filter(({ demonym }) => demonym)
    .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
    .sort((a, b) => a.demonym.localeCompare(b.demonym)));
  const [modals, setModals] = useState({
    logo: false,
    banner: false,
  });
  const [showDeleteTeam, setShowDeleteTeam] = useState(false);

  useEffect(() => {
    setValue("nationality", JSON.stringify(team.nationality));
  }, [team.nationality]);

  const onEditImage = async (type, image) => {
    try {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/teams/${team._id}/image`, { type, image });
      const modalConfig = type === "originalLogo" ? { ...modals, ["logo"]: false } : { ...modals, [type]: false };
      setModals(modalConfig);
      setTeam({ ...team, logo: data.updatedTeam.logo, banner: data.updatedTeam.banner });
    } catch (err) {
      toast.error("There was a problem updating your team's images");
    }
  };

  const onDeleteTeam = () => setShowDeleteTeam(true);

  const onSubmit = async (data) => {
    try {
      await Axios.put(`${process.env.REACT_APP_CORE_API}/api/teams/${team._id}?gameId=${team.game._id}`, { ...data, about, logoCropped });
      toast.success("Saved");
    } catch (e) {
      const error = e.response.data ? e.response.data.msg : "There was a problem saving";
      toast.error(error);
    }
  };

  const onLogoUpdate = async (value) => {
    try {
      let body = {
        email: team.email,
        initials: team.initials,
        name: team.name,
        nationality: team.nationality,
        tagline: team.tagline,
        website: team.website,
        logoCropped: value,
        about: team.about,
      };
      await Axios.put(`${process.env.REACT_APP_CORE_API}/api/teams/${team._id}?gameId=${team.game._id}`, { ...body });
    } catch (e) {
      const error = e.response.data ? e.response.data.msg : "There was a problem saving";
      toast.error(error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="account-settings-form">
        <div className="row">
          <div className="col-4">
            <div className="form-row">
              <label htmlFor="name">Name</label>
              <input
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register({ required: "Required", maxLength: { value: 200, message: "Name too long" } })}
                type="text"
                id="name"
                name="name"
                autoComplete="off"
                className={`form-input ${errors.name ? "invalid" : ""}`}
                defaultValue={team.name}
                placeholder="Name"
              />
              {errors.name && <div className="form-input-error">{errors.name.message}</div>}
            </div>
          </div>

          <div className="col-2">
            <div className="form-row">
              <label htmlFor="initials">initials (tricode)</label>
              <input
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register({ required: "Required", maxLength: { value: 4, message: "Initials too long" } })}
                type="text"
                id="initials"
                name="initials"
                autoComplete="off"
                className={`form-input ${errors.initials ? "invalid" : ""}`}
                defaultValue={team.initials}
                placeholder="Initials"
              />
              {errors.initials && <div className="form-input-error">{errors.initials.message}</div>}
            </div>
          </div>

          <div className="col-6">
            <div className="form-row">
              <label htmlFor="tagline">Tagline</label>
              <input
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register({ maxLength: { value: 50, message: "Tagline too long" } })}
                type="text"
                id="tagline"
                name="tagline"
                autoComplete="off"
                maxLength={50}
                className={`form-input ${errors.tagline ? "invalid" : ""}`}
                defaultValue={team.tagline}
                placeholder="Tagline"
              />
              {errors.tagline && <div className="form-input-error">{errors.tagline.message}</div>}
            </div>
          </div>

          <div className="col-4">
            <div className="form-row">
              <label htmlFor="email">Public Contact Email</label>
              <input
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register({
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                type="text"
                id="email"
                name="email"
                autoComplete="off"
                className={`form-input ${errors.email ? "invalid" : ""}`}
                defaultValue={team.email}
                placeholder="Organisation@mail.com"
              />
              {errors.email && <div className="form-input-error">{errors.email.message}</div>}
            </div>
          </div>

          <div className="col-2">
            <div className="form-row">
              <label htmlFor="nationality">Nationality</label>
              <select
                ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
                id="nationality"
                name="nationality"
                className={`form-input ${errors.nationality ? "invalid" : ""}`}
                defaultValue={team.nationality || "_DEFAULT_"}
              >
                <option value="_DEFAULT_" disabled>
                  Choose nationality
                </option>
                {nationalities.map(({ alpha2Code, demonym }) => (
                  <option key={alpha2Code} value={JSON.stringify({ code: alpha2Code, name: demonym })}>
                    {demonym}
                  </option>
                ))}
              </select>
              {errors.nationality && <div className="form-input-error">{errors.nationality.message}</div>}
            </div>
          </div>

          <div className="col-6">
            <div className="form-row">
              <label htmlFor="website">Website</label>
              <input
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register({
                  pattern: {
                    value: /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi,
                    message: "Invalid URL",
                  },
                })}
                type="text"
                id="website"
                name="website"
                autoComplete="off"
                className={`form-input ${errors.website ? "invalid" : ""}`}
                defaultValue={team.website}
                placeholder="Leagues.gg"
              />
              {errors.website && <div className="form-input-error">{errors.website.message}</div>}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="form-row">
              <label htmlFor="about">About</label>
              <RichEditor placeholder="Write something about the team" defaultValue={about} onChange={setAbout} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="form-row">
              <Checkbox onChange={() => setLogoCropped(!logoCropped)} checked={logoCropped} label="Logo Cropped" className="check-type1 logo-cropped-box" game={selectedGame.name} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12" style={{ display: "flex" }}>
            <div className="form-row" style={{ marginRight: 30 }}>
              <label htmlFor="logo">Logo</label>
              <div
                className={!logoCropped ? "upload-image-btn" : "upload-image-btn-cropped"}
                style={{ backgroundImage: `url(${team.logo})` }}
                onClick={() => setModals({ ...modals, logo: true })}
              >
                <RiEditLine />
              </div>
            </div>

            <EditImageModal
              name="Logo"
              width={250}
              height={250}
              borderRadius={250}
              open={modals.logo}
              onClose={() => setModals({ ...modals, logo: false })}
              logoCropped={(value) => {
                setLogoCropped(value);
                onLogoUpdate(value);
              }}
              afterImageUpload={(file) => onEditImage("logo", file)}
              afterImageUploadFullSize={(file) => onEditImage("originalLogo", file)}
            />

            <div className="form-row">
              <label htmlFor="logo">Banner</label>
              <div
                className="upload-image-btn"
                style={{ backgroundImage: `url(${team.banner})`, width: 800 }}
                onClick={() => setModals({ ...modals, banner: true })}
              >
                <RiEditLine />
              </div>
            </div>

            <EditImageModal
              name="Banner"
              width={800}
              height={200}
              crop={false}
              rotate={false}
              borderRadius={0}
              open={modals.banner}
              onClose={() => setModals({ ...modals, banner: false })}
              afterImageUpload={(file) => onEditImage("banner", file)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <button type="submit" className="button primary">
              Save
            </button>
          </div>
          <div className="col-6 text-right">
            <button type="button" className="button danger" onClick={onDeleteTeam}>
              Delete Team
            </button>
          </div>
        </div>
      </form>

      <DeleteTeamModal isOpen={showDeleteTeam} onClose={() => setShowDeleteTeam(false)} team={team} />
    </>
  );
};

export default EditTeam;
