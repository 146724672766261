import React, { useContext, useState, useRef } from "react";
// import { CreateOrgModalContext } from "../CreateTeamModal";
import { CreateOrgModalContext } from "../CreateOrgModal";
import DynamicSearch from "../../../_common/DynamicSearch/DynamicSearch";
import Axios from "axios";
import { RiCloseLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { AppContext } from "@context/AppContext";

const InvitePlayers = () => {
  const { orgData, setStep, /*onClose*/ } = useContext(CreateOrgModalContext);
  const timeout = useRef();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(false);
  const { user } = useContext(AppContext);

  const setSelectedUsersRole = (index,role) => {
    let memberData = selectedMembers;
    memberData[index].role = role;
    setSelectedMembers(memberData);
  }

  const addMember = (player) => {
    setResults(false);

    let roles = [];
    switch (player.type) {
      case "User":
        roles = ["Owner", "Manager"];
        break;
      case "Coach":
        roles = ["Coach"];
        break;
      default:
    }

    // setSelectedMembers([...selectedMembers, { ...player, role: roles[0], roles }]);
    setSelectedMembers([...selectedMembers, { ...player, role: "Owner" }]);
  };
  
  const onSearch = async (query) => {
    if (!query.length) return setResults([]);
    setLoading(true);

    clearTimeout(timeout.current);
    timeout.current = setTimeout(async () => {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/globalSearch`, {
        query,
        include: "users, players, coaches",
      });

      // Map data to required output, and remove already selected players
      const searchResults = data
        .filter(({ _id }) => !selectedMembers.map(({ _id }) => _id).includes(_id))
        .filter(({ _id }) => user && user.state?._id !== _id)
        .map(({ _id, img, name, user, type }) => ({
          _id,
          type,
          img,
          title: name,
          subtitle: user ? `${user.firstName} ${user.lastName}` : false,
        }));

      setResults(searchResults);
      setLoading(false);
    }, 500);
  };

  const onAddOwner = async () => {
    if (!selectedMembers) return false;

    try {
      // await Axios.post(`${process.env.REACT_APP_CORE_API}/api/organisations/${orgData._id}/owners/addOwner`, selectedUser);
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/organisations/invites`, {
        members: selectedMembers,
        organisation: orgData._id,
      });
      setStep(2);
    } catch (e) {
      const msg = e.response && e.response.data ? e.response.data.msg : "Couldn't add owner";
      toast.error(msg);
    }
  };

  return (
    <div>
      <div className="modal-header">
        <h2>
          Invite members to <strong>{orgData.name}</strong>
        </h2>
        <h3>Grow your team by inviting players, coaches, managers, and owners.</h3>
      </div>
      <div className="modal-body">
        <div className="add-players">
          <p className="ap-title">Add Members</p>

          <DynamicSearch
            placeholder="Search for team members"
            onSearch={onSearch}
            onSelectResult={addMember}
            // onSelectResult={(data) => setSelectedUser({ ...data, role: "Owner" })}
            loadingResults={loading}
            results={results}
            mixedResults={true}
          />
        <div className="dynamic-search-selected-items">
          {selectedMembers && selectedMembers.map((selectedUserd,i) => (
            <div className="selected-item">
              <div className="item-details">
                <div className="item-img" style={{ backgroundImage: `url(${selectedUserd.img})` }} />
                <p className="item-name">{selectedUserd.title}</p>
              </div>
              <select
                name="role"
                id="role"
                className="form-input"
                // value={selectedUserd.role}
                onChange={(e) => setSelectedUsersRole(i,e.target.value)}
                // onChange={(e) => setSelectedUser({ ...selectedUserd, role: e.target.value })}
              >
                {["Owner", "Manager"].map((role) => (
                  <option value={role}>{role}</option>
                ))}
              </select>
              <button onClick={() => setSelectedMembers(selectedMembers.filter((val,key) => key !== i))}>
                <RiCloseLine size={27} />
              </button>
            </div>
          ))
          }
        </div>


          <div className="next-buttons">
            <button className="button transparent" onClick={() => setStep(2)}>
              Skip
            </button>
            <button className="button primary" onClick={onAddOwner} disabled={!selectedMembers.length}>
              Add owner or manager
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvitePlayers;
