import React, { useState, useEffect } from "react";
import moment from "moment";
import { Icon } from '@common';
import { makeCountdownString } from "@utils";

const Timer = ({ time, onTimeComplete, children, fontSize, style, name }) => {
  const [timeRemaining, setTimeRemaining] = useState(moment(time).diff(moment(), "milliseconds"));
  useEffect(() => {
    setTimeout(() => {
      let timeDiff = moment(time).diff(moment(), "milliseconds");
      if (timeDiff <= 0) {
        setTimeRemaining(null);
        onTimeComplete && onTimeComplete();
      } else setTimeRemaining(timeDiff);
    }, 1000);
  }, [timeRemaining]);
  useEffect(() => {
    let timeDiff = moment(time).diff(moment(), "milliseconds");
    setTimeRemaining(timeDiff);
  }, []);
  return (
    <div className="veto-countdown row align-items-center p-0 m-0" style={{ fontSize: fontSize || 16, ...style }}>
      <Icon name={name || "clock"} />
      {makeCountdownString(timeRemaining, true)}
      <span className="pl-1">{children}</span>
    </div>
  );
};

export default Timer
