import React, { useRef, useState, useEffect } from "react";
import { Button } from "@common";
import { Tabs } from "antd";

const SimpleTabs = ({ page, setPage, pages }) => {
  const containerRef = useRef(null);
  const elemRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    setIsScrollable(containerRef.current.scrollWidth > containerRef.current.clientWidth);
  });

  return (
    <div className="tabs-container simple__tabs__container">
      <div ref={containerRef} className="tabs-nav mt-4 mb-4 sub-tabs">
        <nav ref={elemRef}>
          {pages.map((item, index) => (
            <button className={page === index ? "active" : ""} onClick={() => setPage(index)} key={index}>
              {item}
            </button>
          ))}
          {/* <div className="ellipses-btn">{isScrollable && <button onClick={() => {}}>{"..."}</button>}</div> */}
        </nav>
      </div>
    </div>
  );
};

export default SimpleTabs;
