import React from "react";
import { MirrorText, SimplePageWithTabs } from "@common";
import NewsTab from "./NewsTab/NewsTab";

const News = () => {
  const tabs = {
    All: <NewsTab />,
    Announcements: <NewsTab filter="tag:announcement" />,
    Leagues: <NewsTab filter="tag:league" />,
    Tournaments: <NewsTab filter="tag:tournament" />,
  };

  return (
    <>
      <SimplePageWithTabs
        title="News"
        tabs={tabs}
        top_right={
          <React.Fragment>
            <MirrorText text={["L4t3st esport n3ws"]} is_static />
          </React.Fragment>
        }
        tabs_props={{
          show_filter: true,
          custom_filter: (
            <React.Fragment>
              <span className="text-12">FITLER BY:</span>
              <select className="form-input">
                <option>Recent news</option>
              </select>
            </React.Fragment>
          ),
          base_route: "/news",
        }}
      />
    </>
  );
};

export default News;
