import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../context/AppContext";
import { Card, Icon } from "@common";
import moment from "moment";
import EditRoleModal from "./EditRoleModal";
import ReactTooltip from "react-tooltip";
import { RiSkullLine } from "react-icons/ri";
import { getGameBackground } from "../../../../utils/additional-helpers.js";
import { ReactComponent as DiscordIcon } from "@img/discord-icon.svg";
import { ReactComponent as ExternalLink } from "@img/external-primary.svg";

const Overview = ({ player, setPlayer, totalWin = 0, totalLoss = 0 }) => {
  const { user: authUser } = useContext(AppContext);
  const { lolData = {}, user = {}, game, primaryRole = "Role not found", secondaryRole = "Role not found" } = player;

  if (!lolData) {
    return null;
  }

  const rankedSolo = (lolData.leagueEntries || []).find(({ queueType }) => queueType === "RANKED_SOLO_5x5");
  const rankedSoloWinRate = rankedSolo ? Math.round((rankedSolo.wins / (rankedSolo.wins + rankedSolo.losses)) * 100) : "No rank found";

  const getLaneIcon = (role) => {
    const data = game.configuration.playerTypes.find(({ name }) => role.includes(name));

    return data ? data.icon : false;
  };

  const HIGHLIGHTS = [
    {
      icon: game.name === "CS:GO" ? <RiSkullLine className="faded" size={`1.5em`} /> : <Icon name="game-icon" />,
      title: rankedSolo ? `${rankedSolo.tier} ${rankedSolo.rank}` : "No rank found",
      subtitle: game.name === "CS:GO" ? "Leagues.gg Rating" : "Rank (Ranked Solo)",
      tooltip: game.name === "CS:GO" ? "From the last 10 games." : false,
    },
    {
      icon: <Icon name="trophy-icon" />,
      title: rankedSolo ? `${rankedSoloWinRate}% WR (${rankedSolo.wins}W ${rankedSolo.losses}L)` : "No rank found",
      subtitle: game.name === "CS:GO" ? "K-D Score" : "Ranked Solo",
      tooltip: game.name === "CS:GO" ? "From the last 10 games." : false,
    },
    {
      icon: <Icon name="trophy-icon" />,
      title: `${Math.round((totalWin / (totalWin + totalLoss)) * 100)}% WR (${totalWin}W ${totalLoss}L)`,
      title:
        totalWin === 0 && totalLoss === 0
          ? `0% WR (0W 0L)`
          : `${Math.round((totalWin / (totalWin + totalLoss)) * 100)}% WR (${totalWin}W ${totalLoss}L)`,
      subtitle: "Leagues.gg",
    },
    {
      icon: <Icon name="globe-icon" />,
      title: game.name === "CS:GO" ? "CS:GO" : "LOL, EU WEST",
      subtitle: game.name === "CS:GO" ? "Game" : "Game & Region",
    },
    primaryRole !== "Role not found" && {
      icon: <img src={getLaneIcon(primaryRole)} alt={primaryRole} width={18} />,
      title: (
        <>
          {primaryRole}
          {user._id === authUser.state._id && (
            <EditRoleModal
              type="primary"
              playerId={player._id}
              game={game}
              currentRole={primaryRole}
              afterUpdate={(newRole) => setPlayer({ ...player, primaryRole: newRole })}
            />
          )}
        </>
      ),
      subtitle: "Preferred Role",
    },
    secondaryRole !== "Role not found" && {
      icon: <img src={getLaneIcon(secondaryRole)} alt={secondaryRole} width={18} />,
      title: (
        <>
          {secondaryRole}
          {user._id === authUser.state._id && (
            <EditRoleModal
              type="secondary"
              playerId={player._id}
              game={game}
              currentRole={secondaryRole}
              afterUpdate={(newRole) => setPlayer({ ...player, secondaryRole: newRole })}
            />
          )}
        </>
      ),
      subtitle: "Secondary Role",
    },
  ];

  return (
    <div>
      <div className="row smaller-gutter-row">
        <div className="col-3 row-title">
          <span className="faded bold uppercase">About</span>
        </div>
        <div className="col-9">
          <div className="row smaller-gutter-row">
            {HIGHLIGHTS.map(
              ({ icon, title, subtitle, tooltip = false }, key) =>
                title && (
                  <div data-tip data-for={`tip_${key}`} className="col-4 pt-3 pb-3">
                    <Card icon={icon} title={title} subtitle={subtitle} />
                    {tooltip && (
                      <ReactTooltip id={`tip_${key}`}>
                        <span>{tooltip}</span>
                      </ReactTooltip>
                    )}
                  </div>
                )
            )}
          </div>

          <div className="row smaller-gutter-row pb-3 pt-2">
            <div className="col-12">
              {user.discord && (
                <a href="https://discord.com/" className="primary-bg-link" style={{ backgroundColor: getGameBackground() }}>
                  <span>
                    <Icon pureIcon={<DiscordIcon />} classes="fill-primary" />
                    {user.discord}
                  </span>
                  <Icon pureIcon={<ExternalLink />} classes="fill-primary" />
                </a>
              )}
            </div>
          </div>

          <div className="row smaller-gutter-row">
            <div className="col-3 pt-2 pb-2">
              <div className="text-14 bold uppercase">
                <img
                  src={`https://flagcdn.com/w20/${user.nationality.code?.toLowerCase()}.png`}
                  className="flag"
                  alt={user.nationality.code}
                />
                {user.nationality.name}
              </div>
              <div className="text-14 faded">Country</div>
            </div>
            <div className="col-2 pt-2 pb-2">
              <div className="text-14 bold uppercase">{user.gender}</div>
              <div className="text-14 faded">Gender</div>
            </div>
            <div className="col-2 pt-2 pb-2">
              <div className="text-14 bold uppercase">{moment().diff(moment(user.birthday), "years") + " years old"}</div>
              <div className="text-14 faded">Age</div>
            </div>
          </div>
        </div>
      </div>
      {/* <hr />
      <div className="row smaller-gutter-row player-overview-row">
        <div className="col-3 row-title">
          <span className="faded bold uppercase">BIO</span>
        </div>
        <div className="col-9 row-content">
          <p>
            Im a midmain and have played the game for nearly 10 years. The past 6 seasons, i have been plat or above and the past 2 seasons
            i have been a diamond player jumping around the different divisions. Prefer playing control mages, but can also do assassins if
            it suits the comp. Im a flexible player, but still open to learn new champions if needed. Furthermore i got a decent amount of
            team experience from playing on my last team (VHE). Highest peek ever: D1 My op.gg:
            https://euw.op.gg/summoner/userName=Legenden%20Lalle
          </p>
          <div className="social-links">
            <a href="/#">
              <img src={twitch} alt="Twitch" />
            </a>
            <a href="/#">
              <img src={facebook} alt="Facebook" />
            </a>
            <a href="/#">
              <img src={twitter} alt="Twitter" />
            </a>
            <a href="/#">
              <img src={instagram} alt="Instagram" />
            </a>
            <a href="/#">
              <img src={youtube} alt="Youtube" />
            </a>
          </div>
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default Overview;
