import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useHistory, useParams } from "react-router-dom";
import { Loading } from "@common";

const DynamicPage = () => {
  const { slug } = useParams();
  const history = useHistory();
  const { ghost } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState({});

  useEffect(() => {
    ghost.pages
      .read({ slug: slug })
      .then((page) => {
        setPage(page);
        setLoading(false);
      })
      .catch((err) => {
        history.push("/error");
      });
  }, [ghost, slug, history]);

  return loading ? (
    <Loading />
  ) : (
    page && (
      <div className="container-fluid news-single">
        <div className="row">
          <div className="col-12 news-single-content-wrapper">
            <h1 className="news-heading center">{page.title}</h1>

            <div className="news-single-content ghost-content" dangerouslySetInnerHTML={{ __html: page.html }}></div>
          </div>
        </div>
      </div>
    )
  );
};

export default DynamicPage;
