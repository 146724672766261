import React from "react";
import youtube from "@img/youtube-round.svg";
import facebook from "@img/facebook-round.svg";
import twitter from "@img/twitter-round.svg";
import twitch from "@img/twitch-round.svg";
import instagram from "@img/instagram-round.svg";

const Contact = ({ email, phone, phoneExt, instagramLink, facebookLink, twitchLink, twitterLink, youtubeLink }) => {
  return (
    <div className="narrow-content teams-content">
      <h1>Contact Us</h1>

      {/* <p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
				ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
			</p>
			<p>
				Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
				cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
			</p> */}

      <div className="row pt-4 pb-4">
        {email && (
          <div className="col-6">
            <div className="color-primary text-14 bold uppercase">{email}</div>
            <div className="text-14 faded">E-mail</div>
          </div>
        )}
        {phone && (
          <div className="col-6">
            <div className="color-primary text-14 bold uppercase">
              {phoneExt} {phone}
            </div>
            <div className="text-14 faded">Phone</div>
          </div>
        )}
      </div>

      <div className="social-links pt-3">
        {twitchLink && (
          <a href={twitchLink}>
            <img src={twitch} alt="Twitch" />
          </a>
        )}
        {facebookLink && (
          <a href={facebookLink}>
            <img src={facebook} alt="Facebook" />
          </a>
        )}
        {twitterLink && (
          <a href={twitchLink}>
            <img src={twitter} alt="Twitter" />
          </a>
        )}
        {instagramLink && (
          <a href={instagramLink}>
            <img src={instagram} alt="Instagram" />
          </a>
        )}
        {youtubeLink && (
          <a href={youtubeLink}>
            <img src={youtube} alt="Youtube" />
          </a>
        )}
      </div>
    </div>
  );
};

export default Contact;
