import React, { useState, useRef } from "react";
import Modal from "react-modal";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import Axios from "axios";
import { toast } from "react-toastify";
import { RiCloseLine, RiArrowGoBackLine, RiArrowGoForwardLine } from "react-icons/ri";
import { useEffect } from "react";
import Loading from "../../_common/Loading/Loading";

const EditImageModal = ({
  name,
  width,
  height,
  crop = true,
  rotate = true,
  borderRadius,
  open,
  onClose,
  afterImageUpload,
  logoCropped,
  afterImageUploadFullSize,
}) => {
  const editor = useRef();
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);
  const [imgCrop, setImgCrop] = useState(true);

  function isTransparent(e) {
    console.log('isTransparent E: ', e)
    let target = e.target || e.srcElement;
    console.log('target', target)
    if(!target) {
      setImgCrop(false);
      return
    }

    const width = target.naturalWidth
    const height = target.naturalHeight

    let topX = 0.5,
      topY = 0.5;

    let topXX = width - 0.5,
      topYY = topY;

    let bottomX = topX,
      bottomY = height - 0.5;

    let bottomXX = width - 0.5,
      bottomYY = height - 0.5;

    let canvas = (function (_this) {
      let e = document.createElement("canvas");
      e.setAttribute("width", _this.width);
      e.setAttribute("height", _this.height);
      e.setAttribute("id", _this.id + "-canvas");
      e.setAttribute("style", "display:none;");
      document.body.appendChild(e);
      let cx = e.getContext("2d");
      cx.drawImage(_this, 0, 0, _this.width, _this.height);
      return e;
    })(this);

    if (canvas.getContext === undefined) {
      setImgCrop(true);
      return;
    }
    let ctx = canvas.getContext("2d");

    if (ctx.getImageData(topX, topY, 1, 1).data[3] == 0 && ctx.getImageData(topXX, topYY, 1, 1).data[3] == 0) {
      setImgCrop(false);
      return;
    }

    if (ctx.getImageData(bottomX, bottomY, 1, 1).data[3] == 0 && ctx.getImageData(bottomXX, bottomYY, 1, 1).data[3] == 0) {
      setImgCrop(false);
      return;
    }

    if (ctx.getImageData(bottomXX, bottomYY, 1, 1).data[3] == 0 && ctx.getImageData(topX, topY, 1, 1).data[3] == 0) {
      setImgCrop(false);
      return;
    }

    if (ctx.getImageData(ctx.getImageData(topXX, topYY, 1, 1).data[3] == 0 && bottomX, bottomY, 1, 1).data[3] == 0) {
      setImgCrop(false);
      return;
    }

    if (ctx.getImageData(topX, topY, 1, 1).data[3] == 0 && ctx.getImageData(bottomX, bottomY, 1, 1).data[3] == 0) {
      setImgCrop(false);
      return;
    }

    if (ctx.getImageData(ctx.getImageData(topXX, topYY, 1, 1).data[3] == 0 && bottomXX, bottomYY, 1, 1).data[3] == 0) {
      setImgCrop(false);
      return;
    }

    setImgCrop(true);
  }

  const handleDrop = (dropped) => {
    // Get image from drop
    console.log('dropped', dropped)
    const image = dropped[0];
    const imageObj = new Image();
    imageObj.src = window.URL.createObjectURL(image);

    imageObj.addEventListener("load", isTransparent, true);

    imageObj.onload = () => {
      const imageSize = image && image.size;
      const imageWidth = imageObj.width;
      const imageHeight = imageObj.height;

      // Is image less than 8MB?
      if (imageSize > 8000000) return toast.error("File must be smaller than 8MB");

      // Is image the minimum dimensions?
      if (imageWidth < width || imageHeight < height) return toast.error(`File must be at least ${width}px x ${height}px in size`);

      setImage(dropped[0]);
    };
  };

  const clearImage = () => {
    setImage(false);
    setImgCrop(true);
    setUploading(false);
  };

  const handleScale = (e) => setScale(parseFloat(e.target.value));
  const rotateLeft = () => setRotation(rotation - 90);
  const rotateRight = () => setRotation(rotation + 90);

  useEffect(() => {
    clearImage();
    setImgCrop(true);
    setUploading(false);
  }, [open]);

  useEffect(() => {
    setRotation(0);
    setScale(1);
  }, [image]);

  const onClickSave = () => {
    setUploading(true);

    const canvas = !crop ? editor.current.getImage() : editor.current.getImageScaledToCanvas();

    canvas.toBlob((blob) => {
      const file = new File([blob], `${name}-${width}-${height}.png`, {
        type: "image/png",
      });

      const formData = new FormData();
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      formData.append("image", file);

      Axios.post(`${process.env.REACT_APP_CORE_API}/api/upload`, formData, config)
        .then(({ data }) => {
          afterImageUpload(data.file);
          if (logoCropped) {
            logoCropped(imgCrop);
          }
        })
        .catch((err) => {
          const msg = err.response.data ? err.response.data.msg : "There was a problem uploading your file";
          toast.error(msg);
          setUploading(false);
        });
    });

    if (afterImageUploadFullSize) {
      const file = new File([image], `${name}.png`, {
        type: "image/png",
      });

      const formData = new FormData();
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      formData.append("image", file);

      Axios.post(`${process.env.REACT_APP_CORE_API}/api/upload`, formData, config).then(({ data }) => {
        afterImageUploadFullSize(data.file);
      });
    }
  };

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      style={{ content: { width: width + 50 } }}
      className="modal edit-image-modal"
      contentLabel="Example Modal"
    >
      <div className="modal-close" onClick={onClose}>
        <RiCloseLine />
      </div>
      <div className="modal-header">
        <h2>Upload {name}</h2>
      </div>
      <div className="edit-image-body">
        {image ? (
          <div>
            <AvatarEditor
              ref={editor}
              width={width}
              height={height}
              image={image}
              scale={scale}
              rotate={rotation}
              borderRadius={borderRadius}
            />

            <div className="edit-image-controls">
              {rotate && (
                <button onClick={rotateLeft}>
                  <RiArrowGoBackLine />
                </button>
              )}
              <input
                name="scale"
                type="range"
                className="form-input"
                value={scale}
                onChange={handleScale}
                min="1"
                max="2"
                step="0.01"
                defaultValue="1"
              />
              {rotate && (
                <button onClick={rotateRight}>
                  <RiArrowGoForwardLine />
                </button>
              )}
            </div>

            <div className="save-buttons">
              <button className="button transparent" onClick={clearImage}>
                Restart
              </button>
              <button className="button primary" onClick={onClickSave} disabled={uploading}>
                {uploading ? <Loading /> : "Upload"}
              </button>
            </div>
          </div>
        ) : (
          <Dropzone multiple={false} accept={["image/jpeg", "image/jpg", "image/png", "image/gif"]} onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <div className="image-dropzone" {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drag and drop or click to upload image</p>
              </div>
            )}
          </Dropzone>
        )}
      </div>
    </Modal>
  );
};

// accessibility
Modal.setAppElement("#root");

export default EditImageModal;
