import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "@context/AppContext";
import { Icon } from "@common";
import CreatePlayerModal from "../_modals/CreatePlayerModal/CreatePlayerModal";
import CreateOrgModal from "../_modals/CreateOrgModal/CreateOrgModal";
import CreateCoachModal from "../_modals/CreateCoachModal/CreateCoachModal";

const Welcome = () => {
  const { user } = useContext(AppContext);
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const [showOrgModal, setShowOrgModal] = useState(false);
  const [showCoachModal, setShowCoachModal] = useState(false);

  return (
    <div className="registration-welcome">
      <CreatePlayerModal open={showPlayerModal} onClose={() => setShowPlayerModal(false)} />
      <CreateOrgModal open={showOrgModal} onClose={() => setShowOrgModal(false)} afterCreate={() => false} />
      <CreateCoachModal open={showCoachModal} onClose={() => setShowCoachModal(false)} />

      <div className="modal modal-inline on-page">
        <div className="modal-header">
          <h2>Welcome to Leagues, {user.state.firstName}!</h2>
          <h3>Choose what to do with your new profile. All of these actions can be done later as well.</h3>
        </div>

        <div className="modal-body">
          <div className="text-14 bold uppercase pb-2 left">Choose what to create</div>
          {/* Player */}
          <div className="account-type-item" onClick={() => setShowPlayerModal(true)}>
            <Icon name="game-welcome" />
            <div className="account-type-item-text">
              <div className="text-12 bold uppercase left">Player</div>

              <div className="text-10 faded left">
                Players can participate in leagues and tournaments, and can look for teams using the Finder.
              </div>
            </div>
          </div>
          {/* Organisation */}
          <div className="account-type-item" onClick={() => setShowOrgModal(true)}>
            <Icon name="org-welcome" />
            <div className="account-type-item-text">
              <div className="text-12 bold uppercase left">Organisation</div>

              <div className="text-10 faded left">
                This is for owners of organisations. To create and manage teams you will need to have an organisation associated.
              </div>
            </div>
          </div>
          {/* Coach */}
          <div className="account-type-item" onClick={() => setShowCoachModal(true)}>
            <Icon name="coach-welcome" />
            <div className="account-type-item-text">
              <div className="text-12 bold uppercase left">Coach</div>

              <div className="text-10 faded left">
                Coaches can join and look for teams just like players, but different information is shown on the profile.
              </div>
            </div>
          </div>
          <div className="text-10 faded" style={{ marginTop: "18px" }}>
            You can always skip this step and choose what to do later when needed.
          </div>

          <div className="button-container">
            <Link to="/" className="text-12 primary-link">
              Remind me later
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
