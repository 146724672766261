import React, { useState } from "react";
import { Tabs } from "@common";

const SimplePageWithTabs = ({ title, type, children, tabs, top_right, tabs_props, buttonProps, currentPage, frontpage, isTeams, page }) => {
  const [nextPage, setNextPage] = useState(currentPage ? currentPage : 0);

  return (
    <div className={"container-fluid simple-page-with-tabs " + type}>
      <div className="row">
        <div className="col-12">
          <div
            style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: buttonProps ? "44px" : "0px" }}
          >
            <h1>{title ?? "Lorem Ipsum"}</h1>
            <span style={{ display: "flex", marginTop: "8px" }}>{top_right}</span>
          </div>
          {buttonProps && (
            <button className={`transparentButton ${page == "YourMatches" && "your-matches"}`} type="button" onClick={() => setNextPage(1)}>
              {buttonProps}
            </button>
          )}
          {children ?? <Tabs pages={tabs} {...tabs_props} nextPage={nextPage} setNextPage={setNextPage} frontpage isTeams={isTeams} />}
        </div>
      </div>
    </div>
  );
};

export default SimplePageWithTabs;
