import React, { useContext, useState, useRef } from "react";
import { CreateTeamModalContext } from "../CreateTeamModal";
import DynamicSearch from "../../../_common/DynamicSearch/DynamicSearch";
import Axios from "axios";
import { RiCloseLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { AppContext } from "../../../../context/AppContext";

const InvitePlayers = () => {
  const { teamData, setStep } = useContext(CreateTeamModalContext);
  const timeout = useRef();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [inviting, setInviting] = useState(false);

  const onSearch = async (query) => {
    if (!query.length) return setResults([]);
    setLoading(true);

    let selectedGameId = null;
    if(teamData){
      selectedGameId = teamData.game._id;
    }

    clearTimeout(timeout.current);
    timeout.current = setTimeout(async () => {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/globalSearch`, {
        query,
        include: "users, players, coaches",
        selectedGameId
      });

      // Map data to required output, and remove already selected players
      const searchResults = data
        .filter(({ _id }) => !selectedMembers.map(({ _id }) => _id).includes(_id))
        .map(({ _id, img, name, user, type }) => ({
          _id,
          type,
          img,
          title: name,
          subtitle: user ? `${user.firstName} ${user.lastName}` : false,
        }));

      setResults(searchResults);
      setLoading(false);
    }, 500);
  };

  const addMember = (player) => {
    setResults(false);

    const gamePlayerTypes = teamData.game.configuration.playerTypes.map(({ name }) => "Player:" + name);

    let roles = [];
    switch (player.type) {
      case "User":
        roles = ["Manager", "Owner"];
        break;
      case "Player":
        roles = gamePlayerTypes;
        break;
      case "Coach":
        roles = ["Coach"];
        break;
      default:
    }

    setSelectedMembers([...selectedMembers, { ...player, role: roles[0], roles }]);
  };

  const editMember = (playerId, role) => {
    setSelectedMembers(selectedMembers.map((player) => (player._id === playerId ? { ...player, role } : player)));
  };

  const removeMember = (idToRemove) => {
    setSelectedMembers(selectedMembers.filter(({ _id }) => _id !== idToRemove));
  };

  // actually attempt to invite players and move to next step
  const inviteMembers = async () => {
    setInviting(true);

    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/teams/invites`, {
        members: selectedMembers,
        team: teamData._id,
      });
      setStep(2);
    } catch (e) {
      toast.error("There was a problem inviting selected members");
    }

    setInviting(false);
  };

  return (
    <div>
      <div className="modal-header">
        <h2>
          Invite members to <strong>{teamData.name}</strong>
        </h2>
        <h3>Grow your team by inviting players, coaches, managers, and owners.</h3>
      </div>
      <div className="modal-body">
        <div className="add-players">
          <p className="ap-title">Add Members</p>

          <DynamicSearch
            placeholder="Search for team members"
            onSearch={onSearch}
            onSelectResult={addMember}
            loadingResults={loading}
            results={results}
            mixedResults={true}
          />

          <div className="dynamic-search-selected-items">
            {selectedMembers.map(({ _id, img, title, subtitle, role, roles }) => (
              <div key={_id} className="selected-item">
                <div className="item-details">
                  <div className="item-img" style={{ backgroundImage: `url(${img})` }} />
                  <div className="item-name-multi">
                    <p>{title}</p>
                    {subtitle && <small>{subtitle}</small>}
                  </div>
                </div>
                <select name="role" id="role" className="form-input" value={role} onChange={(e) => editMember(_id, e.target.value)}>
                  {roles.map((role) => {
                    const roleSplit = role.split(":");
                    const roleStr = roleSplit[1] || roleSplit[0];

                    return <option value={role}>{roleStr}</option>;
                  })}
                </select>
                <button onClick={() => removeMember(_id)}>
                  <RiCloseLine size={27} />
                </button>
              </div>
            ))}
          </div>

          <div className="next-buttons">
            <button className="button transparent" onClick={() => setStep(2)}>
              Skip
            </button>
            <button className="button primary" onClick={inviteMembers} disabled={inviting || selectedMembers.length < 1}>
              {inviting ? "Inviting Members..." : "Invite Members"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvitePlayers;
