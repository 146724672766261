// import moment from "moment-timezone/builds/moment-timezone-with-data";
import { isEqual } from "lodash";
import moment from "moment-timezone/builds/moment-timezone-with-data";

export const makeCountdownString = (millis, check = false) => {
  const _seconds = Math.round(millis / 1000);
  const _mins = Math.floor(_seconds / 60);
  const _hours = Math.floor(_mins / 60);
  const _days = Math.floor(_hours / 24);
  let seconds = Math.round(_seconds % 60);
  let mins = Math.round(_mins % 60);
  let hours = Math.round(_hours % 24);
  let days = Math.round(_days);
  seconds = String(seconds).length < 2 ? "0" + seconds : String(seconds);
  mins = String(mins).length < 2 ? "0" + mins : String(mins);
  hours = String(hours).length < 2 ? "0" + hours : String(hours);
  days = String(days);
  return check ? `${days > 0 ? `${days}:` : ""}${hours > 0 ? `${hours}:` : ""}${mins}:${seconds}` : `${days}:${hours}:${mins}:${seconds}`;
};

export const getTimeString = (date, timeZone) => {
  let now = moment();
  now = timeZone ? now.tz(timeZone) : now;
  const _date = applyBrowserTimezone(date, timeZone);
  // var duration = _date.diff(now, 'days');

  if (now.format("DD") === _date.format("DD")) {
    return `Today`;
  } else if (now.add("1", "days").format("DD") === _date.format("DD")) {
    return `Tomorrow`;
  } else {
    return `${moment(_date).format("MM/DD-YYYY")}`;
  }
};

export const timeLeft = (liveTimer) => {
  return liveTimer.months() == 0
    ? `${liveTimer.days() > 0 ? `${pad2(liveTimer.days())}:` : ""}${liveTimer.hours() > 0 ? `${pad2(liveTimer.hours())}:` : ""}${pad2(
        liveTimer.minutes()
      )}:${pad2(liveTimer.seconds())}`
    : "";
};

export const pad2 = (n) => {
  return n < 10 ? "0" + n : n;
};

export const getCurrentIsoTime = () => {
  // let d = new Date(new Date().setMonth(7)); //remove this line
  return new Date(new Date().toDateString()).toISOString(); //uncomments this
  // return new Date(d.toDateString()).toISOString(); //remove this line
};

export const getMatchGroupDate = (date) => {
  const tempDate = new Date(date);
  let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  let weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  let groupDate = {};
  groupDate.day = weekdays[tempDate.getDay()];
  groupDate.month = months[tempDate.getMonth()];
  groupDate.date = tempDate.getDate();
  groupDate.year = tempDate.getFullYear();

  return groupDate;
};

export const isCustomDateMatch = (custom, date) => {
  return isEqual(custom, getMatchGroupDate(date));
};

export const getCurrentTimeZone = () => {
  let zone = moment.tz(moment.tz.guess()).zoneName();
  return zone;
};

export const isMatchLive = (date, bestOf) => {
  let currentDate = new Date();
  let matchStart = new Date(date);
  let matchEnd = new Date(date);
  matchEnd.setTime(matchEnd.getTime() + bestOf * 60 * 60 * 1000);
  if (currentDate.getTime() >= matchStart.getTime() && currentDate.getTime() < matchEnd.getTime()) return true;
  return false;
};
export const scrimCheckinToMins = (checkInPeriod) => {
  let time = checkInPeriod.split(" ")[0];
  const unit = checkInPeriod.split(" ")[1];
  if (unit == "Hour" || unit == "Hours") time = time * 60;
  return time;
};

export const applyBrowserTimezone = (datetime, timezone = null) => {
  try {
    // console.log(window.localStorage.getItem("timezone"));
    const localTimezone =
      window.localStorage.getItem("timezone") !== "undefined" &&
      window.localStorage.getItem("timezone") !== undefined &&
      window.localStorage.getItem("timezone") !== null &&
      window.localStorage.getItem("timezone") !== ""
        ? window.localStorage.getItem("timezone")
        : moment.tz.guess();
    // console.log("localTimezone", localTimezone);
    const preferedTimezone = !!timezone ? timezone : localTimezone;
    return moment.tz(datetime, preferedTimezone);
  } catch (err) {
    console.log(err);
    return moment.tz(datetime, moment.tz.guess());
  }
};
