import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { RichEditor } from "../../../_common/RichEditor/RichEditor";
import Axios from "axios";
import { Checkbox } from "@common";
import { toast } from "react-toastify";
import { RiEditLine } from "react-icons/ri";
import EditImageModal from "../../../_modals/EditImageModal/EditImageModal";
import DeleteOrgModal from "./DeleteOrgModal/DeleteOrgModal";

const EditOrganisation = ({ organisation, setOrganisation }) => {
  const { handleSubmit, register, errors } = useForm();
  const [logoCropped, setLogoCropped] = useState(organisation.logoCropped === undefined ? true : organisation.logoCropped);

  const [about, setAbout] = useState(organisation.about || "");
  const [modals, setModals] = useState({
    logo: false,
    banner: false,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onEditImage = async (type, image) => {
    try {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/organisations/${organisation._id}/image`, { type, image });
      const modalConfig = type === "originalLogo" ? { ...modals, ["logo"]: false } : { ...modals, [type]: false };
      setModals(modalConfig);
      setOrganisation({ ...organisation, logo: data.updatedOrganisation.logo, banner: data.updatedOrganisation.banner });
    } catch (err) {
      toast.error("There was a problem updating your organisation's images");
    }
  };

  const onDeleteOrg = () => {
    setShowDeleteModal(true);
  };

  const onSubmit = async (data) => {
    try {
      await Axios.put(`${process.env.REACT_APP_CORE_API}/api/organisations/${organisation._id}`, { ...data, about, logoCropped });
      toast.success("Saved");
    } catch (e) {
      let error = e.response.data ? e.response.data.msg : "There was a problem saving";
      toast.error(error);
    }
  };

  const onLogoUpdate = async (value) => {
    try {
      let body = {
        name: organisation.name,
        email: organisation.email,
        logoCropped: value,
        about: organisation.about,
      };
      await Axios.put(`${process.env.REACT_APP_CORE_API}/api/organisations/${organisation._id}`, { ...body });
    } catch (e) {
      const error = e.response.data ? e.response.data.msg : "There was a problem saving";
      toast.error(error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="account-settings-form">
        <div className="row">
          <div className="col-6">
            <div className="form-row">
              <label htmlFor="name">Name</label>
              <input
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register({ required: "Required", maxLength: { value: 30, message: "Name too long" } })}
                type="text"
                id="name"
                name="name"
                autoComplete="off"
                maxLength={30}
                className={`form-input ${errors.name ? "invalid" : ""}`}
                defaultValue={organisation.name}
                placeholder="Name"
              />
              {errors.name && <div className="form-input-error">{errors.name.message}</div>}
            </div>
          </div>

          <div className="col-6">
            <div className="form-row">
              <label htmlFor="tagline">Tagline</label>
              <input
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register({ maxLength: { value: 50, message: "Tagline too long" } })}
                type="text"
                id="tagline"
                name="tagline"
                autoComplete="off"
                maxLength={50}
                className={`form-input ${errors.tagline ? "invalid" : ""}`}
                defaultValue={organisation.tagline}
                placeholder="Tagline"
              />
              {errors.tagline && <div className="form-input-error">{errors.tagline.message}</div>}
            </div>
          </div>

          <div className="col-6">
            <div className="form-row">
              <label htmlFor="email">Public Contact Email</label>
              <input
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register({
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                type="text"
                id="email"
                name="email"
                autoComplete="off"
                className={`form-input ${errors.email ? "invalid" : ""}`}
                defaultValue={organisation.email}
                placeholder="Organisation@mail.com"
              />
              {errors.email && <div className="form-input-error">{errors.email.message}</div>}
            </div>
          </div>

          <div className="col-6">
            <div className="form-row">
              <label htmlFor="birthdayDay">Public Phone Number</label>
              <div className="row sm-gutters">
                <div className="col-2">
                  <select
                    name="phoneExt"
                    id="phoneExt"
                    className="form-input"
                    ref={register()}
                    defaultValue={organisation.phoneExt || "+45"}
                  >
                    {[
                      "+30",
                      "+31",
                      "+32",
                      "+33",
                      "+34",
                      "+36",
                      "+39",
                      "+40",
                      "+43",
                      "+44",
                      "+45",
                      "+46",
                      "+47",
                      "+48",
                      "+49",
                      "+350",
                      "+351",
                      "+352",
                      "+353",
                      "+354",
                      "+356",
                      "+357",
                      "+358",
                      "+359",
                      "+370",
                      "+371",
                      "+372",
                      "+385",
                      "+386",
                      "+420",
                      "+421",
                      "+423",
                    ].map((ext) => (
                      <option value={ext}>{ext}</option>
                    ))}
                  </select>
                </div>
                <div className="col-10">
                  <input
                    ref={register({ maxLength: { value: 13, message: "Phone number too long" } })}
                    type="number"
                    id="phone"
                    name="phone"
                    autoComplete="off"
                    maxLength={13}
                    className={`form-input ${errors.phone ? "invalid" : ""}`}
                    defaultValue={organisation.phone}
                    placeholder="Phone number"
                  />
                  {errors.phone && <div className="form-input-error">{errors.phone.message}</div>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-row">
              <label htmlFor="about">About</label>
              <RichEditor placeholder="Write something about the organisation" defaultValue={about} onChange={setAbout} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="form-row">
              <Checkbox onChange={() => setLogoCropped(!logoCropped)} checked={logoCropped} label="Logo Cropped" className="check-type1" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12" style={{ display: "flex" }}>
            <div className="form-row" style={{ marginRight: 30 }}>
              <label htmlFor="logo">Logo</label>
              <div
                className={!logoCropped ? "upload-image-btn" : "upload-image-btn-cropped"}
                style={{ backgroundImage: `url(${organisation.logo})` }}
                onClick={() => setModals({ ...modals, logo: true })}
              >
                <RiEditLine />
              </div>
            </div>

            <EditImageModal
              name="Logo"
              width={250}
              height={250}
              borderRadius={250}
              open={modals.logo}
              onClose={() => setModals({ ...modals, logo: false })}
              logoCropped={(value) => {
                setLogoCropped(value);
                onLogoUpdate(value);
              }}
              afterImageUpload={(file) => onEditImage("logo", file)}
              afterImageUploadFullSize={(file) => onEditImage("originalLogo", file)}
            />

            <div className="form-row">
              <label htmlFor="logo">Banner</label>
              <div
                className="upload-image-btn"
                style={{ backgroundImage: `url(${organisation.banner})`, width: 800 }}
                onClick={() => setModals({ ...modals, banner: true })}
              >
                <RiEditLine />
              </div>
            </div>

            <EditImageModal
              name="Banner"
              width={800}
              height={200}
              crop={false}
              rotate={false}
              borderRadius={0}
              open={modals.banner}
              onClose={() => setModals({ ...modals, banner: false })}
              afterImageUpload={(file) => onEditImage("banner", file)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <button type="submit" className="button primary">
              Save
            </button>
          </div>
          <div className="col-6 text-right">
            <button type="button" className="button danger" onClick={onDeleteOrg}>
              Delete Organisation
            </button>
          </div>
        </div>
      </form>

      <DeleteOrgModal isOpen={showDeleteModal} onClose={() => setShowDeleteModal(false)} organisation={organisation} />
    </>
  );
};

export default EditOrganisation;
