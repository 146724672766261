import React, { useState, useContext } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { AppContext } from "@context/AppContext";
import { toast } from "react-toastify";
import AddOwnerModal from "./AddOwnerModal/AddOwnerModal";
import { List, ListItem } from "@common";

const Owners = ({ owners, setOwners }) => {
  const { id } = useParams();
  const { user: userState } = useContext(AppContext);
  const [showAddModal, setShowAddModal] = useState(false);

  const onRemoveOwner = async (ownerId) => {
    if (!window.confirm("Are you sure?")) return false;

    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/organisations/${id}/owners/removeOwner`, { ownerId });
      setOwners(owners.filter(({ user }) => user._id !== ownerId));
      toast.success("Successfully removed owner");
    } catch (e) {
      toast.error("Couldn't remove owner");
    }
  };

  return (
    <React.Fragment>
      <div className="row pb-3" style={{ maxWidth: "650px" }}>
        <div className="col-12">
          <List>
            <ListItem>
              <div className="row">
                <div className="col-5">
                  <span className="text-14 bold uppercase">Name</span>
                </div>
                <div className="col-3">
                  <span className="text-14 bold uppercase">Role</span>
                </div>
                <div className="col-4"></div>
              </div>
            </ListItem>
            {owners.map(
              ({ user, role }) =>
                user && (
                  <ListItem key={user._id}>
                    <div className="row">
                      <div className="col-5">
                        {user.firstName} {user.lastName}
                      </div>
                      <div className="col-3">{role}</div>
                      <div className="col-4">
                        {userState.state._id !== user._id ? (
                          <button className="button small danger" onClick={() => onRemoveOwner(user._id)}>
                            Remove
                          </button>
                        ) : (
                          <>[you] { " " }
                          <button className="button small danger" onClick={() => onRemoveOwner(user._id)}>
                            Remove
                          </button>
                          </>
                        )}
                      </div>
                    </div>
                  </ListItem>
                )
            )}
          </List>
        </div>
      </div>
      <button className="button primary" onClick={() => setShowAddModal(true)}>
        Add owner or manager
      </button>
      <AddOwnerModal open={showAddModal} onClose={() => setShowAddModal(false)} owners={owners} setOwners={setOwners} />
    </React.Fragment>
  );
};

export default Owners;
