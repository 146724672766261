import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { PageWithTabs, Loading } from "@common";
import { Overview, Matches, Leaderboard, TopPlayers, TopPlayersCsgo, Rules, Prizes, Announcements, Participants } from "./LeaguesSingleTabs";
import Axios from "axios";
import moment from "moment";
import { AppContext } from "@context/AppContext";

const LeaguesSingle = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [league, setLeague] = useState({});
  const { selectedGame, setSelectedGame } = useContext(AppContext);
  const [leagueFromGame, setLeagueFromGame] = useState(null);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/leagues/${id}`).then(({ data }) => {
      setLeagueFromGame(data.game.shortName);
      if (data.game.shortName !== selectedGame.shortName) {
        setSelectedGame(data.game);
        return window.location.reload();
      } else {
        console.log("leagues data : ", data);
        setLeague(data);
        setLoading(false);
      }
    });
  }, [id]);

  if (loading) {
    return <div className="col-12 text-center">
      <Loading />
    </div>;
  }

  let pages = {
    Overview: <Overview league={league} />,
    Matches: <Matches league={league} />,
    Participants: <Participants league={league} />,
    Leaderboard: <Leaderboard leagueId={league._id} league={league} />,
    "Top Players": leagueFromGame === "CSGO" ? <TopPlayersCsgo leagueId={league._id} /> : <TopPlayers leagueId={league._id} />,
    Prizes: <Prizes teams={league.teams} prizes={league.prizes} leagueFinished={league.leagueFinished} />,
    Rules: <Rules slug={league.rulesGhostSlug} />,
    // "Announcements (2)": <Announcements />,
  };

  return (
    <PageWithTabs
      pages={pages}
      cover_image={league.coverImage}
      image={league.logoImage}
      base_route={`/league/${id}`}
      above_title="League"
      title={league.name}
      custom_header={<div className="league-header"></div>}
      hide_ads={true}
      below_title={`${moment(league.startDate).format("DD MMM, YYYY")} - ${league.season.name + (league.sponsor ? ` - Hosted by ${league.sponsor}` : "")
        }`}
    />
  );
};

export default LeaguesSingle;
