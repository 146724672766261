import React, { useContext, useState, useEffect } from "react";
import NewsItem from "./../NewsItem/NewsItem";
import { AppContext } from "@context/AppContext";
import { UrlPagination, Advert } from "@common";
import { useLocation } from "react-router-dom";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const FeaturedItemRow = ({ loading, featured, filter }) => {
  if (typeof loading !== "undefined") {
    return (
      <div className="row smaller-gutter-row">
        <NewsItem large loading />
        <div className="col-4 news">
          <div className="info-placeholder fluid">Advertisement</div>
          <div className="info-placeholder fluid">Advertisement</div>
        </div>
      </div>
    );
  }

  return (
    <div className="row smaller-gutter-row">
      <NewsItem
        large
        title={featured.title}
        excerpt={featured.excerpt}
        image={featured.feature_image}
        published={featured.published_at}
        slug={featured.slug}
        tag={featured.primary_tag}
      />
      <div className="col-4 news">
        <div className="info-placeholder small">
          <Advert width="100%" height={250} refName="HOMEPAGE_ARTICLE_500_250_NO_1" style={{ borderRadius: 2 }} />
        </div>
        <div className="info-placeholder small">
          <Advert width="100%" height={250} refName="HOMEPAGE_ARTICLE_500_250_NO_2" style={{ borderRadius: 2 }} />
        </div>
      </div>
    </div>
  );
};

const NewsTab = (props) => {
  const urlQuery = useQuery();
  const [posts, setPosts] = useState([]);
  const [featured, setFeatured] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(urlQuery.get("page"));
  let context = useContext(AppContext);
  let { filter } = props;

  if (typeof filter === "undefined") {
    filter = "";
  } else {
    filter = filter;
  }

  useEffect(() => {
    let blocked = false;
    let _featured = [];
    let fetchLimit = 12;

    setLoading(true);

    context.ghost.posts
      .browse({ filter: "featured:true", limit: 1, include: "tags" })
      .then((content) => {
        if (!blocked) {
          _featured = content;
          setFeatured(content);

          context.ghost.posts
            .browse({
              page: urlQuery.get("page") || 1, limit: 12, include: "tags", filter: `id:-${_featured[0]?.id}${filter ? "+" + filter : ""}`
            })
            .then((content) => {
              setPosts(content);
              setLoading(false);
            })
            .catch((err) => {
              console.error(err);
            });
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      setPosts([]);
      blocked = true;
    };
  }, [filter, context.ghost.posts, page]);

  if (loading || posts === [] || featured === null) {
    return (
      <div>
        <FeaturedItemRow loading filter={filter} />

        <div className="row smaller-gutter-row">
          {[...Array(12).keys()].map((item) => (
            <NewsItem key={item} loading />
          ))}
        </div>
      </div>
    );
  }
  // post?.id == featured[0]?.id) return;
  // (i == 3 && posts[0]?.id !== featured[0]?.id) return;
  return (
    <div>
      <FeaturedItemRow featured={featured[0]} filter={filter} />
      <div className="row smaller-gutter-row">
        {posts.map((item) => (
          <NewsItem
            key={item.id}
            title={item.title}
            excerpt={item.excerpt}
            image={item.feature_image}
            published={item.published_at}
            slug={item.slug}
            tag={item.primary_tag}
          />
        ))}
      </div>
      <UrlPagination page={Number(urlQuery.get("page")) || 1} setPage={setPage} pages={posts.meta?.pagination?.pages} />
    </div>
  );
};

export default NewsTab;
