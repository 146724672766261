import React, { useState } from "react";
import Modal from "react-modal";
import { RiCloseLine } from "react-icons/ri";
import Loading from "../../../../_common/Loading/Loading";
import Axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const DeleteTeamModal = ({ isOpen, onClose, team }) => {
  const history = useHistory();
  const [teamName, setTeamName] = useState("");
  const [processing, setProcessing] = useState(false);

  const onDeleteSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/teams/${team._id}`);
      toast.success("Successfully deleted");
      history.push("/teams");
    } catch (e) {
      let error = e.response.data ? e.response.data.msg : "There was a problem deleting this team";
      toast.error(error);
    }

    setProcessing(false);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="modal">
      <div className="modal-close" onClick={onClose}>
        <RiCloseLine />
      </div>
      <div className="modal-header">
        <h2>Delete your team</h2>
      </div>
      <div className="modal-body">
        <div className="delete-account-text">
          <p>
            To confirm that you want to delete your team type <strong>{team.name}</strong>:
          </p>

          <form onSubmit={onDeleteSubmit} id="delete-acc-form" autoComplete="off">
            <div className="form-row">
              <input onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                type="text"
                className="form-input"
                autoComplete={false}
                autoFocus
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
              />
            </div>
            <div className="form-row">
              <button type="submit" className="button danger thicc" disabled={team.name !== teamName || processing}>
                {processing ? <Loading /> : "Delete Team"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteTeamModal;
