import React, { useEffect, useState } from "react";
import { ListItem } from "@common";
import { RiCheckLine, RiPencilLine } from "react-icons/ri";
import default_team_cover from "@img/teams-single-bg.png";

const TeamMemberListItem = ({
  img,
  title,
  subtitle,
  position = false,
  role,
  nationality = {},
  actions = [],
  pending = false,
  editable = false,
  onChangeRole,
  availableNewRoles = [],
  allMembers = [],
  pendingInvites = [],
  team,
  userFirstName,
  userLastName,
  coachTypes,
  coachPosition,
  subTypes,
  tagLine = false,
}) => {
  const [editingRole, setEditingRole] = useState(false);
  const [subEditingRole, setSubEditingRole] = useState(false);
  const [newRole, setNewRole] = useState();
  const [newCoachRole, setNewCoachRole] = useState();
  const [newUserRole, setNewUserRole] = useState();
  const [newRoles, setNewRoles] = useState([]);
  const [userRoles, setUserRoles] = useState([
    { name: "Owner", value: "Owner" },
    { name: "Manager", value: "Manager" },
  ]);

  console.log('role', role)
  let initialLetters =
    userFirstName && userLastName ? userFirstName.charAt(0) + userLastName.charAt(0) : title && title.charAt(0) + title.charAt(1);

  useEffect(() => {
    if (position && position.name) {
      setNewRole("Player:" + position.name);
    }

    if (coachPosition && coachPosition.name) {
      setNewCoachRole("Coach:" + coachPosition.name);
    }

    setNewRoles(
      availableNewRoles.filter((role) => {
        let roleCheck = team?.game?.configuration?.playerTypes?.filter((playerRole) => {
          if (
            role.includes(playerRole?.name) &&
            playerRole?.numberOfPlayers &&
            Number(playerRole.numberOfPlayers) !== 0 &&
            Number(playerRole.numberOfPlayers) <= countRoleOfPlayer(role)
          )
            return role;
        });

        if (roleCheck && !roleCheck.length) return role;
      })
    );
    
  }, [position, editingRole, subEditingRole]);

  const countRoleOfPlayer = (role) => {
    let roleCount = 0;

    allMembers.forEach((member) => {
      if (member.role.includes(role)) roleCount++;
    });

    pendingInvites.forEach((pendingMembers) => {
      if (pendingMembers.role.includes(role)) roleCount++;
    });

    return roleCount;
  };

  const onEditMemberRole = () => {
    onChangeRole(newRole);
    setEditingRole(false);
  };

  const onEditSubRole = () => {
    onChangeRole(newRole);
    setSubEditingRole(false);
  };

  const onEditCoachRole = () => {
    onChangeRole(newCoachRole);
    setEditingRole(false);
  };

  const onEditUserRole = () => {
    onChangeRole(newUserRole);
    setEditingRole(false);
  };

  return (
    <ListItem>
      <div className="row team-member-list-item">
        <div className="col-9 team-member-details" style={{ opacity: pending ? 0.5 : 1 }}>
          <div className="row">
            <div className="col-5">
              {img ? (
                <div className="team-member-img" style={{ backgroundImage: `url(${img || default_team_cover})` }} alt={title} />
              ) : (
                <div className="no_profile" style={{ backgroundColor: `${team?.game?.mainColour}1A` }}>
                  {initialLetters}
                </div>
              )}
              <div className="team-member-info">
                <span className="text-14 bold">
                  {tagLine ? (
                    <>
                      {title}
                      <span className="text-faded">#{tagLine}</span>
                    </>
                  ) : (
                    title || "[Deleted]"
                  )}
                </span>
                {subtitle && <span className="text-14 text-faded">{subtitle}</span>}
              </div>
            </div>

            <div className="col-4">
              {position ? (
                <>
                  {editingRole ? (
                    <>
                      <select className="form-input" id="select-new-role" value={newRole} onChange={(e) => setNewRole(e.target.value)}>
                        <option value={`Player:${position.name}`}>
                          Current Role: {position.name.includes("SUB") ? "SUB" : position.name}
                        </option>
                        {newRoles.map((role) => {
                          const roleSplit = role.split(":"); // Players come in the format Player:TOP
                          const roleStr = roleSplit[1] || roleSplit[0];

                          return <option value={role}>{roleStr}</option>;
                        })}
                      </select>
                      <button className="edit-teammem-role" onClick={onEditMemberRole}>
                        <RiCheckLine />
                      </button>
                    </>
                  ) : subEditingRole ? (
                    <>
                      <select className="form-input" id="select-new-role" value={newRole} onChange={(e) => setNewRole(e.target.value)}>
                        <option value={`Player:${position.name}`}>
                          Current Role: {position.name.includes("SUB") && position.name.split(":")[1]}
                        </option>
                        {subTypes.map((role) => {
                          return <option value={`Player:SUB:${role.name}`}>{role.name}</option>;
                        })}
                      </select>
                      <button className="edit-teammem-role" onClick={onEditSubRole}>
                        <RiCheckLine />
                      </button>
                    </>
                  ) : (
                    <>
                      <div className="substituteSubRoleTypes icon-alignment">
                        {!editable && position.name.includes("No Specification") ? (
                          <>
                            <div className="withoutSubRole">
                              <img src={position.icon} alt={position.name} className="position-icon" />
                              <span className="text-14 uppercase bold">{position.name.includes("SUB") ? "SUB" : position.name}</span>
                              {!pending && editable && (
                                <button onClick={() => setEditingRole(true)} className="edit-teammem-role">
                                  <RiPencilLine />
                                </button>
                              )}
                            </div>
                          </>
                        ) : position.name.includes("SUB") ? (
                          <>
                            <div className="sub-icon">
                              <img src={position.icon} alt={position.name} className="position-icon" />
                            </div>
                            <div className="withoutSubRole">
                              <div className="sub-text-icon">
                                <span className="text-14 uppercase bold">{position.name.includes("SUB") ? "SUB" : position.name}</span>
                                {!pending && editable && (
                                  <button onClick={() => setEditingRole(true)} className="edit-teammem-role">
                                    <RiPencilLine />
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="withoutSubRole">
                              <img src={position.icon} alt={position.name} className="position-icon" />
                              <span className="text-14 uppercase bold">{position.name.includes("SUB") ? "SUB" : position.name}</span>
                              {!pending && editable && (
                                <button onClick={() => setEditingRole(true)} className="edit-teammem-role">
                                  <RiPencilLine />
                                </button>
                              )}
                            </div>
                          </>
                        )}
                        <div>
                          {position.name.includes("SUB") && (
                            <span className="text-14 text-faded subRole no-spec">
                              {!editable && position.name.includes("No Specification") ? "" : position.name.split(":")[1]} 
                              {!pending && editable && (
                                <button onClick={() => setSubEditingRole(true)} className="edit-teammem-role">
                                  <RiPencilLine />
                                </button>
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : coachPosition ? (
                <>
                  {editingRole ? (
                    <>
                      <select className="form-input" id="select-new-role" value={newRole} onChange={(e) => setNewCoachRole(e.target.value)}>
                        <option value={`Coach:${coachPosition.name}`}>Current Role: {coachPosition.name}</option>
                        {coachTypes &&
                          coachTypes.map(({ name }) => {
                            return <option value={`Coach:${name}`}>{name}</option>;
                          })}
                      </select>
                      <button className="edit-teammem-role" onClick={onEditCoachRole}>
                        <RiCheckLine />
                      </button>
                    </>
                  ) : (
                    <span>
                      <span className="text-14 uppercase bold">Coach</span>
                      <div className="text-14 text-faded">
                        {!editable && coachPosition.name === "No Specification" ? "" : coachPosition.name}
                        {!pending && editable && (
                          <button onClick={() => setEditingRole(true)} className="edit-teammem-role">
                            <RiPencilLine />
                          </button>
                        )}
                      </div>
                    </span>
                  )}
                </>
              ) : role && !role.toLowerCase().includes("organisation") ? (
                <>
                  {editingRole ? (
                    <>
                      <select className="form-input" id="select-new-role" value={newRole} onChange={(e) => setNewUserRole(e.target.value)}>
                        <option value={role}>Current Role: {role}</option>
                        {userRoles.map(({ name }) => {
                          return <option value={name}>{name}</option>;
                        })}
                      </select>
                      <button className="edit-teammem-role" onClick={onEditUserRole}>
                        <RiCheckLine />
                      </button>
                    </>
                  ) : (
                    <div className="substituteSubRoleTypes icon-alignment">
                      <>
                        <div className="withoutSubRole">
                          <span className="text-14 uppercase bold">{role}</span>
                          {!pending && editable && (
                            <button onClick={() => setEditingRole(true)} className="edit-teammem-role">
                              <RiPencilLine />
                            </button>
                          )}
                        </div>
                      </>
                    </div>
                  )}
                </>
              ) : (
                role && (
                  <span>
                    <span className="text-14 uppercase bold">{role}</span>
                  </span>
                )
              )}
            </div>

            <div className="col-3">
              {nationality &&
                (nationality.code ? (
                  <>
                    <img src={`https://flagcdn.com/w20/${nationality.code?.toLowerCase()}.png`} alt={nationality.name} />
                    <span className="text-14 uppercase bold">{nationality.name}</span>
                  </>
                ) : (
                  <>
                    <img src={`https://flagcdn.com/w20/dk.png`} alt={nationality.name} />
                    <span className="text-14 uppercase bold">Danish</span>
                  </>
                ))}
            </div>
          </div>
        </div>

        <div className="col-3 team-member-actions">
          <div>
            {actions.map((action, i) => (
              <React.Fragment key={i}>{action}</React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </ListItem>
  );
};

export default TeamMemberListItem;
