import React, { useState, useContext } from "react";
import CreateCoachModal from "@modals/CreateCoachModal/CreateCoachModal";
import CreatePlayerModal from "@modals/CreatePlayerModal/CreatePlayerModal";
import { RiTeamLine, RiUserLine } from "react-icons/ri";
import ProfileListItem from "./ProfileListItem/ProfileListItem";
import { AppContext } from "@context/AppContext";

const MyPlayersCoaches = () => {
  const { user } = useContext(AppContext);
  const [showCreatePlayer, setShowCreatePlayer] = useState(false);
  const [showCreateCoach, setShowCreateCoach] = useState(false);

  const onCreate = (data, type) => {
    user.dispatch({ type: "update", payload: { [type]: [...user.state[type], data] } });
  };

  const onUpdate = (id, {inGameName,description,headerImage,avatarImage}, type) => {
    let updatedPlayers = null;
    if(type === 'players'){
      updatedPlayers = user.state.players.map(player => {
        if(id=== player._id){
          player.inGameName = inGameName;
          player.description = description;
          player.headerImage = headerImage;
          player.avatarImage = avatarImage;
        }
        return player;
      }) ;
    }
    if(type === 'coaches'){
      updatedPlayers = user.state.coaches.map(coach => {
        if(id=== coach._id){
          
          coach.description = description;
          coach.headerImage = headerImage;
          coach.avatarImage = avatarImage;
        }
        return coach;
      }) ;
    }
    if(updatedPlayers){
      user.dispatch({
        type: "update", 
        payload: {
           [type]: updatedPlayers
         } 
       });
    }
    
  };

  const onDelete = (id, type) => {
    user.dispatch({ type: "update", payload: { [type]: user.state[type].filter(({ _id }) => _id !== id) } });
  };

  return (
    <>
      <CreateCoachModal
        open={showCreateCoach}
        onClose={() => setShowCreateCoach(false)}
        afterCreate={(coach) => onCreate(coach, "coaches")}
      />
      <CreatePlayerModal
        open={showCreatePlayer}
        onClose={() => setShowCreatePlayer(false)}
        afterCreate={(player) => onCreate(player, "players")}
      />

      <div className="row my-players">
        <div className="col-6 ">
          <div className="my-players-title">
            <h3>My Coaches</h3>
            <button className="button bordered" onClick={() => setShowCreateCoach(true)}>
              Create Coach
            </button>
          </div>

          <div className="my-players-content">
            {!user.state.coaches || !user.state.coaches.length ? (
              <div className="no-players">
                <RiTeamLine />
                <span>You have not created any coach profiles yet</span>
              </div>
            ) : (
              user.state.coaches.map((coach) => (
                <ProfileListItem {...coach} type="coach" afterDelete={() => onDelete(coach._id, "coaches")} afterUpdateingamename={(payload) => onUpdate(coach._id, payload, "coaches",)} />
              ))
            )}
          </div>
        </div>

        <div className="col-6">
          <div className="my-players-title">
            <h3>My Players</h3>
            <button className="button bordered" onClick={() => setShowCreatePlayer(true)}>
              Create Player
            </button>
          </div>

          <div className="my-players-content">
            {!user.state.players || !user.state.players.length ? (
              <div className="no-players">
                <RiUserLine />
                <span>You have not created any player profiles yet</span>
              </div>
            ) : (
              user.state.players.map((player) => (
                <ProfileListItem {...player} type="player" afterDelete={() => onDelete(player._id, "players")} afterUpdateingamename={(payload) => onUpdate(player._id, payload, "players",)} />
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyPlayersCoaches;
