import React from "react";

const StreamsSingle = () => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<iframe
						title="Stream embed"
						src="https://player.twitch.tv/?channel=sodapoppin&parent=localhost"
						frameborder="0"
						allowfullscreen="true"
						scrolling="no"
						height="600px"
						width="100%"
					></iframe>
				</div>
			</div>
		</div>
	);
};

export default StreamsSingle;
