import React from "react";
import checkmark from "@img/check-mark.svg";
import checkmarkCSGO from "@img/check-mark-csgo.svg";
import checkmark1 from "@img/check-mark-appointments.svg";
import Tippy from "@tippyjs/react";

const Checkbox = ({ label, checked, onChange, className, customClass, checkBoxClass, icon, tooltipText, game }) => {
  return (
    <div className={`checkbox-wrapper noselect row m-0 p-0 align-items-center ${className}`}>
      <div onClick={onChange} className={`${checkBoxClass ? checkBoxClass : game === "CS:GO" ? "checkbox-container checkbox-csgo" : "checkbox-container"}`}>
        {checked ? <img className="" src={game === "CS:GO" ? checkmarkCSGO : checkBoxClass ? checkmark1 : checkmark} className="check-mark fade" /> : null}
      </div>
      <p className={`check-label ${customClass}`}>{label}</p>
      {icon && (
        <Tippy content={tooltipText} placement="right" animation="shift-toward">
          <div className="icon">
            {icon}
          </div>
        </Tippy>
      )}
    </div>
  );
};

export default Checkbox;
