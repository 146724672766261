import React, {useState,useEffect,useContext} from "react";
import { Item, SimplePageWithTabs } from "@common";
import { AppContext } from "../../context/AppContext";
import Axios from "axios";
import Loading from "@common/Loading/Loading";
import {useHistory} from "react-router-dom";

const BrowseGames = () => {
  const history = useHistory();
  const { selectedGame, setSelectedGame } = useContext(AppContext);
  const [games,setGames] = useState([]);
  const [loading, setLoading] = useState(true);

  const onSwitch = (game) => {
    setSelectedGame(game);
    history.push('/')
  };


  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
      // setGames(data);
      setGames(() => {
        return data.filter((game) => game.name !== "No Game");
      });
      setLoading(false);
    });
  }, []);
  return loading ? (
    <Loading width={27} height={27} />
  ) : (
    <SimplePageWithTabs title="Browse Games">
      <div className="no-tabs-under">
        <div className="no-tabs">
          <div className="row smaller-gutter-row">
            {games.length > 0
              ? games.map((game) => (
                  <Item
                    glitchDisabled={true}
                    image={game.verticalDisplayImage}
                    title={game.name}
                    column="1"
                    imageContainerClass="browse-game-item"
                    // onClick={() => {
                    //   onSwitch(game);
                    // }}
                    browseGame
                    link_to={`/game/${game.shortName}`}
                    hide_time_ago={true}
                    // classes="game-container"
                  />
                ))
              : "No game found"}
          </div>
        </div>
      </div>
    </SimplePageWithTabs>
  );
};

export default BrowseGames;
