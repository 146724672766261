import React, { useContext, useState } from "react";
import EditImageModal from "../../EditImageModal/EditImageModal";
import Axios from "axios";
import { toast } from "react-toastify";
import { CreateOrgModalContext } from "../CreateOrgModal";

const AddOrgImages = () => {
  const { orgData, setStep } = useContext(CreateOrgModalContext);
  const [modals, setModals] = useState({
    logo: false,
    banner: false,
  });
  const [images, setImages] = useState({});

  const updateTeam = async (type, image) => {
    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/organisations/${orgData._id}/image`, { type, image });
      setImages({ ...images, [type]: image });
      setModals({ ...modals, [type]: false });
    } catch (err) {
      toast.error("There was a problem updating your organisation's images");
    }
  };

  return (
    <div>
      <div className="modal-header">
        <h2>Customise your organization</h2>
        <h3>Finish creating your organisation by customising it with a logo and a banner.</h3>
      </div>
      <div className="modal-body">
        <div className="add-images">
          {/* LOGO */}
          <div
            className="image-card round-logo"
            onClick={() => setModals({ ...modals, logo: true })}
            style={{ backgroundImage: `url(${images.logo})` }}
          >
            Add Logo
          </div>
          <EditImageModal
            name="Logo"
            width={250}
            height={250}
            borderRadius={250}
            open={modals.logo}
            onClose={() => setModals({ ...modals, logo: false })}
            afterImageUpload={(file) => updateTeam("logo", file)}
          />

          {/* BANNER */}
          <div
            className="image-card"
            onClick={() => setModals({ ...modals, banner: true })}
            style={{ backgroundImage: `url(${images.banner})` }}
          >
            Add Banner
          </div>

          <EditImageModal
            name="Banner"
            width={800}
            height={200}
            crop={false}
            rotate={false}
            borderRadius={0}
            open={modals.banner}
            onClose={() => setModals({ ...modals, banner: false })}
            afterImageUpload={(file) => updateTeam("banner", file)}
          />

          <div className="next-buttons">
            <button className="button transparent" onClick={() => setStep(3)}>
              Skip
            </button>
            <button className="button" onClick={() => setStep(3)}>
              Save & Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOrgImages;
