import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import vars from "@styles/_variables.scss";
import { Item } from "@common";
import { getTopRole } from "@utils";
// import lol_icon from "@img/lol-icon.svg";

const Tag = (props) => {
  if (props.tag === null) {
    return null;
  }
  const tag_color = vars.primary;
  const tag_change = props.tag.split(':').length !== 1 ? props.tag.split(":")[1] : props.tag;


  return <span style={{ backgroundColor: tag_color }}>{tag_change}</span>;
};
const TeamsItem = (props) => {
  let { classes, croppedLogo, itemForGameProfile = false, playerRole, showMembers = false, ...props_parent } = { ...props };
  croppedLogo = props.logoCropped ?? croppedLogo;
  const { player_id, game } = props;
  const { user, authenticated } = useContext(AppContext);
  const [usersPlayers, setUsersPlayers] = useState([]);
  const [usersCoaches, setUsersCoaches] = useState([]);
  // console.log('authenticated-',authenticated)
  useEffect(() => {
    //Run only when logged in and have Coaches
    if (user.state.coaches) {
      setUsersCoaches(user.state.coaches);
    }
  }, [user.state.coaches]);

  useEffect(() => {
    //Run only when logged in and have Players
    if (user.state.players) {
      setUsersPlayers(user.state.players);
    }
  }, [user.state.players]);

  const getTag = () => {
    // if (playerRole) return playerRole;
    // if (props.name !== "Placeholder 3") return "hellloooo";
    let role = "";
    let allRoles = [];
    // console.log("props: ", props);
    const teamMember = props.members || props.owners || []; // Owners for Organisation and Members for team
    const organisationMember = (props.organisation && props.organisation.owners) || []; // Players,Coaches,Managers for Organisation and Members for team
    let usersPlayersArray = usersPlayers.map(({ _id }) => _id) || [];
    let usersCoachesArray = usersCoaches.map(({ _id }) => _id) || [];
    teamMember.length > 0 &&
      teamMember.filter((member, i) => {
        if (member) {
          // Have to remove these check as per the requirement from the Google task Doc "21 - Last Bugs"
          if (
            // member.user === player_id ||
            // member.coach === player_id ||
            // member.player === player_id ||
            member.user === user.state._id ||
            member.coach === user.state._id ||
            member.player === user.state._id ||
            usersPlayersArray.includes(member.user) ||
            usersCoachesArray.includes(member.user) ||
            usersPlayersArray.includes(member.player) ||
            usersCoachesArray.includes(member.player) ||
            usersPlayersArray.includes(member.coach) ||
            usersCoachesArray.includes(member.coach)
            // itemForGameProfile
          ) {
            role = member.role;
            allRoles.push(member.role);
          }
        }
      });

    // if (role !== "") {
    organisationMember.length > 0 &&
      organisationMember.filter((oMember, i) => {
        if (oMember) {
          if (
            oMember.user === user.state._id ||
            // oMember.user === player_id ||
            usersPlayersArray.includes(oMember.user) ||
            usersCoachesArray.includes(oMember.user) ||
            usersPlayersArray.includes(oMember.player) ||
            usersCoachesArray.includes(oMember.player) ||
            usersPlayersArray.includes(oMember.coach) ||
            usersCoachesArray.includes(oMember.coach)
          ) {
            role = oMember.role;
            allRoles.push(oMember.role);
          }
        }
      });
    // }
    const topRole = getTopRole(allRoles, game);

    return topRole ? topRole : "";
  };

  classes += " teams-item";
  return (
    <Item
      {...props_parent}
      title={props.name}
      excerpt={
        props.game && (
          <>
            <img src={props.game.whiteIcon} alt="Icon" /> {props.game.name}{" "}
            {showMembers ? (
              <span>{`- ${props.members.length + props.organisation.owners.length} ${
                props.members.length + props.organisation.owners.length === 1 ? "member" : "members"
              }`}</span>
            ) : null}
            <span className="float-right">{props.leaguesParticipating?.leagueDivisionName || "No Division"}</span>
          </>
        )
      }
      backgroundSize={"cover"}
      default_image={props.banner}
      // image={props.logo}
      classes={classes + " teamimage"}
      team_game_icon_inner={props.game ? props.game.whiteIcon : null}
      item_image_inner={
        <React.Fragment>
          <div className="image-cover"></div>
          {authenticated && <Tag tag={getTag()} />}
          <span className="icon teamicon">
            {props.logo && (
              <img
                className={
                  croppedLogo || croppedLogo === undefined ? "wide-page-with-tabs-logo" : "wide-page-with-tabs-logo_withoutCropped"
                }
                src={props.logo}
                alt="Icon"
              />
            )}
          </span>
        </React.Fragment>
      }
    />
  );
};

export default TeamsItem;
