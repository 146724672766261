import React from "react";
import { string_to_slug } from "@utils";
import Tippy from "@tippyjs/react";

const CheckboxSlider = ({ label, label_style, value, onChange, checked, slider, icon, tooltipText, data }) => {
  return (
    <div className="checkbox-slider-wrapper noselect">
      <input
        type="checkbox"
        id={data ? data._id : string_to_slug(label)}
        checked={checked || value}
        onChange={(e) => {
          if (data) {
            onChange(e, data);
          } else {
            onChange(e);
          }
        }}
        name={data ? data._id : string_to_slug(label)}
      />
      <label htmlFor={data ? data._id : string_to_slug(label)} className={slider ?? "checkbox-slider"}></label>
      <label htmlFor={data ? data._id : string_to_slug(label)} className={label_style ?? "uppercase bold"}>
        {label}
      </label>
      {icon && (
        <Tippy content={tooltipText} placement="right" animation="shift-toward">
          <div className="icon">{icon}</div>
        </Tippy>
      )}
    </div>
  );
};

export default CheckboxSlider;
