import React, { useContext, useEffect, useState, useRef } from "react";
import { SearchInput, ScheduleMatchesItem, Icon, Loading, DynamicSearch, Tag } from "../../_common";
import Filters from "../Filters";
import { AppContext } from "../../../context/AppContext";
import { BsChevronUp, BsArrowUp, BsChevronDown } from "react-icons/bs";
import { getCurrentIsoTime, getFiltersConcat, updateFilterCount, groupMatchesDate, getMatchGroupDate } from "@utils";
import Axios from "axios";
import { isCustomDateMatch } from "../../../utils/time-functions";
import { useHistory } from "react-router";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import useConstant from "use-constant";

const Schedule = (props) => {
  const { setIsFilterSet, filters, isFilterSet, setFilters, includeTournaments, startLoader } = props;
  const { selectedGame } = useContext(AppContext);
  const [upPage, setUpPage] = useState(0);
  const [downPage, setDownPage] = useState(0);
  const [isPrev, setIsPrev] = useState(false);
  const [matches, setMatches] = useState([]);
  const [orignalMatches, setOrignalMatches] = useState([]);
  const [dataInfoDown, setDataInfoDown] = useState();
  const [dataInfoUp, setDataInfoUp] = useState();
  const [scroll, setScroll] = useState(false);
  const [prevResultDate, setPrevResultDate] = useState();
  const [prevResultButton, setPrevResultButton] = useState();
  const [currentResultDate, setCurrentResultDate] = useState();
  const [currentMatches, setcurrentMatches] = useState([]);
  const matchesScroll = useRef(null);
  const [showToday, setShowToday] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [results, setResults] = useState([]);
  const timeout = useRef();
  const history = useHistory();
  const [tagTeams, setTagTeams] = useState([]);
  const [isTagTeamsChange, setIsTagTeamsChange] = useState(false);
  const [tagTeamFirstEmpty, setTagTeamFirstEmpty] = useState(true);
  const [isCurrentEmpty, setIsCurrentEmpty] = useState(true);
  const [isTodayPressed, setIsTodayPressed] = useState(false);
  const { user } = useContext(AppContext);
  const [firstPrev, setFirstPrev] = useState(false);
  const [clickedPastResult, setClickPastResult] = useState(false);

  const onChangeFilters = () => {
    setIsPrev(false);
    setDataInfoUp(null);
    setDataInfoDown(null);
    // setMatches([]);
    // setOrignalMatches([]);
    setFirstPrev(false);
  };
  const setDefaultFilters = ({ groups = [], seasons = [], divisions = [] }) => {
    let defaultFilters = {};
    defaultFilters.groups = groups?.map((el) => Object.assign(el, { selected: true }));
    defaultFilters.divisions = divisions?.map((el) => Object.assign(el, { selected: true }));
    if (seasons.length) seasons[0].selected = true;
    defaultFilters.seasons = seasons;
    setFilters(defaultFilters);
  };
  const asyncFunction = (params) =>
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/matches/overview/schedule/${selectedGame._id}`, params);

  const asyncFunctionDebounced = useConstant(() => AwesomeDebouncePromise(asyncFunction, 500));

  const getOverviewData = (setDefault = false) => {
    setLoading(true);
    let concatFilters = !!filters ? getFiltersConcat(filters) : {};
    let isFilterTemp = isFilterSet || isTagTeamsChange;
    setIsTagTeamsChange(false);
    isFilterSet && onChangeFilters();
    setIsFilterSet(false);
    let isPrevious = isFilterTemp ? false : isPrev;
    // console.log("data of matches -- before API call", loading, !isPrev);
    asyncFunctionDebounced({
      params: {
        withTournaments: includeTournaments,
        ...concatFilters,
        isPrevious,
        page: isFilterTemp ? 0 : isPrev ? upPage : downPage,
        date: getCurrentIsoTime(),
        teams: tagTeams.map(({ _id }) => _id).toString(),
      },
    }).then(({ data }) => {
      if (isFilterTemp) {
        setOrignalMatches([]);
        setMatches([]);
      }
      if (data?.data) {
        console.log("data of matches -- ", data);
        if (isPrevious || data.isPrevious) {
          if (data.isPrevious) {
            setIsPrev(true);
          }
          setDataInfoUp({ totalCount: data.totalCount, currentPage: data.currentPage, hasMore: data.hasMore });
          isFilterTemp ? setUpPage(data.isPrevious ? 1 : 0) : setUpPage(upPage + 1);
          let mData = data.data.reverse();
          if (scroll) setOrignalMatches([...mData, ...orignalMatches]);
          else {
            setMatches([]);
            setOrignalMatches([...data.data]);
          }
        } else {
          setDataInfoDown({ totalCount: data.totalCount, currentPage: data.currentPage, hasMore: data.hasMore });
          isFilterTemp ? setDownPage(1) : setDownPage(downPage + 1);
          if (scroll) setOrignalMatches([...orignalMatches, ...data.data]);
          else {
            setMatches([]);
            setOrignalMatches([...data.data]);
          }
        }
        if (data.prevMatches && data.prevMatches.length) {
          const { currentPage, totalCount, hasMore } = data.prevMatchInfo;
          setDataInfoUp({ totalCount, currentPage, hasMore });
          setUpPage(1);
          let mData = data.prevMatches.reverse();
          // console.log("orignal matches before prev -- ", orignalMatches);
          setOrignalMatches([...mData, ...data.data]);
        }
      }
      setScroll(false);
      setFilters({ ...updateFilterCount(data.filters, filters) });
      if (setDefault) {
        setDefaultFilters(data.filters);
      }
      setLoading(false);
    });
  };
  useEffect(() => {
    if ((filters && isFilterSet) || scroll || (isTagTeamsChange && !tagTeamFirstEmpty)) {
      getOverviewData(false);
    }
  }, [filters, props.includeTournaments, scroll, isTagTeamsChange]);

  useEffect(() => {
    getOverviewData(true);
  }, []);

  useEffect(() => {
    if (tagTeamFirstEmpty) setTagTeamFirstEmpty(false);
    else setIsTagTeamsChange(true);
  }, [tagTeams]);

  useEffect(() => {
    if (orignalMatches?.length) {
      let prev = {};
      let matchesList = groupMatchesDate(orignalMatches, true, user.state.timezone);
      if (!matches.length) {
        let lastIndex = matchesList.length - 1;
        let newDataLatestDate = Object.keys(matchesList[0])[0];
        if (!isPrev) setPrevResultButton(newDataLatestDate);
        let matchId = matchesList[0][newDataLatestDate]?.[0]._id;
        if (isPrev) newDataLatestDate = Object.keys(matchesList[lastIndex])[0];
        setcurrentMatches([...matchesList[isPrev ? lastIndex : 0][newDataLatestDate]]);
        setCurrentResultDate({ topDate: isPrev ? "end" : newDataLatestDate, matchId }); //storing date where need to show past result text
      } else if (isPrev || dataInfoUp !== null) {
        let newDataLatestDate = Object.keys(matchesList[0])[0];
        let matchId = matchesList[0][newDataLatestDate]?.[0]._id;
        prev.topDate = currentResultDate?.topDate;
        prev.matchId = currentResultDate?.matchId;

        setPrevResultDate({ ...prev });
        setCurrentResultDate({ topDate: newDataLatestDate, matchId });
      }
      setMatches([...matchesList]);
    }
  }, [orignalMatches]);
  useEffect(() => {
    if (isPrev) {
      setTimeout(() => {
        onPrevious(prevResultDate);
      }, 120);
    } else if (dataInfoUp?.currentPage == 0 && dataInfoDown?.currentPage == 0) {
      setTimeout(() => {
        onPressToday();
      }, 120);
    } else if (dataInfoUp == null && dataInfoDown?.currentPage == 0) {
      setTimeout(() => {
        onPressToday();
      }, 120);
    }
  }, [matches.length]);

  const handleScroll = () => {
    const { current } = matchesScroll;
    if (current.scrollTop === 0 && dataInfoUp && dataInfoUp?.hasMore) {
      scrollToPrev();
    }
    const scroll = current.scrollHeight - current.scrollTop - current.clientHeight; //for bottom reach
    if (scroll <= 2) {
      if (dataInfoDown?.hasMore) {
        setIsTodayPressed(false);
        setIsPrev(false);
        setScroll(true);
      }
    }
    var BreakException = {};

    try {
      let count = 0;
      currentMatches.forEach(({ _id }, index) => {
        var element = document.getElementById(_id);

        const eleTop = element.offsetTop;
        const eleBottom = eleTop + element.clientHeight;
        const containerTop = matchesScroll.current.scrollTop;
        const containerBottom = containerTop + matchesScroll.current.clientHeight;

        if (
          (eleTop >= containerTop && eleBottom <= containerBottom) ||
          (eleTop < containerTop && containerTop < eleBottom) ||
          (eleTop < containerBottom && containerBottom < eleBottom)
        ) {
          setShowToday(0); //between top and below
          throw BreakException;
        } else {
          count = count + 1;
          if (count == currentMatches.length) {
            if (eleTop < containerTop && containerTop > eleBottom) setShowToday(1);
            //up
            else if (eleTop > containerBottom && containerBottom < eleBottom) setShowToday(2); //down
          }
        }
      });
    } catch (e) {}
  };

  const onHover = (index, groupIndex) => {
    let group = matches[groupIndex];
    let element;
    if (group) {
      if (index > 0) {
        let matches = Object.values(group)[0];
        let match = matches[index - 1];
        if (match && match._id) {
          element = document.getElementById(match._id);
        }
      } else if (index == 0) {
        let date = Object.keys(group)[0];
        if (date) {
          element = document.getElementById(date);
        }
      }
      if (element) {
        element.classList.remove("match-item-hover");
        element.classList.add("match-item-hover");
      }
    }
  };

  const onLeave = (index, groupIndex) => {
    let group = matches[groupIndex];
    let element;
    if (group) {
      if (index > 0) {
        let matches = Object.values(group)[0];
        let match = matches[index - 1];
        if (match && match._id) {
          element = document.getElementById(match._id);
        }
      } else if (index == 0) {
        let date = Object.keys(group)[0];
        if (date) {
          element = document.getElementById(date);
        }
      }
      if (element) {
        element.classList.remove("match-item-hover");
      }
    }
  };

  const scrollToPrev = () => {
    if (!dataInfoUp || dataInfoUp?.hasMore) {
      setIsTodayPressed(false);
      setIsPrev(true);
      setScroll(true);
    }
  };

  const onPressToday = (btnPressed = false) => {
    setIsTodayPressed(true);
    let matchDiv;
    if (btnPressed) {
      matchDiv = document.getElementById("scrolling_div");
      matchDiv.classList.add("smoothScroll");
    }
    const titleElement = document.getElementById(
      typeof dataInfoDown === "undefined" || currentResultDate?.topDate == "end" ? "end" : currentMatches[0]._id
    );
    if (titleElement) {
      titleElement.parentNode.scrollTop = titleElement.offsetTop - 130;
      // titleElement.scrollIntoView({ block: "start" });
    }
    if (btnPressed && matchDiv) matchDiv.classList.remove("smoothScroll");
    // window.scrollTo(0, 250);
  };

  const onPrevious = (date = {}) => {
    // console.log("prevResult:  ", prevResultDate);
    const titleElement = document.getElementById(!prevResultDate ? currentResultDate?.topDate : `${prevResultDate?.matchId}`);
    if (titleElement) {
      titleElement.parentNode.scrollTop = titleElement.offsetTop - 130;
      // titleElement.scrollIntoView({ block: "start" });
    }
    // window.scrollTo(0, 250);
  };

  const onSearch = async (query) => {
    clearTimeout(timeout.current);
    setResults([]);
    if (!query.length) return setSearchLoading(false);
    setSearchLoading(true);
    let selectedGameId = null;
    if (selectedGame.shortName !== "No Game") {
      selectedGameId = selectedGame._id;
    }

    timeout.current = setTimeout(async () => {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/globalSearch`, {
        query,
        include: "teams",
        selectedGameId,
      });

      // Map data to required output, and remove already selected players
      const searchResults = data.map(({ _id, img, name, type, logoCropped }) => ({
        _id,
        type,
        img,
        title: name,
        logoCropped,
      }));

      setResults(searchResults);
      setSearchLoading(false);
    }, 250);
  };

  const onClickTag = (id) => {
    let tempTagTeams = tagTeams.filter(({ _id }) => id !== _id);
    setTagTeams([...tempTagTeams]);
  };
  const onClickSearchResult = (result) => {
    const { _id, type, title, img, logoCropped } = result;
    switch (type) {
      case "Team":
        let team = { _id, type, title, img, logoCropped };
        tagTeams.push(team);
        setTagTeams([...tagTeams]);
        break;
      default:
    }
  };

  const scrollOnPastresult = () => {
    if (dataInfoUp !== null && typeof dataInfoUp !== "undefined") {
      let matchDiv = document.getElementById("scrolling_div");
      matchDiv.classList.add("smoothScroll");
      matchesScroll.current.scrollTop = matchesScroll.current.scrollTop - matchesScroll.current.clientHeight;
      setClickPastResult(false);
      matchDiv.classList.remove("smoothScroll");
    }
  };
  return (
    <>
      {startLoader ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loading />
        </div>
      ) : (
        <div className="row d-flex">
          <div className="col-8 matches-col" style={{ paddingLeft: "20px", paddingRight: "0px" }}>
            <DynamicSearch
              placeholder="Search for teams to see matches for..."
              onSearch={onSearch}
              onSelectResult={onClickSearchResult}
              loadingResults={searchLoading}
              results={results}
              mixedResults={true}
              resultStyle={{ zIndex: 20 }}
              searchInputStyle={{ background: "#12141a" }}
              schedule
            />
            <div className="tag-container">
              {tagTeams?.map(({ _id, title, img, logoCropped }) => (
                <Tag name={title} img={img} logoCropped={logoCropped} onClick={() => onClickTag(_id)} />
              ))}
            </div>
            <div
              id="scrolling_div"
              className="graybox"
              ref={(r) => (matchesScroll.current = r)}
              onScroll={() => handleScroll()}
              style={{ height: "700px", maxHeight: "700px" }}
            >
              {loading && isPrev && (
                <div className="col-12 text-center">
                  <Loading />
                </div>
              )}
              {matches.length ? (
                <>
                  {matches.map((group, i) => {
                    let date = getMatchGroupDate(Object.keys(group)[0]);
                    // console.log("current matches group:", group);
                    let id = JSON.stringify(Object.keys(group)[0]);
                    let showPrev = isCustomDateMatch(date, new Date(prevResultButton));
                    let currentDate = new Date().getFullYear();
                    let groupDate = new Date(Object.keys(group)[0]).getFullYear();
                    let groupMatches = Object.values(group)[0];

                    // console.log("single group matches :", date, groupMatches);

                    return (
                      <>
                        <div className="date-wrapper">
                          <div className="date-div row d-flex justify-content-between ml-4" id={Object.keys(group)[0]}>
                            <span className="uppercase">
                              <b>{date.day}</b> - {date.date} {date.month} {groupDate != currentDate ? date.year : null}
                            </span>
                            {showPrev && (
                              <div
                                className="row d-flex text-14 bold uppercase color-white align-items-center prev"
                                onClick={() => {
                                  setClickPastResult(true);
                                  scrollOnPastresult();
                                  // scrollToPrev();
                                }}
                              >
                                <span style={{ textAlign: "center" }}>Past Results</span>
                                <BsArrowUp
                                  size={20}
                                  style={{
                                    color: "var(--primary)",
                                    marginLeft: "2px",
                                    marginTop: "2px",
                                    marginRight: "15px",
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        {groupMatches.map((match, ind) => {
                          return (
                            <ScheduleMatchesItem
                              key={match?._id}
                              match={match}
                              id={match?._id}
                              date={date}
                              index={ind}
                              onHover={onHover}
                              onLeave={onLeave}
                              groupIndex={i}
                            />
                          );
                        })}
                      </>
                    );
                  })}
                  {/* <div id="end"></div> */}
                </>
              ) : null}
              {(!matches.length || (matches.length && dataInfoDown == null)) && (
                <>
                  <div className="date-div row d-flex justify-content-between" id={"end"} style={{ borderBottom: "0px" }}>
                    <span></span>
                    <div
                      className="row d-flex text-14 bold uppercase color-white align-items-center prev"
                      onClick={() => {
                        setClickPastResult(true);
                        // scrollToPrev();
                        scrollOnPastresult();
                      }}
                    >
                      <span style={{ textAlign: "center" }}>Past Results</span>
                      <BsArrowUp
                        size={20}
                        style={{
                          color: "var(--primary)",
                          marginLeft: "2px",
                          marginTop: "2px",
                          marginRight: "15px",
                        }}
                      />
                    </div>
                  </div>
                  {loading && !isPrev && (
                    <div className="col-12 text-center">
                      <Loading />
                    </div>
                  )}
                  <span className="no-match-text">No future matches scheduled</span>
                </>
              )}
            </div>
            {loading && !isPrev && dataInfoDown !== null && typeof dataInfoDown !== "undefined" && (
              <div className="col-12 text-center">
                <Loading />
              </div>
            )}
            {showToday !== 0 && (
              <div className="text-14 bold uppercase color-white bottom-float prev shecdule-float-btn" onClick={() => onPressToday(true)}>
                <span style={{ textAlign: "center" }}>TODAY</span>
                {showToday === 2 ? (
                  <BsChevronDown style={{ color: "var(--primary)", marginLeft: "3px" }} />
                ) : (
                  <BsChevronUp style={{ color: "var(--primary)", marginLeft: "3px" }} />
                )}{" "}
              </div>
            )}
          </div>

          <div className="col-4 pl-4 pr-1 filters-col">
            <Filters {...props} />
          </div>
        </div>
      )}
    </>
  );
};

export default Schedule;
