import React, { useState, useEffect, useContext } from "react";
import DotPattern from "./DotPattern";
import RenderNewsItems from "./RenderNewsItems";
import RenderFeaturedNewsItem from "./RenderFeaturedNewsItem";
import { Button, SliderItem, Icon, Advert, AdvertByGame } from "@common";
import { AppContext } from "../../../context/AppContext";
const News = ({ data }) => {
  let context = useContext(AppContext);
  const { selectedGame, setSelectedGame } = useContext(AppContext);
  const [posts, setPosts] = useState([]);
  const [featured, setFeatured] = useState([]);
  const [ghostFilterTag,setGhostFilterTag] = useState(null);
  const [advertGameId,setAdvertGameId] = useState(selectedGame ? selectedGame._id : null);
  console.log('ccc-ccc-posts--posts--',posts);
  console.log('ccc-ccc-posts--featured--', featured);

  useEffect(() => {
    console.log('ccc-ccc-run data');
    if(data.game){
      setGhostFilterTag(data.game.replace('#','hash-'));
    }
    if (data.SelectAdvert) {
      setAdvertGameId(data.SelectAdvert);
    }
  }, [data]);

  const getFeaturedBlogPost = (ghostFilterTag) => {
    console.log("ghost filter tag -- ", ghostFilterTag);
    context.ghost.posts
      .browse({
        // limit: 1, filter: `featured:true,tags:${ghostFilterTag}`, include: "tags,authors"
        limit: 1,
        include: "tags,authors",
        filter: `featured:true${ghostFilterTag == "ALL" ? "" : "+tag:" + ghostFilterTag}`,
      })
      .then((posts) => {
        console.log("ccc-posts-getFeaturedBlogPost-", posts);
        setFeatured(posts);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  console.log("filter --", `${ghostFilterTag == "ALL" ? "" : ghostFilterTag}`);
  const getSubBlogPosts = (ghostFilterTag) => {
    context.ghost.posts
      .browse({
        limit: 4,
        include: "tags,authors",
        filter: `${ghostFilterTag == "ALL" ? "" : "tag:" + ghostFilterTag}`,
        // filter: `featured:false,tags:${ghostFilterTag}`
        // filter: `tags:hash-gamelol`
        // filter: `tag:${ghostFilterTag}`,
      })
      .then((posts) => {
        console.log("ccc-ccc-posts-getSubBlogPosts-", posts);
        setPosts(posts);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    console.log("ccc-run 2", "ghostFilterTag");

    // Featured blog post
    ghostFilterTag && getFeaturedBlogPost(ghostFilterTag);

    // sub blog posts
    ghostFilterTag && getSubBlogPosts(ghostFilterTag);
  }, [context.ghost.posts, ghostFilterTag]);

  return (
    <div className="container-fluid">
      {/* <div className="cool-heading-container">

  <div className="icon-cross">
    <Icon name="cross" />
  </div>

  <div className="icon-play">
    <Icon name="play" />
  </div>

  <DotPattern />

  <h1 className="center">
    <span className="news-heading-background">NEWS</span>
    <span className="news-heading-foreground">
      <span className="outline">L4t3st</span> news
    </span>
  </h1>
</div> */}

      {data.HideFeaturedArticle !== true && (
        <div className="row smaller-gutter-row">
          <RenderFeaturedNewsItem post={featured} />
          <div className="col-4 news">
            <div className="info-placeholder small">
              {advertGameId !== "ALL" ? (
                <AdvertByGame
                  width="100%"
                  height={250}
                  advertForGame={advertGameId}
                  refName="HOMEPAGE_ARTICLE_500_250_NO_1"
                  style={{ borderRadius: 2 }}
                />
              ) : (
                <Advert width="100%" height={250} refName="HOMEPAGE_ARTICLE_500_250_NO_1" style={{ borderRadius: 2 }} />
              )}
            </div>
            <div className="info-placeholder small">
              {advertGameId !== "ALL" ? (
                <AdvertByGame
                  width="100%"
                  height={250}
                  advertForGame={advertGameId}
                  refName="HOMEPAGE_ARTICLE_500_250_NO_2"
                  style={{ borderRadius: 2 }}
                />
              ) : (
                <Advert width="100%" height={250} refName="HOMEPAGE_ARTICLE_500_250_NO_2" style={{ borderRadius: 2 }} />
              )}
            </div>
          </div>
        </div>
      )}

      <div className="row smaller-gutter-row">
        <RenderNewsItems posts={posts} featured={featured} hide={data.HideFeaturedArticle} />
      </div>
    </div>
  );
};

export default News;
