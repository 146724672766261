import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../../context/AppContext";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Axios from "axios";
import AvatarCover from "./AvatarCover";
import DeleteAccountModal from "./DeleteAccountModal/DeleteAccountModal";
import moment from "moment";
import { countries } from "@utils";
import { TIMEZONES } from "../../../../utils/timezones";

const MyAccount = () => {
  const { user } = useContext(AppContext);
  const { register, handleSubmit, setValue, errors, setError, reset } = useForm();
  const [nationalities, setNationalities] = useState([]);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
 
 
  useEffect(() => {
    setNationalities(
      countries
        .filter(({ demonym }) => demonym)
        .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
        .sort((a, b) => a.demonym.localeCompare(b.demonym))
    );
  }, [])
  useEffect(() => {
    reset({
      nationality: JSON.stringify(user.state.nationality),
      timezone: user.state.timezone
    });
  }, [user.state])

  const onDeleteAccount = () => {
    setDeleteAccountModal(true);
  };

  const onSubmit = async (data) => {
    // Create a birthday string from values entered
    const birthday = moment(`${data.birthdayDay} ${data.birthdayMonth} ${data.birthdayYear}`).toISOString();

    if (moment().diff(birthday, "years") < 10) {
      setError("birthday", "tooYoung", "You need to be at least 10 to sign up for Leagues");
      return;
    }

    // Delete old birthday keys
    delete data.birthdayDay;
    delete data.birthdayMonth;
    delete data.birthdayYear;

    try {
      const { data: newData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/auth`, { ...data, birthday });
      user.dispatch({ type: "update", payload: newData.updatedUser });
      toast.success("Details updated");
    } catch (e) {
      const msg = e.response.data ? e.response.data.msg : "Fatal error";
      toast.error(msg);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} id="acc-settings-form" className="account-settings-form">
        <div className="row smaller-gutter-row">
          <div className="col-3">
            <div className="form-row">
              <label htmlFor="firstName">First Name</label>
              <input
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register({ required: "Required", maxLength: { value: 30, message: "Name too long" } })}
                type="text"
                id="firstName"
                name="firstName"
                autoComplete="off"
                className={`form-input ${errors.firstName ? "invalid" : ""}`}
                defaultValue={user.state.firstName}
                placeholder="John"
              />
              {errors.firstName && <div className="form-input-error">{errors.firstName.message}</div>}
            </div>
          </div>
          <div className="col-3">
            <div className="form-row">
              <label htmlFor="lastName">Last Name</label>
              <input
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register({ required: "Required", maxLength: { value: 30, message: "Name too long" } })}
                type="text"
                id="lastName"
                name="lastName"
                autoComplete="off"
                className={`form-input ${errors.lastName ? "invalid" : ""}`}
                defaultValue={user.state.lastName}
                placeholder="Doe"
              />
              {errors.lastName && <div className="form-input-error">{errors.lastName.message}</div>}
            </div>
          </div>

          <div className="col-6">
            <div className="form-row">
              <label htmlFor="nickname">Nickname</label>
              <input
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register({ maxLength: { value: 30, message: "Nickname too long" } })}
                type="text"
                id="nickname"
                name="nickname"
                autoComplete="off"
                className={`form-input ${errors.nickname ? "invalid" : ""}`}
                defaultValue={user.state.nickname}
                placeholder="Johnny"
              />
              {errors.nickname && <div className="form-input-error">{errors.nickname.message}</div>}
            </div>
          </div>

          <div className="col-6">
            <div className="form-row">
              <label htmlFor="birthdayDay">Birthday</label>
              <div className="row sm-gutters">
                <div className="col-3">
                  <input
                    onBlur={(e) => {
                      e.target.value = e.target.value.trim();
                    }}
                    type="number"
                    ref={register({ required: "Required", min: 1, max: 31 })}
                    id="birthdayDay"
                    name="birthdayDay"
                    className={`form-input ${errors.birthdayDay ? "invalid" : ""}`}
                    defaultValue={moment(user.state.birthday).format("D") || ""}
                    placeholder="Day"
                  />
                </div>
                <div className="col-6">
                  <select
                    ref={register({ required: "Required" })}
                    id="birthdayMonth"
                    name="birthdayMonth"
                    className={`form-input ${errors.birthdayMonth ? "invalid" : ""}`}
                    defaultValue={moment(user.state.birthday).format("MMMM") || ""}
                  >
                    <option value="" disabled>
                      Month
                    </option>
                    {[
                      "January",
                      "February",
                      "March",
                      "April",
                      "May",
                      "June",
                      "July",
                      "August",
                      "September",
                      "October",
                      "November",
                      "December",
                    ].map((month) => (
                      <option value={month}>{month}</option>
                    ))}
                  </select>
                </div>
                <div className="col-3">
                  <input
                    onBlur={(e) => {
                      e.target.value = e.target.value.trim();
                    }}
                    type="number"
                    ref={register({ required: "Required", min: 1900, max: moment().format("Y") })}
                    id="birthdayYear"
                    name="birthdayYear"
                    className={`form-input ${errors.birthdayYear ? "invalid" : ""}`}
                    defaultValue={moment(user.state.birthday).format("YYYY") || ""}
                    placeholder="Year"
                  />
                  {errors.birthdayYear && <div className="form-input-error">Year is wrong</div>}
                </div>
              </div>
              {errors.birthday && <div className="form-input-error">{errors.birthday.message}</div>}
            </div>
          </div>

          <div className="col-6">
            <div className="form-row">
              <label htmlFor="gender">Gender</label>
              <select
                ref={register({ required: "Required" })}
                id="gender"
                name="gender"
                className={`form-input ${errors.gender ? "invalid" : ""}`}
                defaultValue={user.state.gender || ""}
              >
                <option value="" disabled>
                  Choose gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
                <option value="Prefer not to say">Prefer not to say</option>
              </select>
              {errors.gender && <div className="form-input-error">{errors.gender.message}</div>}
            </div>
          </div>

          <div className="col-6">
            <div className="form-row">
              <label htmlFor="nationality">Nationality</label>
              <select
                ref={register({ required: "Required" })}
                id="nationality"
                name="nationality"
                className={`form-input ${errors.nationality ? "invalid" : ""}`}
              >
                <option value="" disabled>
                  Choose nationality
                </option>
                {nationalities.map(({ alpha2Code, demonym }) => (
                  <option key={alpha2Code} value={JSON.stringify({ code: alpha2Code, name: demonym })}>
                    {demonym}
                  </option>
                ))}
              </select>
              {errors.nationality && <div className="form-input-error">{errors.nationality.message}</div>}
            </div>
          </div>

          <div className="col-6">
            <div className="form-row">
              <label htmlFor="phoneExt">Phone Number</label>
              <div className="row sm-gutters">
                <div className="col-2">
                  <select name="phoneExt" id="phoneExt" className="form-input" ref={register()} defaultValue={user.state.phoneExt || "+45"}>
                    {[
                      "+30",
                      "+31",
                      "+32",
                      "+33",
                      "+34",
                      "+36",
                      "+39",
                      "+40",
                      "+43",
                      "+44",
                      "+45",
                      "+46",
                      "+47",
                      "+48",
                      "+49",
                      "+92",
                      "+350",
                      "+351",
                      "+352",
                      "+353",
                      "+354",
                      "+356",
                      "+357",
                      "+358",
                      "+359",
                      "+370",
                      "+371",
                      "+372",
                      "+385",
                      "+386",
                      "+420",
                      "+421",
                      "+423",
                    ].map((ext) => (
                      <option value={ext}>{ext}</option>
                    ))}
                  </select>
                </div>
                <div className="col-10">
                  <input
                    onBlur={(e) => {
                      e.target.value = e.target.value.trim();
                    }}
                    ref={register({ maxLength: { value: 13, message: "Phone number too long" } })}
                    type="number"
                    id="phone"
                    name="phone"
                    autoComplete="off"
                    className={`form-input ${errors.phone ? "invalid" : ""}`}
                    defaultValue={user.state.phone}
                    placeholder="Phone number"
                  />
                  {errors.phone && <div className="form-input-error">{errors.phone.message}</div>}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="form-row">
              <label htmlFor="timezone">Preferred time zone</label>
              <select ref={register()} name="timezone" id="timezone" className={"form-input"}>
                <option value="">Automatic</option>
                {TIMEZONES.map(({ name, value }) => (
                  <option value={value}>{name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-12">
            <div className="form-row">
              <label htmlFor="about">About</label>
              <textarea
                ref={register({ maxLength: 5000 })}
                name="about"
                id="about"
                className={`form-input ${errors.about ? "invalid" : ""}`}
                rows="10"
                placeholder="Write something about yourself"
              >
                {user.state.about}
              </textarea>
              {errors.about && <div className="form-input-error">Text too long {errors.about.message}</div>}
            </div>
          </div>

          <div className="col-12">
            <AvatarCover />
          </div>

          <div className="col-6">
            <div className="form-row">
              <button className="button primary" type="submit">
                Save Changes
              </button>
            </div>
          </div>
          <div className="col-6 right">
            <div className="form-row">
              <button type="button" className="button danger" onClick={onDeleteAccount}>
                Delete my account
              </button>
            </div>
          </div>
        </div>
      </form>

      <DeleteAccountModal isOpen={deleteAccountModal} onClose={() => setDeleteAccountModal(false)} />
    </div>
  );
};

export default MyAccount;
