import React, { useState, useRef, useEffect } from "react";
import { Icon } from "@common";

const DropdownHeader = ({ title, dots_menu }) => {
  if (typeof dots_menu == "undefined") {
    return (
      <header>
        <h3>{title}</h3>
      </header>
    );
  }

  return (
    <header>
      <h3>{title}</h3>
      <label htmlFor="dropdown-dotted-button-menu-box" className="dropdown-dotted-button">
        <span></span>
        <span></span>
        <span></span>

        <input type="checkbox" id="dropdown-dotted-button-menu-box" />
        <div className="dropdown-dotted-button-menu">
          {dots_menu.map((i) => (
            <div key={i} onClick={i[1]}>
              {i[0]}
            </div>
          ))}
        </div>
      </label>
    </header>
  );
};

const Dropdown = ({
  games,
  children,
  label,
  image,
  title,
  badge,
  showCaret,
  dots_menu,
  dynamic_width,
  className,
  footer,
  render,
  isGameSwitcher = false,
}) => {
  const dropdown = useRef();
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", checkOutsideClick);
  }, []);

  const checkOutsideClick = (e) => {
    // if click outside div
    if (dropdown.current && !dropdown.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  let classes = "dropdown-wrapper";
  if (showDropdown) classes += " show";
  if (typeof dynamic_width !== "undefined") classes += " dynamic-width";
  if (typeof className !== "undefined") classes += " " + className;
  if (isGameSwitcher) classes += " gc-box";

  return (
    <div ref={dropdown} className={classes + " toggle-game"}>
      <span className={`${showDropdown ? "dropdown-toggle dropdown-toggle1" : "dropdown-toggle"}`} onClick={() => setShowDropdown(!showDropdown)}>
        <div>
          {image && <div className="image" style={{ backgroundImage: `url(${image})` }} />} <span className="label">{label}</span>
          {!!badge && <span className="badge">{badge}</span>}
        </div>
        {showCaret && (
          <span className="caret">
            <Icon name="triangle-down" />
          </span>
        )}
      </span>
      {/* <span className="gc-bottom-content"></span> */}
      <span className="gc-top-content"></span>

      {/* {isGameSwitcher && <div className="empty z_index"></div>} */}
      <div
        className={`dropdown-content ${isGameSwitcher && "edge"} `}
        style={isGameSwitcher ? { backgroundColor: "#191b24", height: showDropdown && games ? `${games * 34 + 34 + 20}px` : "" } : {}}
      >
        {title ? <DropdownHeader title={title} dots_menu={dots_menu} /> : null}

        <main className="scroll-gradient">{render(showDropdown, setShowDropdown)}</main>
        {/* <span className="right-corner-dropdown"></span> */}

        {footer ? <footer>{footer}</footer> : null}
        {/* <div class="bottom-image-cover forGameSelector"></div> */}
      </div>

      <span className="gc-top"></span>
      <span className="gc-bottom"></span>
      <span className="gc-left"></span>
      <span className="gc-right"></span>
      <span className="gc-right-corner"></span>
      <span className="gc-left-corner"></span>
    </div>
  );
};

export default Dropdown;
