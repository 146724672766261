import React, { useState } from "react";
import { Link } from "react-router-dom";
import default_team_logo from "@img/default-team-logo.png";
import { LeaguesResultItem, List, Icon, MatchListingByDate } from "@common";

import LeagueParticipent from "./leagueParticipent";
const About = ({
  about,
  leaguesParticipating = [],
  leaguesParticipatingHistory = [],
  tournamentParticipatingHistory = [],
  tournamentParticipating = [],
  organisation = null,
  recentMatches,
  team,
}) => {
  const [page, setPage] = useState(0);

  return (
    <React.Fragment>
      <div className="tabs-container mb-4">
        <div className="tabs-nav" style={{ borderBottom: "none" }}>
          <nav>
            <button className={page === 0 ? "active" : ""} onClick={() => setPage(0)}>
              Currently participating in
            </button>
            <button className={page === 1 ? "active" : ""} onClick={() => setPage(1)}>
              Participation history
            </button>
          </nav>
        </div>
      </div>

      {page === 0 && (
        <LeagueParticipent
          leaguesParticipating={leaguesParticipating}
          tournamentParticipating={tournamentParticipating}
          message={"This team is not currently participating in anything"}
        />
      )}

      {page === 1 && (
        <LeagueParticipent
          leaguesParticipating={leaguesParticipatingHistory}
          tournamentParticipating={tournamentParticipatingHistory}
          message={"This team has not previously participated in anything"}
        />
      )}

      <div className="row mb-3 smaller-gutter-row mb-4">
        <div className="col-12">
          <div className="mt-4 uppercase bold"> Recent Matches</div>
          {!recentMatches.length ? (
            <div className="league-no-matches">
              <Icon name="calendar" />
              <p>This team hasn’t played any matches yet</p>
            </div>
          ) : (
            // <List>
            //   {recentMatches.map((match) => (
            //     <LeaguesResultItem key={match._id} match={match} />
            //   ))}
            // </List>
            <MatchListingByDate
              existingMatches={recentMatches}
              isRecent={true}
              page={"RECENT_MATCHES"}
              fullScreen={true}
              id={team?._id}
            ></MatchListingByDate>
          )}
        </div>
      </div>

      <div>
        {organisation && (
          <div>
            <div className="mt-4 mb-4 uppercase bold"> Organisation</div>
            <div
              style={{
                backgroundImage: `linear-gradient(to top, rgb(3, 6, 13) 15%, rgba(3, 6, 13, 0) 140%),url(${organisation.banner})`,
                height: "350px",
                objectFit: "fill",
                backgroundPostions: "center",
                backgroundSize: "cover",
              }}
            >
              <Link to={`/organisation/${organisation._id}`} className={"text-decoration-none"}>
                <div className="wide-page-with-tabs-heading-wrapper without_margin" style={{ padding: "5%" }}>
                  <div className="wide-page-with-tabs-heading">
                    <img
                      className={
                        organisation.logoCropped || organisation.logoCropped === undefined
                          ? "wide-page-with-tabs-logo"
                          : "wide-page-with-tabs-logo_withoutCropped"
                      }
                      alt="Team"
                      src={organisation.logo ?? default_team_logo}
                    />
                    <div className="wide-page-with-tabs-heading-text">
                      <h1>{organisation.name}</h1>
                      <span className="text-faded uppercase text-white bold">
                        {organisation.countTeams} {organisation.countTeams <= 1 ? "Team" : "Teams"}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default About;
