import React, { useState, createContext, useEffect } from "react";
import Modal from "react-modal";
import { RiCloseLine } from "react-icons/ri";
import CreateOrg from "./CreateOrg/CreateOrg";
import InvitePlayers from "./InvitePlayers/InvitePlayers";
import AddOrgImages from "./AddOrgImages/AddOrgImages";
import AddOrgSocials from "./AddOrgSocials/AddOrgSocials";

export const CreateOrgModalContext = createContext();

const CreateOrgModal = ({ open, onClose, afterCreate }) => {
  const [step, setStep] = useState(0);
  const [orgData, setOrgData] = useState({});

  useEffect(() => {
    // reset modal when it changes visibility
    setStep(0);
    setOrgData({});
  }, [open]);

  const steps = [<CreateOrg />, <InvitePlayers />, <AddOrgImages />, <AddOrgSocials />];

  return (
    <Modal isOpen={open} onRequestClose={onClose} className="modal" shouldCloseOnEsc={false}>
      <div className="modal-close" onClick={onClose}>
        <RiCloseLine />
      </div>
      {step > 0 ? (
        <div className="modal-step-progress">
          <span className="text-12 uppercase bold color-primary">step {step + 1}/4</span>
        </div>
      ) : null}
      <CreateOrgModalContext.Provider value={{ setStep, onClose, orgData, setOrgData, afterCreate }}>
        {steps.map((component, i) => i === step && <div key={i}>{component}</div>)}
      </CreateOrgModalContext.Provider>
    </Modal>
  );
};

export default CreateOrgModal;
