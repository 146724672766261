import React, { useEffect, useState, useContext } from "react";
import { SimplePageWithTabs } from "@common";
import { Matches, Calendar, OngoingUpcoming, Results } from "./YourMatchesTabs";
import { MatchListingByDate } from "../_common";
import { AppContext } from "../../context/AppContext";
import Axios from "axios";

const YourMatches = () => {
  const [count, setCount] = useState(0);
  const { authenticated, user, selectedGame } = useContext(AppContext);
  const _id = user.state._id;

  const tabs = {
    // Calendar: <Calendar />,
    Matches: <MatchListingByDate id={user.state._id} page={"YOUR_MATCHES"} />,
    // Upcoming: <OngoingUpcoming />,
    // Results: <Results />,
  };

  const generateFilters = () => {
    let filters = "?";
    filters += "filter=upcoming&";
    if (selectedGame._id) filters += "gameId=" + selectedGame._id + "&";
    if (authenticated) filters += "user=" + _id + "&";
    return filters.length > 1 ? filters : "";
  };

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/matches/my?filter=upcoming`).then(({ data }) => {
      let matchData = data;
      let _count = 0;
      matchData = matchData.filter((match) => !!match);
      for (let match of matchData) {
        const gameData = match.league
          ? match.league?.game?.shortName == "CSGO"
            ? match.csgoData
            : match.lolData
          : match.tournament
          ? match.tournament?.game?.shortName === "CSGO"
            ? match.csgoData
            : match.lolData
          : match.scrim?.game?.shortName == "CSGO"
          ? match.csgoData
          : match.lolData;
        if (
          gameData.players &&
          (!!match?.t1?.members?.find(({ user }) => user && user._id === _id) ||
            !!match.t1?.organisation?.owners?.find(({ user }) => user && user === _id)) &&
          !(gameData.players.t1 || []).length
        ) {
          _count++;
        }
        if (
          gameData.players &&
          (!!match.t2?.members?.find(({ user }) => user && user._id === _id) ||
            !!match.t2?.organisation?.owners?.find(({ user }) => user && user === _id)) &&
          !(gameData.players.t2 || []).length
        ) {
          _count++;
        }
      }
      setCount(_count);
    });
  }, []);

  return (
    <SimplePageWithTabs
      title="Your Matches"
      base_route="/your-matches"
      tabs={tabs}
      top_right={null /*<div className="your-matches-top-right-box bold uppercase text-12">3 matches needs players set</div>*/}
      buttonProps={count ? `${count} MATCH${count > 1 ? "ES" : ""} NEEDS PLAYERS SET` : null}
      page="YourMatches"
    />
  );
};

export default YourMatches;
