import React, { useState, useEffect, useContext } from "react";
import { Tabs, Checkbox, FilterTabItem } from "@common";
import Axios from "axios";
import { AppContext } from "@context/AppContext";

const Filters = (props) => {
  const { includeTournaments, setIncludeTournaments, setIsFilterSet } = props;  
  const [currentPage, setCurrentPage] = useState(0);
  const [nextPage, setNextPage] = useState(currentPage ? currentPage : 0);


  const pages = {
    Season: <FilterTabItem tab={SELECTEDTAB.SEASON} {...props} />,
    Nationality: <FilterTabItem tab={SELECTEDTAB.NATIONALITY} {...props} />,
    Division: <FilterTabItem tab={SELECTEDTAB.DIVISION} {...props} />,
  };
  return (
    <div>
      <div className="justify-content-between" style={{ position: "relative", display: "flex", marginLeft: "-30px" }}>
        <div className="filter-text">FILTERS</div>
        <div className="filter-checkbox">
          <Checkbox
            onChange={() => {
              setIsFilterSet(true);
              setIncludeTournaments(!includeTournaments);
            }}
            checked={includeTournaments}
            label="Include Tournaments"
            className="check-type1"
          />
        </div>
      </div>

      <div className="graybox filter-tabs">
        <Tabs pages={pages} noPadding={true} className={"filters-header"} nextPage={nextPage} onClick={() => setNextPage(1)} isOverview />
      </div>
    </div>
  );
};

export default Filters;

const SELECTEDTAB = {
  SEASON: "seasons",
  NATIONALITY: "groups",
  DIVISION: "divisions",
};
