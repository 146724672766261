import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Close from "./assets/close.svg";
import moment from "moment";
import Axios from "axios";
const SendRequest = ({
  onClose = () => {},
  currentDate,
  gameColor,
  reschedule,
  teamId,
  matchId,
  cbSuccess,
  cbFailure,
  edit,
  isTournament,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [newDate, setNewDate] = useState(undefined);
  const [minTime, setMinTime] = useState();
  const [maxTime, setMaxTime] = useState();
  const [newTime, setNewTime] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setNewDate(moment.max([moment(reschedule.earliestDate), moment(new Date())]).format(moment.HTML5_FMT.DATE));
  }, [reschedule]);
  const setTimeRange = () => {
    const isminAndMaxOnSameDate =
      moment(reschedule.earliestDate).format("YYYY-MM-DD") === moment(reschedule.latestDate).format("YYYY-MM-DD");
    const isMinAndCurrentOnSameDate = moment(reschedule.earliestDate).format("YYYY-MM-DD") === moment(newDate).format("YYYY-MM-DD");
    const isMaxAndCurrentOnSameDate = moment(reschedule.latestDate).format("YYYY-MM-DD") === moment(newDate).format("YYYY-MM-DD");

    if (isminAndMaxOnSameDate) {
      setMinTime(moment(reschedule.earliestDate).format(moment.HTML5_FMT.TIME));
      setMaxTime(moment(reschedule.latestDate).format(moment.HTML5_FMT.TIME));
    } else if (isMinAndCurrentOnSameDate) {
      setMinTime(moment(reschedule.earliestDate).format(moment.HTML5_FMT.TIME));
      setMaxTime(moment(reschedule.earliestDate).endOf("day").format(moment.HTML5_FMT.TIME));
    } else if (isMaxAndCurrentOnSameDate) {
      const calculatedMinTime = moment(reschedule.latestDate).startOf("day").format(moment.HTML5_FMT.TIME);
      const calculatedMaxTime = moment(reschedule.latestDate).format(moment.HTML5_FMT.TIME);
      setMinTime(calculatedMinTime);
      setMaxTime(calculatedMaxTime);
    } else {
      setMinTime(moment(newDate).startOf("day").format(moment.HTML5_FMT.TIME));
      setMaxTime(moment(newDate).endOf("day").format(moment.HTML5_FMT.TIME));
    }
    // }
  };

  useEffect(() => setTimeRange(), [newDate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      const { data } = !edit
        ? await Axios.post(`${process.env.REACT_APP_CORE_API}/api/rescheduleRequests/${matchId}`, {
            sender: teamId,
            rescheduleDate: moment(`${newDate} ${newTime}`).toISOString(),
          })
        : await Axios.put(`${process.env.REACT_APP_CORE_API}/api/rescheduleRequests/${matchId}`, {
            sender: teamId,
            rescheduleDate: moment(`${newDate} ${newTime}`).toISOString(),
          });
      if (!data.error) {
        setIsSubmitting(false);
        cbSuccess(data, !edit ? "Successfully created reschedule request" : "Successfully updated reschedule request");
        onClose();
        setIsOpen(false);
      } else {
        setIsSubmitting(false);
        cbFailure(data.msg);
      }
    } catch (error) {
      setIsSubmitting(false);
      cbFailure(
        edit
          ? error.response?.data?.msg || "Failed to edit reschedule request"
          : error.response?.data?.msg || "Failed to create reschedule request"
      );
    }
  };

  const deleteRequest = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(false);
      const { data } = await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/rescheduleRequests/${matchId}`);
      if (!data.error) {
        setIsSubmitting(false);
        onClose();
        cbSuccess(null, "Successfully deleted reschedule request");
      }
    } catch (error) {
      cbFailure(error.response?.data?.msg || "Failed to withdraw reschedule request");
    }
  };

  return (
    <Modal isOpen={isOpen} className="modal" style={{ position: "relative" }} onRequestClose={onClose} shouldCloseOnOverlayClick={false}>
      <div className="modal-header">
        <h2 className="headerTitle">SUGGEST A NEW MATCH DATE</h2>
        <p className="text-muted text-center description">
          Send a request to the opponent team to move the match date. If the opponent declines or fail to answer your request, the match
          date will not be changed
        </p>
        <hr />
        <div className="modal-body">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <label htmlFor="currentDate">CURRENT DATE</label>
              <input
                type="datetime"
                id="currentTime"
                placeholder={moment(currentDate).toString(true)}
                autoComplete="datetime"
                className="form-input"
                value={""}
                required
                disabled
              />
            </div>
            <div className="form-row">
              <label htmlFor="newTime">NEW DATE</label>
              <div className="row m-0 p-0">
                <div className="col-8 p-1">
                  <input
                    type="date"
                    id="newDate"
                    placeholder="Choose Date"
                    autoComplete="date"
                    className="form-input"
                    value={newDate}
                    onChange={(e) => setNewDate(e.target.value)}
                    required
                    min={
                      moment().isBefore(reschedule.earliestDate)
                        ? moment(reschedule.earliestDate).format(moment.HTML5_FMT.DATE)
                        : moment().format(moment.HTML5_FMT.DATE)
                    }
                    max={moment(reschedule.latestDate).format(moment.HTML5_FMT.DATE)}
                  />
                </div>
                <div className="col-4 p-1">
                  <input
                    type="time"
                    id="time"
                    placeholder="time"
                    autoComplete="time"
                    className="form-input"
                    value={newTime || minTime}
                    onChange={(e) => setNewTime(e.target.value)}
                    required
                    min={minTime}
                    max={maxTime}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p className="description">You can also reach out the opponent team using the Discord channel from the match page.</p>
              </div>
            </div>
            {!edit ? (
              <button
                type="submit"
                className="button button-primary"
                style={{ backgroundColor: `${gameColor || ""}` }}
                disabled={isSubmitting}
              >
                Send request
              </button>
            ) : (
              <>
                <button
                  type="submit"
                  className="button button-primary"
                  style={{ backgroundColor: `${gameColor || ""}` }}
                  disabled={isSubmitting}
                >
                  Update request
                </button>
                <button className="button button-danger my-1" onClick={deleteRequest}>
                  Delete Request
                </button>
              </>
            )}
          </form>
        </div>
      </div>
      <div className="closeButton" style={{ display: "absolute", rigth: "-12px" }}>
        <img
          src={Close}
          onClick={() => {
            console.log("Calling onclose");
            onClose();
            setIsOpen(false);
          }}
        />
      </div>
    </Modal>
  );
};
export default SendRequest;
