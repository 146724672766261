import React from "react";
import { Tabs } from "@common";
import vars from "@styles/_variables.scss";
import default_image from "@img/esport-series-logo.png";
import Advert from "../Advert/Advert";
import { useHistory } from "react-router";

const PageWithTabs = ({
  cover_image,
  pages,
  tab_link,
  base_route,
  title,
  above_title,
  below_title,
  image,
  custom_header,
  append_to_header,
  type,
  hide_ads = false,
  showAdsInTabs = true,
  data,
  isTournament,
  tournamentData,
  croppedLogo = false,
  reschedule_buttons,
  tagLine = null,
}) => {
  const history = useHistory();

  const adsComponent = (
    <div className="col-3">
      <div className="info-placeholder-wrapper">
        <div className="info-placeholder small">
          {isTournament && tournamentData && tournamentData.showFirstAd && tournamentData.firstAd ? (
            <img src={tournamentData.firstAd} width="100%" />
          ) : (
            <Advert width="100%" height={250} refName="PROFILE_SIDEBAR_420_250_SM" style={{ borderRadius: 2 }} />
          )}
        </div>
        <div className="info-placeholder">
          {isTournament && tournamentData && tournamentData.showSecondAd && tournamentData.secondAd ? (
            <img src={tournamentData.secondAd} width="100%" />
          ) : (
            <Advert width="100%" height={500} refName="PROFILE_SIDEBAR_420_500_LG" style={{ borderRadius: 2 }} />
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className={"container-fluid page-with-tabs " + type} style={{ position: "relative" }}>
      <div
        className="page-with-tabs-hero-bg"
        style={{
          background: `linear-gradient(to top, ${vars.content_bg_color} 10%, rgba(3, 6, 13, 0) 120%), url(${cover_image})`,
        }}
      />
      <div className="row smaller-gutter-row pt-4">
        <div className="col">
          <div
            className={`row  ${
              history.location.pathname.match(new RegExp("/match/[a-zA-Z0-9]+"))
                ? "page-with-tabs-header__less-margin"
                : "page-with-tabs-header"
            }`}
          >
            <div className="col">
              {custom_header ?? (
                <React.Fragment>
                  <div className="row">
                    <div className="col-1">
                      <div className="page-with-tabs-image-container">
                        {image && <img src={image} alt={title} className={croppedLogo ? "image-cropped" : "image-notCropped"} />}
                      </div>
                    </div>
                    <div className="col">
                      <div className="page-with-tabs-info">
                        <span className="text-14 faded">{above_title}</span>
                        <h1 className="text-32">
                          {tagLine ? (
                            <>
                              {title}
                              <span className="text-faded">#{tagLine}</span>
                            </>
                          ) : (
                            title || "[Deleted]"
                          )}
                        </h1>
                        <span className="text-14">{below_title}</span>
                      </div>
                      {append_to_header}
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>

          <Tabs
            ads={hide_ads ? undefined : showAdsInTabs ? adsComponent : undefined}
            pages={pages}
            base_route={base_route}
            show_filter={typeof tab_link !== "undefined"}
            custom_filter={tab_link}
            reschedule_buttons={reschedule_buttons}
          />
        </div>
      </div>
    </div>
  );
};

export default PageWithTabs;
