import React, { useState } from "react";
import { Loading, Icon } from "@common";
import Axios from "axios";
import { toast } from "react-toastify";

const ForgotPassword = ({ afterReset }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/auth/forgotPassword`, { email });

      setLoading(false);
      toast.success("Check the email entered for instructions on how to reset your password");
      afterReset();
    } catch (e) {
      setLoading(false);

      const msg = e.response && e.response.data ? e.response.data.msg : "Fatal error";
      toast.error(msg);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div style={{ display: "flex", alignItems: "center" }} className="forgot-password-back-wrapper mb-3">
        <button type="button" className="round-toggle-button mr-2" onClick={afterReset}>
          <Icon name="down" />
        </button>
        <p className="text-16 bold">Forgot password</p>
      </div>
      <div className="form-row">
        <label htmlFor="email">Email</label>
        <input onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
          type="email"
          id="email"
          autoComplete="email"
          placeholder="Name@mail.com"
          className="form-input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>

      <button type="submit" className="button primary thicc" disabled={!email || loading}>
        {loading ? (
          <>
            <Loading width={30} height={30} /> Processing...
          </>
        ) : (
          "Reset Password"
        )}
      </button>
    </form>
  );
};

export default ForgotPassword;
