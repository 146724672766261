import React, { useContext, useState, useEffect } from "react";
import { PageWithTabs } from "@common";
import cover_image from "@img/ScrimFinderBanner.jpg";
import cover_image_csgo from "@img/ScrimFinderBannerCsgo.jpg";
import esport_series_logo from "@img/esport-series-logo.png";
import { AppContext } from "../../context/AppContext";
import Schedule from "./Schedule/Schedule";
import Filters from "./Filters";
import Axios from "axios";

const Overview = () => {
  const { authenticated, selectedGame } = useContext(AppContext);
  const [includeTournaments, setIncludeTournaments] = useState(true);
  const [filters, setFilters] = useState();
  const [isFilterSet, setIsFilterSet] = useState(true);
  const [startLoader, setStartLoader] = useState(false);



  const pages = {
    Schedule: (
      <Schedule
        filters={filters}
        setFilters={setFilters}
        includeTournaments={includeTournaments}
        setIncludeTournaments={setIncludeTournaments}
        isFilterSet={isFilterSet}
        setIsFilterSet={setIsFilterSet}
        startLoader={startLoader}
      />
    ),
  };
  return (
    <PageWithTabs
      showAdsInTabs
      title="Overview"
      above_title="Overview"
      croppedLogo= {false}
      below_title={<React.Fragment>Get an overview of all the matches, standings, and top players.</React.Fragment>}
      pages={pages}
      cover_image={selectedGame && selectedGame.shortName === "CSGO" ? cover_image_csgo : cover_image}
      base_route="/overview"
      image={selectedGame.whiteIcon}
      hide_ads={true}
      // type="under-contruction-all-page"
    />
  );
};;

export default Overview;
